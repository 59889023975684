import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Download, Loader2, AlertCircle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '../context/NotificationContext';

interface PDFExportProps {
  reportId: string;
}

export default function PDFExport({ reportId }: PDFExportProps) {
  const { getToken } = useAuth();
  const { addNotification } = useNotification();
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDownload = async () => {
    setIsGenerating(true);
    setError(null);

    try {
      const token = await getToken();
      const response = await fetch(`/api/reports/${reportId}/pdf`, {
        method: 'GET',
        headers: {
          'Accept': 'application/pdf',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = url;
      link.download = `report-${reportId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      addNotification('success', 'PDF downloaded successfully');

    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to generate PDF';
      setError(message);
      addNotification('error', message);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="absolute bottom-full mb-2 right-0 p-3 bg-red-50 text-red-600 rounded-lg shadow-lg flex items-center gap-2 text-sm"
          >
            <AlertCircle size={16} />
            <span>{error}</span>
            <button
              onClick={() => setError(null)}
              className="ml-2 hover:text-red-700"
            >
              ×
            </button>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        onClick={handleDownload}
        disabled={isGenerating}
        className={`
          flex items-center gap-2 px-4 py-2.5 rounded-lg shadow-lg
          transition-colors duration-200
          ${isGenerating
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-teal-500 hover:bg-teal-600'
          }
          text-white
        `}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {isGenerating ? (
          <>
            <Loader2 size={20} className="animate-spin" />
            <span>Generating PDF...</span>
          </>
        ) : (
          <>
            <Download size={20} />
            <span>Download PDF</span>
          </>
        )}
      </motion.button>
    </div>
  );
}