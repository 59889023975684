import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Briefcase, Building2, Users, Plus, LayoutGrid, Table2 } from 'lucide-react';
import { useJobs } from '../../hooks/useJobs';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { Job } from '../../types';
import JobFormModal from '../modals/JobFormModal';
import JobCard from './JobCard';
import JobTable from './JobTable';
import LoadingState from '../ui/LoadingState';
import ErrorState from '../ui/ErrorState';
import EmptyState from '../ui/EmptyState';
import ConfirmDialog from '../ui/ConfirmDialog';
import { useNotification } from '../../context/NotificationContext';
import { useSearch } from '../../hooks/useSearch';
import SearchAndFilter from '../ui/SearchAndFilter';
import { useViewMode } from '../../hooks/useViewMode';
import { useAppNavigation } from '../../routes/useNavigation';

export default function JobList() {
  const navigation = useAppNavigation();
  const [viewMode, setViewMode] = useViewMode('jobListViewMode', 'card');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState<Job | null>(null);
  const [jobToEdit, setJobToEdit] = useState<Job | null>(null);
  const { jobs, loading, error, fetchJobs, addJob, updateJob, deleteJob } = useJobs();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { addNotification } = useNotification();

  const {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  } = useSearch({
    data: jobs,
    searchFields: ['name', 'jobId', 'address', 'description'],
    filterConfig: {
      status: (job, values) => values.includes(job.status),
    },
  });

  const filters = [
    {
      id: 'status',
      label: 'Status',
      options: [
        { value: 'pending', label: 'Pending' },
        { value: 'in-progress', label: 'In Progress' },
        { value: 'completed', label: 'Completed' },
      ],
    },
  ];

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleDelete = (job: Job) => {
    setJobToDelete(job);
  };

  const handleEdit = (job: Job) => {
    setJobToEdit(job);
  };

  const handleConfirmDelete = async () => {
    if (jobToDelete) {
      try {
        await deleteJob(jobToDelete.id);
        addNotification('success', 'Job deleted successfully');
      } catch (error) {
        addNotification('error', 'Failed to delete job');
      }
      setJobToDelete(null);
    }
  };

  const handleSubmit = async (data: Partial<Job>) => {
    try {
      if (jobToEdit) {
        await updateJob({ ...data, id: jobToEdit.id } as Job);
        addNotification('success', 'Job updated successfully');
        setJobToEdit(null);
      } else {
        await addJob(data);
        addNotification('success', 'Job created successfully');
        setIsAddModalOpen(false);
      }
    } catch (error) {
      addNotification('error', `Failed to ${jobToEdit ? 'update' : 'create'} job`);
    }
  };

  const handleJobClick = (job: Job) => {
    navigation.goToJob(job.id);
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} onRetry={fetchJobs} />;
  if (!jobs.length) {
    return (
      <EmptyState
        title="No Jobs Found"
        message="Get started by adding your first job."
        icon={<Briefcase size={24} className="text-gray-400" />}
        actionLabel="Add Job"
        onAction={() => setIsAddModalOpen(true)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Briefcase className="text-blue-600" size={24} />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Jobs</h1>
            <p className="text-sm text-gray-500">Manage your construction projects</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* View Toggle */}
          <div className="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewMode('card')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'card'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <LayoutGrid size={20} />
            </button>
            <button
              onClick={() => setViewMode('table')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'table'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Table2 size={20} />
            </button>
          </div>

          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
          >
            <div className="flex items-center gap-2">
              <Plus size={16} />
              <span>Add Job</span>
            </div>
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <SearchAndFilter
        searchPlaceholder="Search jobs..."
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={filters}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
        showFilters={showFilters}
        onToggleFilters={toggleFilters}
      />

      {/* Jobs List */}
      {viewMode === 'card' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((job) => (
            <JobCard
              key={job.id}
              job={job}
              onSelect={() => handleJobClick(job)}
              onEdit={() => handleEdit(job)}
              onDelete={() => handleDelete(job)}
            />
          ))}
        </div>
      ) : (
        <JobTable
          jobs={filteredData}
          onSelect={handleJobClick}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {/* Add/Edit Job Modal */}
      {(isAddModalOpen || jobToEdit) && (
        <JobFormModal
          isOpen={true}
          onClose={() => {
            setIsAddModalOpen(false);
            setJobToEdit(null);
          }}
          onSubmit={handleSubmit}
          initialData={jobToEdit || undefined}
          companyId={companies[0]?.id || ''}
          clientId={clients[0]?.id || ''}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={!!jobToDelete}
        onClose={() => setJobToDelete(null)}
        onConfirm={handleConfirmDelete}
        title="Delete Job"
        message={`Are you sure you want to delete ${jobToDelete?.name}? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </div>
  );
}