import React, { useState } from 'react';
import { useFormValidation } from '../../hooks/useFormValidation';
import { jobValidationRules } from '../../utils/validationRules';
import { Job } from '../../types';
import FormModal from './FormModal';
import FormField from '../ui/FormField';
import FormError from '../ui/FormError';
import { useNotification } from '../../context/NotificationContext';
import { Upload, X, FileText } from 'lucide-react';

interface JobFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Job>) => void;
  initialData?: Partial<Job>;
  companyId: string;
  clientId: string;
}

export default function JobFormModal({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  companyId,
  clientId,
}: JobFormModalProps) {
  const { addNotification } = useNotification();
  const [files, setFiles] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);

  const {
    formData,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
    setFormData,
  } = useFormValidation(initialData || {
    companyId,
    clientId,
    jobId: '',
    name: '',
    address: '',
    startDate: new Date().toISOString().split('T')[0],
    estimatedEndDate: '',
    status: 'pending',
    projectManagerName: '',
    quoteNumber: '',
    claimNumber: '',
    jobManager: '',
    description: '',
    contractAmount: 0,
    totalInvoiced: 0,
    pendingVariationValue: 0,
    approvedVariationValue: 0,
    notes: '',
  }, jobValidationRules);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      // In a real app, we would handle file uploads here
      onSubmit(formData);
      addNotification('success', `Job ${initialData ? 'updated' : 'created'} successfully`);
      onClose();
    }
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files) {
      handleFiles(Array.from(e.dataTransfer.files));
    }
  };

  const handleFiles = (newFiles: File[]) => {
    setFiles(prev => [...prev, ...newFiles]);
  };

  const removeFile = (index: number) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      title={initialData ? 'Edit Job' : 'Add New Job'}
      size="lg"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Job Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.name} touched={touched.name} />
          </div>

          <div>
            <FormField
              label="Job ID"
              name="jobId"
              value={formData.jobId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.jobId} touched={touched.jobId} />
          </div>
        </div>

        {/* Status and Dates */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              required
            >
              <option value="pending">Pending</option>
              <option value="in-progress">In Progress</option>
              <option value="completed">Completed</option>
            </select>
            <FormError errors={errors.status} touched={touched.status} />
          </div>

          <div>
            <FormField
              label="Start Date"
              name="startDate"
              type="date"
              value={formData.startDate}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.startDate} touched={touched.startDate} />
          </div>

          <div>
            <FormField
              label="Estimated End Date"
              name="estimatedEndDate"
              type="date"
              value={formData.estimatedEndDate}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.estimatedEndDate} touched={touched.estimatedEndDate} />
          </div>
        </div>

        {/* Management */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Project Manager"
              name="projectManagerName"
              value={formData.projectManagerName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.projectManagerName} touched={touched.projectManagerName} />
          </div>

          <div>
            <FormField
              label="Job Manager"
              name="jobManager"
              value={formData.jobManager}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.jobManager} touched={touched.jobManager} />
          </div>
        </div>

        {/* Reference Numbers */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Quote Number"
              name="quoteNumber"
              value={formData.quoteNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.quoteNumber} touched={touched.quoteNumber} />
          </div>

          <div>
            <FormField
              label="Claim Number"
              name="claimNumber"
              value={formData.claimNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.claimNumber} touched={touched.claimNumber} />
          </div>
        </div>

        {/* Financial Information */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <FormField
              label="Contract Amount"
              name="contractAmount"
              type="number"
              value={formData.contractAmount?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              prefix="$"
            />
            <FormError errors={errors.contractAmount} touched={touched.contractAmount} />
          </div>

          <div>
            <FormField
              label="Total Invoiced"
              name="totalInvoiced"
              type="number"
              value={formData.totalInvoiced?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              prefix="$"
            />
            <FormError errors={errors.totalInvoiced} touched={touched.totalInvoiced} />
          </div>

          <div>
            <FormField
              label="Pending Variations"
              name="pendingVariationValue"
              type="number"
              value={formData.pendingVariationValue?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              prefix="$"
            />
            <FormError errors={errors.pendingVariationValue} touched={touched.pendingVariationValue} />
          </div>

          <div className="md:col-span-3">
            <FormField
              label="Approved Variations"
              name="approvedVariationValue"
              type="number"
              value={formData.approvedVariationValue?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              prefix="$"
            />
            <FormError errors={errors.approvedVariationValue} touched={touched.approvedVariationValue} />
          </div>
        </div>

        {/* Address and Description */}
        <div>
          <FormField
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            multiline
            rows={2}
          />
          <FormError errors={errors.address} touched={touched.address} />
        </div>

        <div>
          <FormField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={3}
            placeholder="Enter job description..."
          />
          <FormError errors={errors.description} touched={touched.description} />
        </div>

        {/* Notes */}
        <div>
          <FormField
            label="Notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={4}
            placeholder="Add any additional notes..."
          />
          <FormError errors={errors.notes} touched={touched.notes} />
        </div>

        {/* File Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Files
          </label>
          <div
            className={`border-2 border-dashed rounded-lg p-6 text-center ${
              dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="mt-4 flex text-sm text-gray-600 justify-center">
              <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                <span>Upload files</span>
                <input
                  type="file"
                  className="sr-only"
                  multiple
                  onChange={(e) => e.target.files && handleFiles(Array.from(e.target.files))}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">Up to 10 files, max 10MB each</p>
          </div>

          {files.length > 0 && (
            <div className="mt-4 space-y-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center gap-3">
                    <FileText size={20} className="text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">{file.name}</p>
                      <p className="text-xs text-gray-500">{formatFileSize(file.size)}</p>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                  >
                    <X size={16} className="text-gray-500" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Form Actions */}
        <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors"
          >
            {initialData ? 'Save Changes' : 'Create Job'}
          </button>
        </div>
      </form>
    </FormModal>
  );
}