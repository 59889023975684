import React from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Briefcase, Building2, Users, Mail, Phone, MapPin,
  FileText, DollarSign, GitBranch, ChevronLeft,
  ArrowUpRight, Calendar, TrendingUp, Clock,
  CheckCircle2, AlertTriangle
} from 'lucide-react';
import { useJobs } from '../../hooks/useJobs';
import { useClients } from '../../hooks/useClients';
import { useReports } from '../../hooks/useReports';
import { useInvoices } from '../../hooks/useInvoices';
import { useVariations } from '../../hooks/useVariations';

export default function JobView() {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { jobs } = useJobs();
  const { clients } = useClients();
  const { reports } = useReports();
  const { invoices } = useInvoices();
  const { variations } = useVariations();

  const job = jobs.find(j => j.id === jobId);
  const client = job ? clients.find(c => c.id === job.clientId) : null;
  
  // Filter related items
  const jobReports = reports.filter(r => r.jobId === jobId);
  const jobInvoices = invoices.filter(i => i.items.some(item => item.jobId === jobId));
  const jobVariations = variations.filter(v => v.jobId === jobId);

  if (!job || !client) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Job Not Found</h2>
          <p className="text-gray-500 mb-4">The requested job could not be found.</p>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  const progressPercentage = Math.round((job.totalInvoiced / job.contractAmount) * 100);
  const isOverdue = new Date(job.estimatedEndDate) < new Date() && job.status !== 'completed';

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-emerald-50 text-emerald-700';
      case 'in-progress':
        return 'bg-blue-50 text-blue-700';
      default:
        return 'bg-gray-50 text-gray-700';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 size={16} className="text-emerald-500" />;
      case 'in-progress':
        return <Clock size={16} className="text-blue-500" />;
      default:
        return <AlertTriangle size={16} className="text-gray-500" />;
    }
  };

  return (
    <div className="space-y-6">
      {/* Back Navigation */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
      >
        <ChevronLeft size={16} />
        <span>Back to Jobs</span>
      </button>

      {/* Job Header */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 bg-blue-50 rounded-xl flex items-center justify-center">
              <Briefcase className="text-blue-600" size={32} />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{job.name}</h1>
              <div className="flex items-center gap-4 text-sm text-gray-500 mt-1">
                <div className="flex items-center gap-2">
                  <Building2 size={14} />
                  <span>{client.name}</span>
                </div>
                {job.jobId && (
                  <div className="flex items-center gap-2">
                    <Briefcase size={14} />
                    <span>#{job.jobId}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <span className={`inline-flex items-center gap-2 px-3 py-1 rounded-full text-sm font-medium ${
              getStatusColor(job.status)
            }`}>
              {getStatusIcon(job.status)}
              {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Job Details */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900">Job Details</h2>
            <div className="space-y-3">
              <div className="flex items-start gap-3">
                <MapPin size={16} className="text-gray-400 mt-1" />
                <div>
                  <p className="text-sm font-medium text-gray-900">Address</p>
                  <p className="text-sm text-gray-600 whitespace-pre-line">
                    {job.address}
                  </p>
                </div>
              </div>
              {job.description && (
                <div className="flex items-start gap-3">
                  <FileText size={16} className="text-gray-400 mt-1" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">Description</p>
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {job.description}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex items-start gap-3">
                <Calendar size={16} className="text-gray-400 mt-1" />
                <div>
                  <p className="text-sm font-medium text-gray-900">Timeline</p>
                  <div className="text-sm text-gray-600">
                    <p>Start: {new Date(job.startDate).toLocaleDateString()}</p>
                    <p className={isOverdue ? 'text-red-600 font-medium' : ''}>
                      End: {new Date(job.estimatedEndDate).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Financial Overview */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900">Financial Overview</h2>
            <div className="bg-gray-50 rounded-xl p-4">
              <div className="space-y-4">
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm text-gray-500">Progress</span>
                    <span className="text-sm font-medium text-gray-900">
                      {progressPercentage}%
                    </span>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                    <motion.div
                      className="h-full bg-blue-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${progressPercentage}%` }}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Contract Value</p>
                    <p className="text-lg font-semibold text-gray-900">
                      ${job.contractAmount.toLocaleString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Invoiced to Date</p>
                    <p className="text-lg font-semibold text-gray-900">
                      ${job.totalInvoiced.toLocaleString()}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 pt-4 border-t border-gray-200">
                  <div>
                    <p className="text-sm text-gray-500">Approved Variations</p>
                    <p className="text-lg font-semibold text-emerald-600">
                      ${job.approvedVariationValue.toLocaleString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Pending Variations</p>
                    <p className="text-lg font-semibold text-amber-600">
                      ${job.pendingVariationValue.toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Related Items */}
      <div className="space-y-6">
        {/* Progress Reports */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-teal-50 rounded-lg">
                <FileText className="text-teal-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Progress Reports</h2>
            </div>
            <button
              onClick={() => navigate(`/app/jobs/${jobId}/reports`)}
              className="text-sm text-teal-600 hover:text-teal-700"
            >
              View All Reports
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {jobReports.slice(0, 3).map(report => (
              <motion.div
                key={report.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/reports/${report.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{report.reportId}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <div className="mt-2 flex items-center gap-2">
                  <div className="flex-1 h-1.5 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-teal-500"
                      style={{ width: `${report.progressPercentage}%` }}
                    />
                  </div>
                  <span className="text-xs font-medium text-gray-500">
                    {report.progressPercentage}%
                  </span>
                </div>
                <p className="text-sm text-gray-500 mt-2">
                  {new Date(report.date).toLocaleDateString()}
                </p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Invoices */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <DollarSign className="text-emerald-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Invoices</h2>
            </div>
            <button
              onClick={() => navigate(`/app/jobs/${jobId}/invoices`)}
              className="text-sm text-emerald-600 hover:text-emerald-700"
            >
              View All Invoices
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {jobInvoices.slice(0, 3).map(invoice => (
              <motion.div
                key={invoice.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/invoices/${invoice.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{invoice.invoiceNumber}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-500">
                    {new Date(invoice.date).toLocaleDateString()}
                  </span>
                  <span className="font-medium text-gray-900">
                    ${invoice.total.toLocaleString()}
                  </span>
                </div>
                <div className="mt-2">
                  <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                    invoice.status === 'paid'
                      ? 'bg-emerald-50 text-emerald-600'
                      : invoice.status === 'pending'
                      ? 'bg-amber-50 text-amber-600'
                      : 'bg-gray-50 text-gray-600'
                  }`}>
                    {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Variations */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <GitBranch className="text-amber-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Variations</h2>
            </div>
            <button
              onClick={() => navigate(`/app/jobs/${jobId}/variations`)}
              className="text-sm text-amber-600 hover:text-amber-700"
            >
              View All Variations
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {jobVariations.slice(0, 3).map(variation => (
              <motion.div
                key={variation.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/variations/${variation.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{variation.name}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <p className="text-sm text-gray-500 line-clamp-2">{variation.description}</p>
                <div className="mt-2 flex items-center justify-between text-sm">
                  <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                    variation.status === 'approved'
                      ? 'bg-emerald-50 text-emerald-600'
                      : 'bg-amber-50 text-amber-600'
                  }`}>
                    {variation.status === 'approved' ? 'Approved' : 'Pending'}
                  </span>
                  <span className="font-medium text-gray-900">
                    ${variation.quotedAmount.toLocaleString()}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}