import { useState, useEffect } from 'react';

type ViewMode = 'card' | 'table';

export function useViewMode(key: string, defaultMode: ViewMode = 'card') {
  const [viewMode, setViewMode] = useState<ViewMode>(() => {
    const savedMode = localStorage.getItem(key);
    return (savedMode as ViewMode) || defaultMode;
  });

  useEffect(() => {
    localStorage.setItem(key, viewMode);
  }, [viewMode, key]);

  return [viewMode, setViewMode] as const;
}