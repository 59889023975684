import React from 'react';
import { motion } from 'framer-motion';
import { 
  TrendingUp, 
  CheckCircle2, 
  Target,
  ArrowUpRight,
  Activity
} from 'lucide-react';
import AnimatedProgressCircle from './AnimatedProgressCircle';

interface JobProgressProps {
  progressPercentage: number;
  milestones?: Array<{
    title: string;
    completed: boolean;
  }>;
}

export default function JobProgress({ 
  progressPercentage,
  milestones = []
}: JobProgressProps) {
  const getStatusConfig = () => {
    if (progressPercentage >= 90) {
      return {
        icon: CheckCircle2,
        color: 'emerald',
        text: 'Almost Complete',
        description: 'Final stages of completion'
      };
    }
    if (progressPercentage >= 50) {
      return {
        icon: TrendingUp,
        color: 'blue',
        text: 'On Track',
        description: 'Progressing as planned'
      };
    }
    return {
      icon: Activity,
      color: 'violet',
      text: 'In Progress',
      description: 'Work underway'
    };
  };

  const status = getStatusConfig();
  const StatusIcon = status.icon;

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-blue-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Job Progress</h2>
              <p className="text-sm text-gray-500 mt-1">Overall project completion status</p>
            </div>
          </div>
          <motion.div 
            className={`p-2 bg-${status.color}-50 rounded-xl cursor-pointer group`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <StatusIcon className={`text-${status.color}-500 transform group-hover:rotate-12 transition-transform`} size={24} />
          </motion.div>
        </div>

        <div className="grid md:grid-cols-2 gap-12">
          {/* Progress Overview */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Progress Overview</h3>
              <p className="text-gray-500">Current project completion status</p>
            </div>

            <div className="space-y-4">
              {/* Progress Bar */}
              <motion.div 
                className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors cursor-pointer group"
                whileHover={{ y: -2 }}
              >
                <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                  <motion.div
                    className={`h-full bg-${status.color}-500`}
                    initial={{ width: 0 }}
                    animate={{ width: `${progressPercentage}%` }}
                    transition={{ duration: 1.5, ease: [0.34, 1.56, 0.64, 1] }}
                  />
                </div>
              </motion.div>

              {/* Status Card */}
              <motion.div
                className={`bg-${status.color}-50 rounded-lg p-4 hover:bg-${status.color}-100 transition-colors cursor-pointer group`}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.4 }}
                whileHover={{ scale: 1.02 }}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-600 mb-1">Current Progress</p>
                    <motion.div 
                      className="flex items-center gap-2"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                    >
                      <span className="text-2xl font-bold text-gray-900">
                        {progressPercentage}%
                      </span>
                      <Activity size={20} className={`text-${status.color}-500`} />
                    </motion.div>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-600 mb-1">Status</p>
                    <motion.p 
                      className={`text-lg font-semibold text-${status.color}-600 flex items-center gap-2`}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                    >
                      {status.text}
                      <ArrowUpRight 
                        size={16} 
                        className={`opacity-0 group-hover:opacity-100 transition-opacity text-${status.color}-500`}
                      />
                    </motion.p>
                  </div>
                </div>
                <p className={`mt-2 text-sm text-${status.color}-600`}>
                  {status.description}
                </p>
              </motion.div>
            </div>
          </motion.div>

          {/* Progress Circle and Milestones */}
          <div className="space-y-6">
            <motion.div 
              className="flex items-center justify-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ 
                duration: 0.5,
                delay: 0.3,
                type: "spring",
                stiffness: 200,
                damping: 20
              }}
            >
              <AnimatedProgressCircle percentage={progressPercentage} />
            </motion.div>

            {/* Milestones */}
            {milestones.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="mt-6"
              >
                <h4 className="text-sm font-medium text-gray-900 mb-4">Project Milestones</h4>
                <div className="space-y-3">
                  {milestones.map((milestone, index) => (
                    <motion.div
                      key={index}
                      className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer group"
                      whileHover={{ x: 4 }}
                    >
                      <div className={`p-2 rounded-full ${
                        milestone.completed 
                          ? 'bg-emerald-100 text-emerald-500' 
                          : 'bg-blue-100 text-blue-500'
                      }`}>
                        {milestone.completed ? (
                          <CheckCircle2 size={16} />
                        ) : (
                          <Target size={16} />
                        )}
                      </div>
                      <p className="font-medium text-gray-900 truncate flex-1">
                        {milestone.title}
                      </p>
                      <ArrowUpRight 
                        size={16} 
                        className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                      />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}