import { ValidationRules } from '../hooks/useFormValidation';
import { Company, Client, Job, Report } from '../types';

export const companyValidationRules: ValidationRules<Partial<Company>> = {
  name: [
    { required: true, message: 'Company name is required' },
    { minLength: 2, message: 'Company name must be at least 2 characters' },
  ],
  email: [
    { required: true, message: 'Primary email is required' },
    {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    },
  ],
  billingEmail: [
    {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    },
  ],
  website: [
    {
      pattern: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      message: 'Please enter a valid website URL',
    },
  ],
  phone: [
    { required: true, message: 'Phone number is required' },
    {
      pattern: /^[0-9+\-\s()]*$/,
      message: 'Please enter a valid phone number',
    },
  ],
  contactEmail: [
    {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    },
  ],
  contactPhone: [
    {
      pattern: /^[0-9+\-\s()]*$/,
      message: 'Please enter a valid phone number',
    },
  ],
  status: [{ required: true, message: 'Status is required' }],
};

export const clientValidationRules: ValidationRules<Partial<Client>> = {
  name: [
    { required: true, message: 'Client name is required' },
    { minLength: 2, message: 'Client name must be at least 2 characters' },
  ],
  email: [
    { required: true, message: 'Primary email is required' },
    {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    },
  ],
  billingEmail: [
    {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    },
  ],
  website: [
    {
      pattern: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      message: 'Please enter a valid website URL',
    },
  ],
  phone: [
    { required: true, message: 'Phone number is required' },
    {
      pattern: /^[0-9+\-\s()]*$/,
      message: 'Please enter a valid phone number',
    },
  ],
  contactEmail: [
    {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    },
  ],
  contactPhone: [
    {
      pattern: /^[0-9+\-\s()]*$/,
      message: 'Please enter a valid phone number',
    },
  ],
  status: [{ required: true, message: 'Status is required' }],
  notes: [
    { maxLength: 1000, message: 'Notes cannot exceed 1000 characters' },
  ],
};

export const jobValidationRules: ValidationRules<Partial<Job>> = {
  name: [
    { required: true, message: 'Job name is required' },
    { minLength: 2, message: 'Job name must be at least 2 characters' },
  ],
  jobId: [
    { minLength: 2, message: 'Job ID must be at least 2 characters' },
  ],
  address: [
    { required: true, message: 'Address is required' },
    { minLength: 5, message: 'Address must be at least 5 characters' },
  ],
  startDate: [{ required: true, message: 'Start date is required' }],
  estimatedEndDate: [{ required: true, message: 'Estimated end date is required' }],
  contractAmount: [
    { required: true, message: 'Contract amount is required' },
    { min: 0, message: 'Contract amount must be positive' },
  ],
  status: [{ required: true, message: 'Status is required' }],
  projectManagerName: [
    { minLength: 2, message: 'Project manager name must be at least 2 characters' },
  ],
  jobManager: [
    { minLength: 2, message: 'Job manager name must be at least 2 characters' },
  ],
  totalInvoiced: [
    { min: 0, message: 'Total invoiced amount must be positive' },
  ],
  pendingVariationValue: [
    { min: 0, message: 'Pending variation value must be positive' },
  ],
  approvedVariationValue: [
    { min: 0, message: 'Approved variation value must be positive' },
  ],
  notes: [
    { maxLength: 1000, message: 'Notes cannot exceed 1000 characters' },
  ],
};

export const reportValidationRules: ValidationRules<Partial<Report>> = {
  date: [{ required: true, message: 'Report date is required' }],
  preparedBy: [
    { required: true, message: 'Prepared by is required' },
    { minLength: 2, message: 'Name must be at least 2 characters' },
  ],
  preparedByTitle: [{ required: true, message: 'Title is required' }],
  progressPercentage: [
    { required: true, message: 'Progress percentage is required' },
    { min: 0, message: 'Progress cannot be less than 0%' },
    { max: 100, message: 'Progress cannot exceed 100%' },
  ],
  tasksAchieved: [
    { required: true, message: 'Tasks achieved is required' },
    { minLength: 10, message: 'Please provide more detail about achieved tasks' },
  ],
  tasksPlanned: [
    { required: true, message: 'Tasks planned is required' },
    { minLength: 10, message: 'Please provide more detail about planned tasks' },
  ],
  keyUpdates: [
    { required: true, message: 'Key updates are required' },
    { minLength: 10, message: 'Please provide more detail about key updates' },
  ],
  clientCommunication: [{ required: true, message: 'Client communication is required' }],
  accidents: [{ required: true, message: 'Health & Safety report is required' }],
  councilInspections: [{ required: true, message: 'Council inspections report is required' }],
  contractAmount: [
    { required: true, message: 'Contract amount is required' },
    { min: 0, message: 'Contract amount must be positive' },
  ],
  invoicedToDate: [
    { required: true, message: 'Invoiced amount is required' },
    { min: 0, message: 'Invoiced amount must be positive' },
  ],
  approvedVariations: [
    { required: true, message: 'Approved variations amount is required' },
    { min: 0, message: 'Approved variations must be positive' },
  ],
  pendingVariations: [
    { required: true, message: 'Pending variations amount is required' },
    { min: 0, message: 'Pending variations must be positive' },
  ],
};