import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDrag } from '@use-gesture/react';
import { Image as ImageIcon, Download, Share2, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

interface Image {
  url: string;
  caption?: string;
}

interface ImageGalleryProps {
  images: Image[];
}

export default function ImageGallery({ images }: ImageGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<{ image: Image; index: number } | null>(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden" ref={ref}>
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-blue-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Progress Images</h2>
              <p className="text-sm text-gray-500 mt-1">Visual documentation of project progress</p>
            </div>
          </div>
          <div className="p-2 bg-blue-50 rounded-xl">
            <ImageIcon className="text-blue-600" size={24} />
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              onClick={() => setSelectedImage({ image, index })}
              className="group cursor-pointer"
            >
              <ImageCard image={image} />
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {selectedImage && (
          <ImageModal
            selectedImage={selectedImage}
            totalImages={images.length}
            onClose={() => setSelectedImage(null)}
            onNavigate={(direction) => {
              if (!selectedImage) return;
              const newIndex = direction === 'prev'
                ? (selectedImage.index - 1 + images.length) % images.length
                : (selectedImage.index + 1) % images.length;
              setSelectedImage({
                image: images[newIndex],
                index: newIndex
              });
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

function ImageCard({ image }: { image: Image }) {
  return (
    <div className="relative bg-gray-50 rounded-xl border border-gray-100 overflow-hidden transition-all hover:shadow-md">
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={image.url}
          alt={image.caption || 'Progress image'}
          className="w-full h-48 object-cover"
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/0 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
        <div className="absolute bottom-0 left-0 right-0 p-4">
          {image.caption && (
            <p className="text-sm text-white">{image.caption}</p>
          )}
        </div>
      </div>
    </div>
  );
}

interface ImageModalProps {
  selectedImage: { image: Image; index: number };
  totalImages: number;
  onClose: () => void;
  onNavigate: (direction: 'prev' | 'next') => void;
}

function ImageModal({ selectedImage, totalImages, onClose, onNavigate }: ImageModalProps) {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = React.useRef<HTMLDivElement>(null);

  const bind = useDrag(({ movement: [x], direction: [xDir], distance, last }) => {
    if (scale === 1 && distance > 50 && last) {
      onNavigate(xDir > 0 ? 'prev' : 'next');
    }
  });

  const resetZoom = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') onNavigate('prev');
      if (e.key === 'ArrowRight') onNavigate('next');
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onNavigate, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/95 z-50 backdrop-blur-xl"
      onClick={() => {
        if (scale === 1) onClose();
        else resetZoom();
      }}
    >
      {/* Top Bar */}
      <div className="absolute top-0 inset-x-0 p-4">
        <div className="flex items-center justify-between max-w-6xl mx-auto">
          <span className="px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white/90 text-sm">
            {selectedImage.index + 1} / {totalImages}
          </span>
          <div className="flex items-center gap-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                window.open(selectedImage.image.url, '_blank');
              }}
              className="p-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
            >
              <Download className="text-white/90" size={20} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(selectedImage.image.url);
              }}
              className="p-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
            >
              <Share2 className="text-white/90" size={20} />
            </button>
            <button
              onClick={onClose}
              className="p-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
            >
              <X className="text-white/90" size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Image Container */}
      <div 
        className="absolute inset-0 flex items-center justify-center p-4"
        {...bind()}
        ref={containerRef}
      >
        <motion.img
          src={selectedImage.image.url}
          alt={selectedImage.image.caption || 'Progress image'}
          className="max-w-full max-h-[80vh] object-contain rounded-lg"
          style={{
            cursor: scale > 1 ? 'grab' : 'default',
            touchAction: 'none'
          }}
          drag={scale > 1}
          dragConstraints={containerRef}
          animate={{
            scale,
            x: position.x,
            y: position.y,
          }}
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={() => {
            if (scale === 1) {
              setScale(2);
            } else {
              resetZoom();
            }
          }}
        />
      </div>

      {/* Caption */}
      {selectedImage.image.caption && (
        <div className="absolute bottom-0 inset-x-0 p-4">
          <div className="max-w-3xl mx-auto">
            <p className="text-white/90 text-center bg-black/50 backdrop-blur-sm rounded-lg p-4">
              {selectedImage.image.caption}
            </p>
          </div>
        </div>
      )}

      {/* Navigation Buttons */}
      <button
        onClick={(e) => { e.stopPropagation(); onNavigate('prev'); }}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
      >
        <ChevronLeft className="text-white" size={24} />
      </button>
      <button
        onClick={(e) => { e.stopPropagation(); onNavigate('next'); }}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
      >
        <ChevronRight className="text-white" size={24} />
      </button>
    </motion.div>
  );
}