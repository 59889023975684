import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Users, 
  UserPlus, 
  Mail, 
  Shield, 
  Edit2,
  Trash2,
  AlertTriangle,
  Lock,
  Search,
  Filter,
  Crown,
  Building2,
  CheckCircle2,
  Clock,
  ChevronDown
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { usePermissions } from '../../hooks/usePermissions';
import { useUsers } from '../../hooks/useUsers';
import { useClients } from '../../hooks/useClients';
import InviteUserModal from '../modals/InviteUserModal';
import EditUserModal from '../modals/EditUserModal';
import ConfirmDialog from '../ui/ConfirmDialog';
import LoadingState from '../ui/LoadingState';
import ErrorState from '../ui/ErrorState';
import EmptyState from '../ui/EmptyState';
import { User } from '../../types/auth';

const permissionCategories = {
  'Company': ['manage_company'],
  'Users': ['manage_users', 'manage_roles'],
  'Clients': ['manage_clients'],
  'Projects': ['manage_jobs', 'manage_reports', 'manage_variations'],
  'Tasks': ['manage_tasks'],
  'Financial': ['manage_invoices'],
  'Settings': ['manage_settings', 'manage_integrations']
};

export default function UserPermissions() {
  const { company } = useAuth();
  const { addNotification } = useNotification();
  const { canManageUsers } = usePermissions();
  const { users, loading, error, fetchUsers, addUser, updateUser, deleteUser } = useUsers();
  const { clients } = useClients();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    status: [] as string[],
    role: [] as string[]
  });
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  // Filter users based on search term and filters
  const filteredUsers = users.filter(user => {
    const matchesSearch = 
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStatus = filters.status.length === 0 || 
      filters.status.includes(user.status);

    const matchesRole = filters.role.length === 0 || 
      filters.role.includes(user.role);

    return matchesSearch && matchesStatus && matchesRole;
  });

  const handleInviteUser = async (data: { email: string; role: string }) => {
    try {
      await addUser({
        ...data,
        name: '',
        status: 'invited',
        companyId: company?.id,
        password: Math.random().toString(36).slice(-8) // Temporary password
      });
      setShowInviteModal(false);
      addNotification('success', 'User invited successfully');
    } catch (error) {
      addNotification('error', 'Failed to invite user');
    }
  };

  const handleUpdateUser = async (updatedUser: User) => {
    try {
      await updateUser(updatedUser.id, updatedUser);
      setEditingUser(null);
      addNotification('success', 'User updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update user');
    }
  };

  const handleDeleteUser = async () => {
    if (!userToDelete) return;

    try {
      await deleteUser(userToDelete.id);
      setUserToDelete(null);
      addNotification('success', 'User deleted successfully');
    } catch (error) {
      addNotification('error', 'Failed to delete user');
    }
  };

  if (!canManageUsers) {
    return (
      <div className="text-center py-12">
        <Users className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Access</h3>
        <p className="mt-1 text-sm text-gray-500">
          You don't have permission to manage users.
        </p>
      </div>
    );
  }

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} onRetry={fetchUsers} />;
  if (!users.length) {
    return (
      <EmptyState
        title="No Users Found"
        message="Get started by inviting your first user."
        icon={<Users size={24} className="text-gray-400" />}
        actionLabel="Invite User"
        onAction={() => setShowInviteModal(true)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-violet-50 rounded-lg">
            <Users className="text-violet-600" size={20} />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">User Management</h2>
            <p className="text-sm text-gray-500">
              {company?.name ? `Manage users for ${company.name}` : 'Manage users'}
            </p>
          </div>
        </div>
        <button
          onClick={() => setShowInviteModal(true)}
          className="flex items-center gap-2 px-4 py-2 text-white bg-violet-500 rounded-lg hover:bg-violet-600 transition-colors"
        >
          <UserPlus size={16} />
          <span>Invite User</span>
        </button>
      </div>

      {/* Search and Filters */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4">
        <div className="flex items-center gap-4">
          <div className="flex-1 relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search users..."
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            />
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className={`p-2 rounded-lg transition-colors ${
              showFilters ? 'bg-violet-50 text-violet-600' : 'hover:bg-gray-100'
            }`}
          >
            <Filter size={20} />
          </button>
        </div>

        {/* Filters */}
        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mt-4 pt-4 border-t border-gray-100"
            >
              <div className="grid grid-cols-2 gap-4">
                {/* Status Filter */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Status
                  </label>
                  <div className="space-y-2">
                    {['active', 'invited', 'disabled'].map((status) => (
                      <label key={status} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={filters.status.includes(status)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFilters(prev => ({
                                ...prev,
                                status: [...prev.status, status]
                              }));
                            } else {
                              setFilters(prev => ({
                                ...prev,
                                status: prev.status.filter(s => s !== status)
                              }));
                            }
                          }}
                          className="rounded border-gray-300 text-violet-600 focus:ring-violet-500"
                        />
                        <span className="ml-2 text-sm text-gray-600 capitalize">
                          {status}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Role Filter */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Role
                  </label>
                  <div className="space-y-2">
                    {['org_admin', 'admin', 'user', 'client'].map((role) => (
                      <label key={role} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={filters.role.includes(role)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFilters(prev => ({
                                ...prev,
                                role: [...prev.role, role]
                              }));
                            } else {
                              setFilters(prev => ({
                                ...prev,
                                role: prev.role.filter(r => r !== role)
                              }));
                            }
                          }}
                          className="rounded border-gray-300 text-violet-600 focus:ring-violet-500"
                        />
                        <span className="ml-2 text-sm text-gray-600 capitalize">
                          {role.replace('_', ' ')}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Users List */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-100">
                <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">User</th>
                <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">Role</th>
                <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">Status</th>
                <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">Last Active</th>
                <th className="text-right py-4 px-6 text-sm font-medium text-gray-500">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50">
              {filteredUsers.map((user) => {
                const client = user.clientId ? clients.find(c => c.id === user.clientId) : null;
                
                return (
                  <motion.tr
                    key={user.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="group hover:bg-gray-50"
                  >
                    <td className="py-4 px-6">
                      <div className="flex items-center gap-3">
                        {user.profileImage ? (
                          <img
                            src={user.profileImage}
                            alt={user.name}
                            className="w-8 h-8 rounded-full object-cover"
                          />
                        ) : (
                          <div className="w-8 h-8 rounded-full bg-violet-100 flex items-center justify-center">
                            <span className="text-sm font-medium text-violet-600">
                              {user.name.charAt(0)}
                            </span>
                          </div>
                        )}
                        <div>
                          <p className="font-medium text-gray-900">{user.name}</p>
                          <p className="text-sm text-gray-500">{user.email}</p>
                        </div>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center gap-2">
                        {user.role === 'super_admin' && (
                          <Crown size={14} className="text-amber-400" />
                        )}
                        {user.role === 'org_admin' && (
                          <Building2 size={14} className="text-violet-500" />
                        )}
                        {user.role === 'client' && (
                          <Users size={14} className="text-blue-500" />
                        )}
                        <span className="text-sm font-medium text-gray-900">
                          {user.role.replace('_', ' ').charAt(0).toUpperCase() + 
                           user.role.replace('_', ' ').slice(1)}
                        </span>
                      </div>
                      {client && (
                        <p className="text-xs text-gray-500 mt-1">
                          {client.name}
                        </p>
                      )}
                    </td>
                    <td className="py-4 px-6">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${
                        user.status === 'active'
                          ? 'bg-emerald-50 text-emerald-600'
                          : user.status === 'invited'
                          ? 'bg-blue-50 text-blue-600'
                          : 'bg-gray-100 text-gray-600'
                      }`}>
                        {user.status === 'active' && <CheckCircle2 size={12} />}
                        {user.status === 'invited' && <Clock size={12} />}
                        {user.status === 'disabled' && <Lock size={12} />}
                        {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                      </span>
                    </td>
                    <td className="py-4 px-6">
                      {user.lastActive ? (
                        <span className="text-sm text-gray-500">
                          {new Date(user.lastActive).toLocaleDateString()}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-400">Never</span>
                      )}
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-end gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => setEditingUser(user)}
                          className="p-1 hover:bg-violet-50 rounded-lg transition-colors"
                        >
                          <Edit2 size={16} className="text-violet-500" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => setUserToDelete(user)}
                          className="p-1 hover:bg-red-50 rounded-lg transition-colors"
                        >
                          <Trash2 size={16} className="text-red-500" />
                        </motion.button>
                      </div>
                    </td>
                  </motion.tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modals */}
      <AnimatePresence>
        {showInviteModal && (
          <InviteUserModal
            onClose={() => setShowInviteModal(false)}
            onSubmit={handleInviteUser}
          />
        )}
        {editingUser && (
          <EditUserModal
            user={editingUser}
            onClose={() => setEditingUser(null)}
            onSubmit={handleUpdateUser}
          />
        )}
      </AnimatePresence>

      {/* Delete Confirmation */}
      <ConfirmDialog
        isOpen={!!userToDelete}
        onClose={() => setUserToDelete(null)}
        onConfirm={handleDeleteUser}
        title="Delete User"
        message={`Are you sure you want to delete ${userToDelete?.name}? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </div>
  );
}