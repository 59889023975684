import React from 'react';
import { Invoice } from '../../types/invoice';

interface InvoiceTotalsProps {
  invoice: Invoice;
}

export default function InvoiceTotals({ invoice }: InvoiceTotalsProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="w-full max-w-sm ml-auto space-y-4">
        <div className="flex items-center justify-between text-sm">
          <span className="text-gray-500">Subtotal</span>
          <span className="font-medium text-gray-900">
            ${invoice.subtotal.toLocaleString()}
          </span>
        </div>
        
        <div className="flex items-center justify-between text-sm">
          <span className="text-gray-500">Tax Total</span>
          <span className="font-medium text-gray-900">
            ${invoice.taxTotal.toLocaleString()}
          </span>
        </div>
        
        <div className="flex items-center justify-between text-lg font-medium border-t border-gray-100 pt-4">
          <span className="text-gray-900">Total</span>
          <span className="text-gray-900">
            ${invoice.total.toLocaleString()}
          </span>
        </div>
        
        {invoice.amountPaid > 0 && (
          <>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-500">Amount Paid</span>
              <span className="font-medium text-green-600">
                -${invoice.amountPaid.toLocaleString()}
              </span>
            </div>
            
            <div className="flex items-center justify-between text-lg font-medium border-t border-gray-100 pt-4">
              <span className="text-gray-900">Balance Due</span>
              <span className="text-gray-900">
                ${invoice.balance.toLocaleString()}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}