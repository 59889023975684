import React, { useState } from 'react';
import { useFormValidation } from '../../hooks/useFormValidation';
import { clientValidationRules } from '../../utils/validationRules';
import { Client } from '../../types';
import FormModal from './FormModal';
import FormField from '../ui/FormField';
import FormError from '../ui/FormError';
import { useNotification } from '../../context/NotificationContext';
import { Upload, X } from 'lucide-react';

interface ClientFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Client>) => void;
  initialData?: Partial<Client>;
  companyId: string;
}

export default function ClientFormModal({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  companyId,
}: ClientFormModalProps) {
  const { addNotification } = useNotification();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);

  const {
    formData,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
  } = useFormValidation(initialData || {
    companyId,
    name: '',
    email: '',
    billingEmail: '',
    phone: '',
    website: '',
    address: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    clientId: '',
    xeroId: '',
    status: 'active',
    notes: '',
  }, clientValidationRules);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      // In a real app, we would handle logo upload here
      onSubmit({ ...formData, companyId });
    }
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleLogoFile(e.dataTransfer.files[0]);
    }
  };

  const handleLogoFile = (file: File) => {
    if (file.type.startsWith('image/')) {
      setLogoFile(file);
      // In a real app, we would handle the file upload here
    } else {
      addNotification('error', 'Please upload an image file');
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      title={initialData ? 'Edit Client' : 'Add New Client'}
      size="lg"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Logo Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Client Logo
          </label>
          <div
            className={`border-2 border-dashed rounded-lg p-6 text-center ${
              dragActive ? 'border-violet-500 bg-violet-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {logoFile ? (
              <div className="relative inline-block">
                <img
                  src={URL.createObjectURL(logoFile)}
                  alt="Logo preview"
                  className="max-h-32 rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => setLogoFile(null)}
                  className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                >
                  <X size={12} />
                </button>
              </div>
            ) : (
              <>
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="mt-4 flex text-sm text-gray-600 justify-center">
                  <label className="relative cursor-pointer rounded-md font-medium text-violet-600 hover:text-violet-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      onChange={(e) => e.target.files && handleLogoFile(e.target.files[0])}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
              </>
            )}
          </div>
        </div>

        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Client Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.name} touched={touched.name} />
          </div>

          <div>
            <FormField
              label="Website"
              name="website"
              type="url"
              value={formData.website}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="https://example.com"
            />
            <FormError errors={errors.website} touched={touched.website} />
          </div>
        </div>

        {/* Contact Information */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <FormField
              label="Primary Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.email} touched={touched.email} />
          </div>

          <div>
            <FormField
              label="Billing Email"
              name="billingEmail"
              type="email"
              value={formData.billingEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.billingEmail} touched={touched.billingEmail} />
          </div>

          <div>
            <FormField
              label="Phone Number"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.phone} touched={touched.phone} />
          </div>
        </div>

        {/* Primary Contact */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-4">Primary Contact</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <FormField
                label="Contact Name"
                name="contactName"
                value={formData.contactName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError errors={errors.contactName} touched={touched.contactName} />
            </div>

            <div>
              <FormField
                label="Contact Phone"
                name="contactPhone"
                type="tel"
                value={formData.contactPhone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError errors={errors.contactPhone} touched={touched.contactPhone} />
            </div>

            <div>
              <FormField
                label="Contact Email"
                name="contactEmail"
                type="email"
                value={formData.contactEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError errors={errors.contactEmail} touched={touched.contactEmail} />
            </div>
          </div>
        </div>

        {/* Additional Information */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <FormField
              label="Client ID"
              name="clientId"
              value={formData.clientId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.clientId} touched={touched.clientId} />
          </div>

          <div>
            <FormField
              label="Xero ID"
              name="xeroId"
              value={formData.xeroId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormError errors={errors.xeroId} touched={touched.xeroId} />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 transition-colors"
              required
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <FormError errors={errors.status} touched={touched.status} />
          </div>
        </div>

        <div>
          <FormField
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={3}
          />
          <FormError errors={errors.address} touched={touched.address} />
        </div>

        <div>
          <FormField
            label="Notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={4}
            placeholder="Add any additional notes about the client..."
          />
          <FormError errors={errors.notes} touched={touched.notes} />
        </div>

        <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-violet-500 hover:bg-violet-600 rounded-lg transition-colors"
          >
            {initialData ? 'Save Changes' : 'Create Client'}
          </button>
        </div>
      </form>
    </FormModal>
  );
}