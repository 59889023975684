import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from './config';
import { generatePath } from './helpers';

export function useAppNavigation() {
  const navigate = useNavigate();

  return {
    // Dashboard
    goToDashboard: () => navigate(ROUTE_PATHS.DASHBOARD),
    
    // Tasks
    goToTasks: () => navigate(ROUTE_PATHS.TASKS),
    goToTaskBoard: () => navigate(ROUTE_PATHS.TASK_BOARD),
    goToTaskCalendar: () => navigate(ROUTE_PATHS.TASK_CALENDAR),
    goToTask: (taskId: string) => navigate(generatePath(ROUTE_PATHS.TASK_VIEW, { taskId })),
    
    // Companies
    goToCompanies: () => navigate(ROUTE_PATHS.COMPANIES),
    goToCompany: (companyId: string) => navigate(generatePath(ROUTE_PATHS.COMPANY_VIEW, { companyId })),
    
    // Clients
    goToClients: () => navigate(ROUTE_PATHS.CLIENTS),
    goToClient: (clientId: string) => navigate(generatePath(ROUTE_PATHS.CLIENT_VIEW, { clientId })),
    goToClientJobs: (clientId: string) => navigate(generatePath(ROUTE_PATHS.CLIENT_JOBS, { clientId })),
    
    // Jobs
    goToJobs: () => navigate(ROUTE_PATHS.JOBS),
    goToJob: (jobId: string) => navigate(generatePath(ROUTE_PATHS.JOB_VIEW, { jobId })),
    goToJobReports: (jobId: string) => navigate(generatePath(ROUTE_PATHS.JOB_REPORTS, { jobId })),
    
    // Reports
    goToReports: () => navigate(ROUTE_PATHS.REPORTS),
    goToReport: (reportId: string) => navigate(generatePath(ROUTE_PATHS.REPORT_VIEW, { reportId })),
    goToSharedReport: (reportId: string) => navigate(generatePath(ROUTE_PATHS.SHARED_REPORT, { reportId })),
    
    // Invoices
    goToInvoices: () => navigate(ROUTE_PATHS.INVOICES),
    goToInvoice: (invoiceId: string) => navigate(generatePath(ROUTE_PATHS.INVOICE_VIEW, { invoiceId })),
    
    // Variations
    goToVariations: () => navigate(ROUTE_PATHS.VARIATIONS),
    goToVariation: (variationId: string) => navigate(generatePath(ROUTE_PATHS.VARIATION_VIEW, { variationId })),
    
    // Settings
    goToSettings: () => navigate(ROUTE_PATHS.SETTINGS),
    goToSettingsGeneral: () => navigate(ROUTE_PATHS.SETTINGS_GENERAL),
    goToSettingsCompany: () => navigate(ROUTE_PATHS.SETTINGS_COMPANY),
    goToBrandingSettings: () => navigate(ROUTE_PATHS.SETTINGS_BRANDING),
    goToUserSettings: () => navigate(ROUTE_PATHS.SETTINGS_USERS),
    goToRoleSettings: () => navigate(ROUTE_PATHS.SETTINGS_ROLES),
    goToIntegrationSettings: () => navigate(ROUTE_PATHS.SETTINGS_INTEGRATIONS),
    
    // Profile
    goToProfile: () => navigate(ROUTE_PATHS.PROFILE),
    
    // Search
    goToSearch: (query?: string) => {
      navigate({
        pathname: ROUTE_PATHS.SEARCH_RESULTS,
        search: query ? `?q=${encodeURIComponent(query)}` : ''
      });
    },
    
    // Auth
    goToLogin: () => navigate(ROUTE_PATHS.LOGIN),
    goToSignup: () => navigate(ROUTE_PATHS.SIGNUP),
    goToForgotPassword: () => navigate(ROUTE_PATHS.FORGOT_PASSWORD),
    
    // Navigation helpers
    goBack: () => navigate(-1),
    goForward: () => navigate(1)
  };
}