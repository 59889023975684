import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase } from 'lucide-react';
import { Job } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface JobCardProps {
  job: Job;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function JobCard({ job, onSelect, onEdit, onDelete }: JobCardProps) {
  const progressPercentage = Math.round((job.totalInvoiced / job.contractAmount) * 100);

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden cursor-pointer group"
    >
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3" onClick={onSelect}>
            <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
              <Briefcase className="text-blue-600" size={20} />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                {job.name}
              </h3>
              <p className="text-sm text-gray-500">{job.jobId || 'No Job ID'}</p>
            </div>
          </div>
          <ActionMenu
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>

        <div className="space-y-4">
          <p className="text-sm text-gray-600 line-clamp-2">
            {job.address}
          </p>

          <div>
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-500">Progress</span>
              <span className="text-sm font-medium text-gray-900">
                {progressPercentage}%
              </span>
            </div>
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <motion.div
                className="h-full bg-blue-500"
                initial={{ width: 0 }}
                animate={{ width: `${progressPercentage}%` }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              />
            </div>
          </div>

          <div className="flex items-center justify-between pt-4 border-t border-gray-100">
            <span className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${
              job.status === 'completed'
                ? 'bg-green-50 text-green-700'
                : job.status === 'in-progress'
                ? 'bg-blue-50 text-blue-700'
                : 'bg-gray-50 text-gray-700'
            }`}>
              {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
            </span>
            <span className="text-sm font-medium text-gray-900">
              ${job.contractAmount.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}