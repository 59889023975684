import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { GitBranch, Plus, LayoutGrid, Table2 } from 'lucide-react';
import { useVariations } from '../../hooks/useVariations';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import { Variation } from '../../types/variation';
import VariationCard from './VariationCard';
import VariationTable from '../dashboard/VariationTable';
import VariationFormModal from '../modals/VariationFormModal';
import LoadingState from '../ui/LoadingState';
import ErrorState from '../ui/ErrorState';
import EmptyState from '../ui/EmptyState';
import ConfirmDialog from '../ui/ConfirmDialog';
import { useNotification } from '../../context/NotificationContext';
import { useSearch } from '../../hooks/useSearch';
import SearchAndFilter from '../ui/SearchAndFilter';
import { useViewMode } from '../../hooks/useViewMode';
import { useAppNavigation } from '../../routes/useNavigation';

export default function VariationList() {
  const navigation = useAppNavigation();
  const [viewMode, setViewMode] = useViewMode('variationListViewMode', 'card');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [variationToDelete, setVariationToDelete] = useState<Variation | null>(null);
  const [variationToEdit, setVariationToEdit] = useState<Variation | null>(null);
  const { variations, loading, error, fetchVariations, addVariation, updateVariation, deleteVariation } = useVariations();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { addNotification } = useNotification();

  const {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  } = useSearch({
    data: variations,
    searchFields: ['name', 'description', 'variationId'],
    filterConfig: {
      status: (variation, values) => values.includes(variation.status),
      progressStatus: (variation, values) => values.includes(variation.progressStatus),
    },
  });

  const filters = [
    {
      id: 'status',
      label: 'Status',
      options: [
        { value: 'pending_approval', label: 'Pending Approval' },
        { value: 'approved', label: 'Approved' },
      ],
    },
    {
      id: 'progressStatus',
      label: 'Progress',
      options: [
        { value: 'pending', label: 'Pending' },
        { value: 'in_progress', label: 'In Progress' },
        { value: 'completed', label: 'Completed' },
      ],
    },
  ];

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  const handleDelete = (variation: Variation) => {
    setVariationToDelete(variation);
  };

  const handleEdit = (variation: Variation) => {
    setVariationToEdit(variation);
  };

  const handleConfirmDelete = async () => {
    if (variationToDelete) {
      try {
        await deleteVariation(variationToDelete.id);
        addNotification('success', 'Variation deleted successfully');
      } catch (error) {
        addNotification('error', 'Failed to delete variation');
      }
      setVariationToDelete(null);
    }
  };

  const handleSubmit = async (data: Partial<Variation>) => {
    try {
      if (variationToEdit) {
        await updateVariation({ ...data, id: variationToEdit.id } as Variation);
        addNotification('success', 'Variation updated successfully');
        setVariationToEdit(null);
      } else {
        await addVariation(data);
        addNotification('success', 'Variation created successfully');
        setIsAddModalOpen(false);
      }
    } catch (error) {
      addNotification('error', `Failed to ${variationToEdit ? 'update' : 'create'} variation`);
    }
  };

  const handleVariationClick = (variation: Variation) => {
    navigation.goToVariation(variation.id);
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} onRetry={fetchVariations} />;
  if (!variations.length) {
    return (
      <EmptyState
        title="No Variations Found"
        message="Get started by creating your first variation."
        icon={<GitBranch size={24} className="text-gray-400" />}
        actionLabel="Create Variation"
        onAction={() => setIsAddModalOpen(true)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-violet-50 rounded-lg">
            <GitBranch className="text-violet-600" size={24} />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Variations</h1>
            <p className="text-sm text-gray-500">Manage project variations and changes</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* View Toggle */}
          <div className="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewMode('card')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'card'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <LayoutGrid size={20} />
            </button>
            <button
              onClick={() => setViewMode('table')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'table'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Table2 size={20} />
            </button>
          </div>

          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-violet-500 rounded-lg hover:bg-violet-600 transition-colors"
          >
            <div className="flex items-center gap-2">
              <Plus size={16} />
              <span>Create Variation</span>
            </div>
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <SearchAndFilter
        searchPlaceholder="Search variations..."
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={filters}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
        showFilters={showFilters}
        onToggleFilters={toggleFilters}
      />

      {/* Variations List */}
      {viewMode === 'card' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((variation) => (
            <VariationCard
              key={variation.id}
              variation={variation}
              onSelect={() => handleVariationClick(variation)}
              onEdit={() => handleEdit(variation)}
              onDelete={() => handleDelete(variation)}
            />
          ))}
        </div>
      ) : (
        <VariationTable
          variations={filteredData}
          onSelect={handleVariationClick}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {/* Add/Edit Variation Modal */}
      {(isAddModalOpen || variationToEdit) && (
        <VariationFormModal
          isOpen={true}
          onClose={() => {
            setIsAddModalOpen(false);
            setVariationToEdit(null);
          }}
          onSubmit={handleSubmit}
          initialData={variationToEdit || undefined}
          companyId={companies[0]?.id || ''}
          clientId={clients[0]?.id}
          jobId={jobs[0]?.id}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={!!variationToDelete}
        onClose={() => setVariationToDelete(null)}
        onConfirm={handleConfirmDelete}
        title="Delete Variation"
        message={`Are you sure you want to delete this variation? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </div>
  );
}