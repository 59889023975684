import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, SlidersHorizontal, X, Check, ChevronDown } from 'lucide-react';

interface FilterOption {
  value: string;
  label: string;
}

interface FilterConfig {
  id: string;
  label: string;
  options: FilterOption[];
}

interface SearchAndFilterProps {
  searchPlaceholder?: string;
  searchValue: string;
  onSearchChange: (value: string) => void;
  filters?: FilterConfig[];
  activeFilters: Record<string, string[]>;
  onFilterChange: (filterId: string, values: string[]) => void;
  showFilters?: boolean;
  onToggleFilters?: () => void;
}

export default function SearchAndFilter({
  searchPlaceholder = 'Search...',
  searchValue,
  onSearchChange,
  filters = [],
  activeFilters,
  onFilterChange,
  showFilters = false,
  onToggleFilters,
}: SearchAndFilterProps) {
  const hasActiveFilters = Object.values(activeFilters).some(values => values.length > 0);
  const activeFilterCount = Object.values(activeFilters).reduce((sum, values) => sum + values.length, 0);

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-3">
        {/* Search Input */}
        <div className="flex-1 relative">
          <input
            type="text"
            value={searchValue}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder={searchPlaceholder}
            className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-xl focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition-colors"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
          {searchValue && (
            <button
              onClick={() => onSearchChange('')}
              className="absolute right-3 top-1/2 -translate-y-1/2 p-1 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X size={14} className="text-gray-500" />
            </button>
          )}
        </div>

        {/* Filter Toggle */}
        <motion.button
          onClick={onToggleFilters}
          className={`
            px-4 py-2.5 flex items-center gap-2 rounded-xl border transition-all
            ${showFilters || hasActiveFilters
              ? 'bg-teal-50 border-teal-200 text-teal-600 shadow-sm'
              : 'border-gray-200 text-gray-600 hover:bg-gray-50'
            }
          `}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <SlidersHorizontal size={18} />
          <span className="text-sm font-medium">Filters</span>
          {hasActiveFilters && (
            <span className="flex items-center justify-center w-5 h-5 text-xs font-medium bg-teal-100 text-teal-600 rounded-full">
              {activeFilterCount}
            </span>
          )}
        </motion.button>
      </div>

      {/* Filter Panel */}
      <AnimatePresence>
        {showFilters && filters.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
          >
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {filters.map((filter) => (
                  <div key={filter.id}>
                    <label className="block text-sm font-medium text-gray-900 mb-2">
                      {filter.label}
                    </label>
                    <div className="space-y-2">
                      {filter.options.map((option) => {
                        const isSelected = activeFilters[filter.id]?.includes(option.value);
                        return (
                          <motion.button
                            key={option.value}
                            onClick={() => {
                              const currentValues = activeFilters[filter.id] || [];
                              const newValues = isSelected
                                ? currentValues.filter(v => v !== option.value)
                                : [...currentValues, option.value];
                              onFilterChange(filter.id, newValues);
                            }}
                            className={`
                              w-full flex items-center justify-between px-4 py-2 rounded-lg border text-sm transition-all
                              ${isSelected
                                ? 'bg-teal-50 border-teal-200 text-teal-700 font-medium'
                                : 'border-gray-200 text-gray-700 hover:bg-gray-50'
                              }
                            `}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                          >
                            <span>{option.label}</span>
                            {isSelected && <Check size={16} className="text-teal-600" />}
                          </motion.button>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>

              {/* Active Filters */}
              {hasActiveFilters && (
                <div className="pt-4 border-t border-gray-100">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 flex-wrap">
                      <span className="text-sm text-gray-500">Active filters:</span>
                      {Object.entries(activeFilters).map(([filterId, values]) => (
                        values.map((value) => {
                          const filter = filters.find(f => f.id === filterId);
                          const option = filter?.options.find(o => o.value === value);
                          if (!filter || !option) return null;
                          
                          return (
                            <motion.span
                              key={`${filterId}-${value}`}
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 0.8 }}
                              className="inline-flex items-center gap-1.5 px-3 py-1 bg-teal-50 text-teal-700 rounded-full text-sm font-medium"
                            >
                              {filter.label}: {option.label}
                              <button
                                onClick={() => {
                                  const newValues = activeFilters[filterId].filter(v => v !== value);
                                  onFilterChange(filterId, newValues);
                                }}
                                className="p-0.5 hover:bg-teal-100 rounded-full transition-colors"
                              >
                                <X size={14} />
                              </button>
                            </motion.span>
                          );
                        })
                      ))}
                    </div>
                    <button
                      onClick={() => {
                        Object.keys(activeFilters).forEach(filterId => {
                          onFilterChange(filterId, []);
                        });
                      }}
                      className="text-sm font-medium text-teal-600 hover:text-teal-700 transition-colors"
                    >
                      Clear all
                    </button>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}