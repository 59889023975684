import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  CheckCircle2, 
  HelpCircle,
  Building2,
  Users,
  FileText,
  DollarSign,
  ChevronRight,
  X
} from 'lucide-react';
import PublicLayout from '../../layouts/PublicLayout';

export default function Pricing() {
  const [billingPeriod, setBillingPeriod] = React.useState<'monthly' | 'yearly'>('monthly');
  const [showFaq, setShowFaq] = React.useState<string | null>(null);

  const plans = [
    {
      name: 'Basic',
      description: 'Perfect for small construction companies',
      price: billingPeriod === 'monthly' ? 49 : 490,
      features: [
        'Up to 5 users',
        '10 active projects',
        'Basic progress reports',
        'Client portal access',
        'Email support'
      ],
      limitations: [
        'No custom branding',
        'Basic integrations only',
        'Limited storage (10GB)'
      ]
    },
    {
      name: 'Pro',
      description: 'For growing construction businesses',
      price: billingPeriod === 'monthly' ? 99 : 990,
      popular: true,
      features: [
        'Up to 15 users',
        'Unlimited projects',
        'Advanced progress reports',
        'Custom branding',
        'Xero integration',
        'Priority support',
        '100GB storage'
      ]
    },
    {
      name: 'Enterprise',
      description: 'Custom solutions for large organizations',
      price: 'Custom',
      features: [
        'Unlimited users',
        'Unlimited projects',
        'Custom report templates',
        'Advanced integrations',
        'Dedicated support',
        'Custom storage limits',
        'API access',
        'SLA agreement'
      ]
    }
  ];

  const faqs = [
    {
      question: 'How does the free trial work?',
      answer: 'You get full access to all Pro features for 14 days. No credit card required. At the end of your trial, you can choose to subscribe to any plan or continue with limited features.'
    },
    {
      question: 'Can I change plans later?',
      answer: 'Yes, you can upgrade or downgrade your plan at any time. If you upgrade, you\'ll be charged the prorated difference. If you downgrade, you\'ll receive a credit for your next billing cycle.'
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept all major credit cards (Visa, Mastercard, American Express) and direct bank transfers for annual plans.'
    },
    {
      question: 'Is there a long-term contract?',
      answer: 'No, all our plans are pay-as-you-go. You can cancel at any time and you won\'t be charged for the next billing cycle.'
    },
    {
      question: 'What happens to my data if I cancel?',
      answer: 'You\'ll have 30 days to export your data after cancellation. After that, your data will be permanently deleted from our servers.'
    }
  ];

  return (
    <PublicLayout>
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-gray-900 py-20 sm:py-32">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900" />
        <div className="absolute inset-0 bg-pattern opacity-5" />
        
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1 
              className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Simple, Transparent
              <span className="block text-teal-500">Pricing for Everyone</span>
            </motion.h1>
            <motion.p 
              className="mx-auto mt-6 max-w-2xl text-lg text-gray-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Start with a 14-day free trial. No credit card required.
            </motion.p>

            {/* Billing Toggle */}
            <motion.div
              className="mt-8 flex items-center justify-center gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <button
                onClick={() => setBillingPeriod('monthly')}
                className={`text-sm font-medium ${
                  billingPeriod === 'monthly' 
                    ? 'text-white' 
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Monthly
              </button>
              <div className="relative">
                <div className="w-14 h-8 bg-gray-700 rounded-full">
                  <div
                    className={`absolute top-1 w-6 h-6 bg-white rounded-full transition-all ${
                      billingPeriod === 'yearly' ? 'left-7' : 'left-1'
                    }`}
                  />
                </div>
              </div>
              <button
                onClick={() => setBillingPeriod('yearly')}
                className={`text-sm font-medium ${
                  billingPeriod === 'yearly' 
                    ? 'text-white' 
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Yearly
                <span className="ml-1.5 text-xs text-teal-500">(Save 20%)</span>
              </button>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Pricing Cards */}
      <section className="py-20 sm:py-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {plans.map((plan, index) => (
              <motion.div
                key={plan.name}
                className={`relative bg-white rounded-2xl shadow-sm border ${
                  plan.popular ? 'border-teal-500' : 'border-gray-100'
                }`}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {plan.popular && (
                  <div className="absolute -top-5 left-0 right-0 mx-auto w-32 rounded-full bg-teal-500 py-1 px-3 text-center text-sm font-medium text-white">
                    Most Popular
                  </div>
                )}

                <div className="p-8">
                  <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                  <p className="mt-1 text-sm text-gray-500">{plan.description}</p>
                  
                  <div className="mt-6">
                    {typeof plan.price === 'number' ? (
                      <>
                        <span className="text-4xl font-bold text-gray-900">${plan.price}</span>
                        <span className="text-gray-500">/{billingPeriod === 'monthly' ? 'mo' : 'yr'}</span>
                      </>
                    ) : (
                      <span className="text-4xl font-bold text-gray-900">Contact Us</span>
                    )}
                  </div>

                  <ul className="mt-8 space-y-4">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex items-center gap-3">
                        <CheckCircle2 size={16} className="text-teal-500 flex-shrink-0" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                    {plan.limitations?.map((limitation) => (
                      <li key={limitation} className="flex items-center gap-3">
                        <X size={16} className="text-gray-400 flex-shrink-0" />
                        <span className="text-gray-400">{limitation}</span>
                      </li>
                    ))}
                  </ul>

                  <div className="mt-8">
                    <Link
                      to="/signup"
                      className={`block w-full rounded-lg px-4 py-3 text-center text-sm font-medium ${
                        plan.popular
                          ? 'bg-teal-500 text-white hover:bg-teal-600'
                          : 'bg-gray-900 text-white hover:bg-gray-800'
                      }`}
                    >
                      {typeof plan.price === 'number' ? 'Start Free Trial' : 'Contact Sales'}
                    </Link>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Feature Comparison */}
      <section className="py-20 sm:py-32 bg-gray-50">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Compare Features
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Find the perfect plan for your business
            </p>
          </div>

          {/* Feature comparison table here */}
        </div>
      </section>

      {/* FAQs */}
      <section className="py-20 sm:py-32">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Frequently Asked Questions
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Have a different question? Contact our support team.
            </p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-xl shadow-sm border border-gray-100"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <button
                  onClick={() => setShowFaq(showFaq === faq.question ? null : faq.question)}
                  className="w-full flex items-center justify-between p-6"
                >
                  <div className="flex items-center gap-4">
                    <HelpCircle size={20} className="text-teal-500" />
                    <span className="text-left font-medium text-gray-900">
                      {faq.question}
                    </span>
                  </div>
                  <ChevronRight
                    size={20}
                    className={`text-gray-400 transition-transform ${
                      showFaq === faq.question ? 'rotate-90' : ''
                    }`}
                  />
                </button>
                {showFaq === faq.question && (
                  <div className="px-6 pb-6">
                    <div className="h-px bg-gray-100 mb-6" />
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </PublicLayout>
  );
}