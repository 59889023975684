import React from 'react';
import { motion } from 'framer-motion';
import { Users, Mail, Phone, Globe } from 'lucide-react';
import { Client } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface ClientCardProps {
  client: Client;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function ClientCard({ client, onSelect, onEdit, onDelete }: ClientCardProps) {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden cursor-pointer group"
    >
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3" onClick={onSelect}>
            {client.logo ? (
              <img 
                src={client.logo} 
                alt={client.name}
                className="w-10 h-10 rounded-lg object-cover"
              />
            ) : (
              <div className="p-2 bg-violet-50 rounded-lg group-hover:bg-violet-100 transition-colors">
                <Users className="text-violet-600" size={24} />
              </div>
            )}
            <div>
              <h3 className="font-semibold text-gray-900 group-hover:text-violet-600 transition-colors">
                {client.name}
              </h3>
              <p className="text-sm text-gray-500">
                {client.clientId ? `#${client.clientId}` : 'No Client ID'}
              </p>
            </div>
          </div>
          <ActionMenu
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>

        <div className="space-y-4">
          {client.website && (
            <div className="flex items-center gap-2 text-sm">
              <Globe size={14} className="text-gray-400" />
              <a 
                href={client.website}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-violet-600 hover:underline"
              >
                {new URL(client.website).hostname}
              </a>
            </div>
          )}

          <div className="flex items-center gap-2 text-sm">
            <Mail size={14} className="text-gray-400" />
            <a 
              href={`mailto:${client.email}`}
              onClick={(e) => e.stopPropagation()}
              className="text-violet-600 hover:underline"
            >
              {client.email}
            </a>
          </div>

          <div className="flex items-center gap-2 text-sm">
            <Phone size={14} className="text-gray-400" />
            <a 
              href={`tel:${client.phone}`}
              onClick={(e) => e.stopPropagation()}
              className="text-violet-600 hover:underline"
            >
              {client.phone}
            </a>
          </div>

          {client.address && (
            <p className="text-sm text-gray-600 line-clamp-2">
              {client.address}
            </p>
          )}

          <div className="pt-4 border-t border-gray-100">
            <span className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${
              (client.status || 'active') === 'active' 
                ? 'bg-emerald-50 text-emerald-700' 
                : 'bg-gray-50 text-gray-700'
            }`}>
              {(client.status || 'active').charAt(0).toUpperCase() + (client.status || 'active').slice(1)}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}