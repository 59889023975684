import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Palette, 
  Upload, 
  X, 
  Loader2, 
  CheckCircle2, 
  Eye, 
  Sun, 
  Moon,
  Layout,
  Sidebar,
  Bell,
  Menu,
  Search,
  User,
  ChevronDown,
  FileText,
  DollarSign,
  Building2
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { useBranding } from '../../context/BrandingContext';
import { usePermissions } from '../../hooks/usePermissions';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

export default function BrandingSettings() {
  const { company, updateCompany } = useAuth();
  const { primaryColor, secondaryColor } = useBranding();
  const { addNotification } = useNotification();
  const { canEditCompany } = usePermissions();
  
  const [isLoading, setIsLoading] = useState(false);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(company?.logo || null);
  const [dragActive, setDragActive] = useState(false);
  const [colors, setColors] = useState({
    primary: primaryColor,
    secondary: secondaryColor
  });

  const [previewMode, setPreviewMode] = useState<'light' | 'dark'>('light');
  const [previewSection, setPreviewSection] = useState<'components' | 'dashboard' | 'sidebar'>('components');

  useEffect(() => {
    // Update colors when branding context changes
    setColors({
      primary: primaryColor,
      secondary: secondaryColor
    });
  }, [primaryColor, secondaryColor]);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleLogoFile(e.dataTransfer.files[0]);
    }
  };

  const validateFile = (file: File): boolean => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      addNotification('error', 'Please upload an image file (JPEG, PNG, or GIF)');
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      addNotification('error', 'File size must be less than 5MB');
      return false;
    }

    return true;
  };

  const handleLogoFile = (file: File) => {
    if (!validateFile(file)) return;

    setLogoFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!canEditCompany) {
      addNotification('error', 'You do not have permission to edit branding settings');
      return;
    }

    setIsLoading(true);
    try {
      let logoUrl = company?.logo;

      if (logoFile) {
        // In a real app, implement file upload to your storage service
        // For now, we'll use the data URL as a placeholder
        logoUrl = logoPreview;
      }

      await updateCompany({
        logo: logoUrl,
        brandColors: {
          primary: colors.primary,
          secondary: colors.secondary
        }
      });

      addNotification('success', 'Branding settings updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update branding settings');
    } finally {
      setIsLoading(false);
    }
  };

  if (!canEditCompany) {
    return (
      <div className="text-center py-12">
        <Palette className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Access</h3>
        <p className="mt-1 text-sm text-gray-500">
          You don't have permission to edit branding settings.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <div className="p-2 bg-violet-50 rounded-lg">
          <Palette className="text-violet-600" size={20} />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Branding Settings</h2>
          <p className="text-sm text-gray-500">Customize your company's visual identity</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Company Logo */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <h3 className="text-sm font-medium text-gray-900 mb-4">Company Logo</h3>
          <div
            className={`border-2 border-dashed rounded-lg p-6 text-center ${
              dragActive ? 'border-violet-500 bg-violet-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {logoPreview ? (
              <div className="relative inline-block">
                <img
                  src={logoPreview}
                  alt="Logo preview"
                  className="max-h-32 rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => {
                    setLogoFile(null);
                    setLogoPreview(null);
                  }}
                  className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                >
                  <X size={12} />
                </button>
              </div>
            ) : (
              <>
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="mt-4 flex text-sm text-gray-600 justify-center">
                  <label className="relative cursor-pointer rounded-md font-medium text-violet-600 hover:text-violet-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      className="sr-only"
                      accept={ALLOWED_FILE_TYPES.join(',')}
                      onChange={(e) => e.target.files && handleLogoFile(e.target.files[0])}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
              </>
            )}
          </div>
        </div>

        {/* Brand Colors */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <h3 className="text-sm font-medium text-gray-900 mb-4">Brand Colors</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Primary Color
              </label>
              <div className="flex items-center gap-3">
                <input
                  type="color"
                  value={colors.primary}
                  onChange={e => setColors(prev => ({ ...prev, primary: e.target.value }))}
                  className="h-10 w-20 rounded-lg border border-gray-300"
                />
                <input
                  type="text"
                  value={colors.primary}
                  onChange={e => setColors(prev => ({ ...prev, primary: e.target.value }))}
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Secondary Color
              </label>
              <div className="flex items-center gap-3">
                <input
                  type="color"
                  value={colors.secondary}
                  onChange={e => setColors(prev => ({ ...prev, secondary: e.target.value }))}
                  className="h-10 w-20 rounded-lg border border-gray-300"
                />
                <input
                  type="text"
                  value={colors.secondary}
                  onChange={e => setColors(prev => ({ ...prev, secondary: e.target.value }))}
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Preview */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-violet-50 rounded-lg">
                <Eye className="text-violet-600" size={20} />
              </div>
              <h3 className="text-sm font-medium text-gray-900">Preview</h3>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => setPreviewMode('light')}
                className={`p-2 rounded-lg ${
                  previewMode === 'light'
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Sun size={16} />
              </button>
              <button
                type="button"
                onClick={() => setPreviewMode('dark')}
                className={`p-2 rounded-lg ${
                  previewMode === 'dark'
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Moon size={16} />
              </button>
            </div>
          </div>

          {/* Preview Navigation */}
          <div className="flex items-center gap-2 mb-6 bg-gray-50 p-1 rounded-lg">
            <button
              type="button"
              onClick={() => setPreviewSection('components')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                previewSection === 'components'
                  ? 'bg-white shadow-sm text-gray-900'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Layout size={16} />
              Components
            </button>
            <button
              type="button"
              onClick={() => setPreviewSection('dashboard')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                previewSection === 'dashboard'
                  ? 'bg-white shadow-sm text-gray-900'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Layout size={16} />
              Dashboard
            </button>
            <button
              type="button"
              onClick={() => setPreviewSection('sidebar')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                previewSection === 'sidebar'
                  ? 'bg-white shadow-sm text-gray-900'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Sidebar size={16} />
              Navigation
            </button>
          </div>

          <div className={`p-6 rounded-lg ${
            previewMode === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
          }`}>
            <AnimatePresence mode="wait">
              {previewSection === 'components' && (
                <PreviewComponents colors={colors} previewMode={previewMode} />
              )}
              {previewSection === 'dashboard' && (
                <PreviewDashboard 
                  colors={colors} 
                  previewMode={previewMode}
                  logo={logoPreview}
                  companyName={company?.name}
                />
              )}
              {previewSection === 'sidebar' && (
                <PreviewSidebar 
                  colors={colors} 
                  previewMode={previewMode}
                  logo={logoPreview}
                  companyName={company?.name}
                />
              )}
            </AnimatePresence>
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <motion.button
            type="submit"
            disabled={isLoading}
            className="flex items-center gap-2 px-6 py-2 text-white bg-violet-500 rounded-lg hover:bg-violet-600 disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin" size={16} />
                <span>Saving...</span>
              </>
            ) : (
              <>
                <CheckCircle2 size={16} />
                <span>Save Changes</span>
              </>
            )}
          </motion.button>
        </div>
      </form>
    </div>
  );
}

interface PreviewProps {
  colors: {
    primary: string;
    secondary: string;
  };
  previewMode: 'light' | 'dark';
  logo?: string | null;
  companyName?: string;
}

function PreviewComponents({ colors, previewMode }: PreviewProps) {
  const textColor = previewMode === 'dark' ? 'text-white' : 'text-gray-900';
  
  const components = [
    {
      name: 'Buttons',
      render: (color: string) => (
        <div className="space-y-2">
          <button
            type="button"
            className="w-full px-4 py-2 rounded-lg text-white transition-colors"
            style={{ backgroundColor: color }}
          >
            Primary Button
          </button>
          <button
            type="button"
            className="w-full px-4 py-2 rounded-lg border transition-colors"
            style={{ borderColor: color, color }}
          >
            Secondary Button
          </button>
        </div>
      )
    },
    {
      name: 'Badges',
      render: (color: string) => (
        <div className="space-x-2">
          <span
            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
            style={{ 
              backgroundColor: `${color}20`,
              color: color
            }}
          >
            Default
          </span>
          <span
            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
            style={{ 
              backgroundColor: color,
              color: 'white'
            }}
          >
            Solid
          </span>
        </div>
      )
    },
    {
      name: 'Cards',
      render: (color: string) => (
        <div 
          className="p-4 rounded-lg border transition-colors"
          style={{ 
            borderColor: `${color}40`,
            backgroundColor: `${color}10`
          }}
        >
          <h4 className="font-medium" style={{ color }}>Card Title</h4>
          <p className={`text-sm ${previewMode === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Card content goes here with some sample text.
          </p>
        </div>
      )
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="grid grid-cols-1 md:grid-cols-2 gap-6"
    >
      {components.map((component, index) => (
        <div key={index} className="space-y-2">
          <p className={`text-sm font-medium ${textColor}`}>
            {component.name}
          </p>
          <div className="space-y-4">
            {component.render(colors.primary)}
            {component.render(colors.secondary)}
          </div>
        </div>
      ))}
    </motion.div>
  );
}

function PreviewDashboard({ colors, previewMode, logo, companyName }: PreviewProps) {
  const textColor = previewMode === 'dark' ? 'text-white' : 'text-gray-900';
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="space-y-4"
    >
      {/* Header */}
      <div className={`flex items-center justify-between p-4 ${
        previewMode === 'dark' ? 'bg-gray-800' : 'bg-white'
      } rounded-lg shadow-sm`}>
        <div className="flex items-center gap-4">
          {logo && (
            <img
              src={logo}
              alt={companyName}
              className="h-8 w-auto"
            />
          )}
          <div>
            <h4 className={`font-medium ${textColor}`}>
              {companyName || 'Your Company Name'}
            </h4>
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <Menu size={14} />
              <span>Dashboard</span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Search size={20} className="text-gray-400" />
          <Bell size={20} className="text-gray-400" />
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
              <User size={16} className="text-gray-600" />
            </div>
            <ChevronDown size={16} className="text-gray-400" />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="grid grid-cols-3 gap-4">
        <div 
          className="p-4 rounded-lg"
          style={{ 
            backgroundColor: colors.primary,
            color: 'white'
          }}
        >
          <FileText size={20} className="mb-2" />
          <h5 className="font-medium">Reports</h5>
          <p className="text-sm opacity-80">24 this month</p>
        </div>
        <div 
          className="p-4 rounded-lg"
          style={{ 
            backgroundColor: colors.secondary,
            color: 'white'
          }}
        >
          <DollarSign size={20} className="mb-2" />
          <h5 className="font-medium">Revenue</h5>
          <p className="text-sm opacity-80">$45,231</p>
        </div>
        <div 
          className="p-4 rounded-lg border"
          style={{ 
            borderColor: `${colors.primary}40`,
            backgroundColor: `${colors.primary}10`,
            color: colors.primary
          }}
        >
          <Building2 size={20} className="mb-2" />
          <h5 className="font-medium">Projects</h5>
          <p className="text-sm opacity-80">12 active</p>
        </div>
      </div>
    </motion.div>
  );
}

function PreviewSidebar({ colors, previewMode, logo, companyName }: PreviewProps) {
  const textColor = previewMode === 'dark' ? 'text-white' : 'text-gray-900';
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="flex h-[300px] rounded-lg overflow-hidden border border-gray-200"
    >
      <div className={`w-64 ${
        previewMode === 'dark' ? 'bg-gray-800' : 'bg-white'
      } border-r border-gray-200 p-4`}>
        <div className="flex items-center gap-3 mb-6">
          {logo && (
            <img
              src={logo}
              alt={companyName}
              className="h-8 w-auto"
            />
          )}
          <div>
            <h4 className={`font-medium ${textColor}`}>
              {companyName || 'Your Company'}
            </h4>
          </div>
        </div>

        <nav className="space-y-1">
          {['Dashboard', 'Reports', 'Projects', 'Settings'].map((item) => (
            <button
              key={item}
              className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm transition-colors ${
                item === 'Dashboard'
                  ? 'text-white'
                  : previewMode === 'dark'
                  ? 'text-gray-400 hover:text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
              style={{ 
                backgroundColor: item === 'Dashboard' ? colors.primary : undefined
              }}
            >
              <FileText size={16} />
              {item}
            </button>
          ))}
        </nav>
      </div>
      <div className={`flex-1 ${
        previewMode === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
      }`}></div>
    </motion.div>
  );
}