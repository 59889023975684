import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Calendar, DollarSign, Clock, CheckCircle2, XCircle, AlertTriangle } from 'lucide-react';
import { Invoice } from '../../types/invoice';
import { Client } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface InvoiceCardProps {
  invoice: Invoice;
  client: Client | undefined;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function InvoiceCard({ invoice, client, onSelect, onEdit, onDelete }: InvoiceCardProps) {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'paid':
        return 'bg-emerald-50 text-emerald-600';
      case 'pending':
        return 'bg-amber-50 text-amber-600';
      case 'overdue':
        return 'bg-red-50 text-red-600';
      case 'void':
        return 'bg-gray-50 text-gray-600';
      default:
        return 'bg-gray-50 text-gray-600';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'paid':
        return <CheckCircle2 size={14} />;
      case 'pending':
        return <Clock size={14} />;
      case 'overdue':
        return <AlertTriangle size={14} />;
      case 'void':
        return <XCircle size={14} />;
      default:
        return <Clock size={14} />;
    }
  };

  const isOverdue = new Date(invoice.dueDate) < new Date() && invoice.status !== 'paid';

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden cursor-pointer group ${
        isOverdue ? 'bg-red-50/10' : ''
      }`}
      onClick={onSelect}
    >
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
              <FileText className="text-blue-600" size={24} />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                {invoice.invoiceNumber}
              </h3>
              <p className="text-sm text-gray-500">{client?.name}</p>
            </div>
          </div>
          <div onClick={e => e.stopPropagation()}>
            <ActionMenu
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </div>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <span className="text-sm text-gray-500">Date</span>
              <div className="flex items-center gap-2 mt-1">
                <Calendar size={14} className="text-gray-400" />
                <span className="text-sm text-gray-900">
                  {new Date(invoice.date).toLocaleDateString()}
                </span>
              </div>
            </div>
            <div>
              <span className="text-sm text-gray-500">Due Date</span>
              <div className="flex items-center gap-2 mt-1">
                <Calendar size={14} className={isOverdue ? 'text-red-400' : 'text-gray-400'} />
                <span className={`text-sm ${isOverdue ? 'text-red-600 font-medium' : 'text-gray-900'}`}>
                  {new Date(invoice.dueDate).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between pt-4 border-t border-gray-100">
            <div className="space-y-1">
              <div className="flex items-center gap-2">
                <DollarSign size={14} className="text-gray-400" />
                <span className="text-sm font-medium text-gray-900">
                  ${invoice.total.toLocaleString()}
                </span>
              </div>
              {invoice.balance > 0 && (
                <div className="flex items-center gap-2">
                  <DollarSign size={14} className="text-amber-400" />
                  <span className="text-sm font-medium text-amber-600">
                    ${invoice.balance.toLocaleString()}
                  </span>
                </div>
              )}
            </div>
            <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${
              getStatusColor(invoice.status)
            }`}>
              {getStatusIcon(invoice.status)}
              {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
            </span>
          </div>

          {invoice.reference && (
            <div className="pt-4 border-t border-gray-100">
              <span className="text-xs text-gray-500">
                Reference: {invoice.reference}
              </span>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}