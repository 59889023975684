import { useAuth } from '../context/AuthContext';
import { PERMISSIONS, SYSTEM_ROLES } from '../constants/permissions';
import { Permission, UserRole, PermissionAction } from '../types/auth';

export const ROLE_DESCRIPTIONS = {
  super_admin: {
    name: 'Super Admin',
    description: 'Full access to all features and can manage all organizations'
  },
  org_admin: {
    name: 'Organization Admin',
    description: 'Full access to organization features and can manage users'
  },
  admin: {
    name: 'Admin',
    description: 'Can manage most features but cannot modify organization settings'
  },
  user: {
    name: 'User',
    description: 'Can view and manage assigned resources'
  },
  client: {
    name: 'Client',
    description: 'Can view their own reports and invoices'
  }
};

export function usePermissions() {
  const { user } = useAuth();

  // Role checks
  const isSuperAdmin = user?.role === 'super_admin';
  const isOrgAdmin = user?.role === 'org_admin';
  const isAdmin = user?.role === 'admin';
  const isUser = user?.role === 'user';
  const isClient = user?.role === 'client';

  // Permission check helper
  const hasPermission = (permissionId: string, action: PermissionAction): boolean => {
    if (!user) return false;
    if (isSuperAdmin) return true;

    const rolePermissions = SYSTEM_ROLES[user.role]?.permissions;
    if (!rolePermissions) return false;

    const permission = rolePermissions.find(p => p.permissionId === permissionId);
    return permission?.actions.includes(action) || false;
  };

  // Company permissions
  const canViewCompany = hasPermission('manage_company', 'view');
  const canEditCompany = hasPermission('manage_company', 'edit');
  const canManageCompany = canEditCompany;

  // User permissions
  const canViewUsers = hasPermission('manage_users', 'view');
  const canCreateUsers = hasPermission('manage_users', 'create');
  const canEditUsers = hasPermission('manage_users', 'edit');
  const canDeleteUsers = hasPermission('manage_users', 'delete');
  const canManageUsers = canCreateUsers || canEditUsers || canDeleteUsers;

  // Role permissions
  const canViewRoles = hasPermission('manage_roles', 'view');
  const canCreateRoles = hasPermission('manage_roles', 'create');
  const canEditRoles = hasPermission('manage_roles', 'edit');
  const canDeleteRoles = hasPermission('manage_roles', 'delete');
  const canManageRoles = canCreateRoles || canEditRoles || canDeleteRoles;

  // Client permissions
  const canViewClients = hasPermission('manage_clients', 'view');
  const canCreateClients = hasPermission('manage_clients', 'create');
  const canEditClients = hasPermission('manage_clients', 'edit');
  const canDeleteClients = hasPermission('manage_clients', 'delete');
  const canManageClients = canCreateClients || canEditClients || canDeleteClients;

  // Job permissions
  const canViewJobs = hasPermission('manage_jobs', 'view');
  const canCreateJobs = hasPermission('manage_jobs', 'create');
  const canEditJobs = hasPermission('manage_jobs', 'edit');
  const canDeleteJobs = hasPermission('manage_jobs', 'delete');
  const canApproveJobs = hasPermission('manage_jobs', 'approve');
  const canManageJobs = canCreateJobs || canEditJobs || canDeleteJobs || canApproveJobs;

  // Report permissions
  const canViewReports = hasPermission('manage_reports', 'view');
  const canCreateReports = hasPermission('manage_reports', 'create');
  const canEditReports = hasPermission('manage_reports', 'edit');
  const canDeleteReports = hasPermission('manage_reports', 'delete');
  const canApproveReports = hasPermission('manage_reports', 'approve');
  const canManageReports = canCreateReports || canEditReports || canDeleteReports || canApproveReports;

  // Invoice permissions
  const canViewInvoices = hasPermission('manage_invoices', 'view');
  const canCreateInvoices = hasPermission('manage_invoices', 'create');
  const canEditInvoices = hasPermission('manage_invoices', 'edit');
  const canDeleteInvoices = hasPermission('manage_invoices', 'delete');
  const canApproveInvoices = hasPermission('manage_invoices', 'approve');
  const canManageInvoices = canCreateInvoices || canEditInvoices || canDeleteInvoices || canApproveInvoices;

  // Variation permissions
  const canViewVariations = hasPermission('manage_variations', 'view');
  const canCreateVariations = hasPermission('manage_variations', 'create');
  const canEditVariations = hasPermission('manage_variations', 'edit');
  const canDeleteVariations = hasPermission('manage_variations', 'delete');
  const canApproveVariations = hasPermission('manage_variations', 'approve');
  const canManageVariations = canCreateVariations || canEditVariations || canDeleteVariations || canApproveVariations;

  // Task permissions
  const canViewTasks = !isClient && hasPermission('manage_tasks', 'view');
  const canCreateTasks = !isClient && hasPermission('manage_tasks', 'create');
  const canEditTasks = !isClient && hasPermission('manage_tasks', 'edit');
  const canDeleteTasks = !isClient && hasPermission('manage_tasks', 'delete');
  const canAssignTasks = !isClient && hasPermission('manage_tasks', 'assign');
  const canManageTasks = canCreateTasks || canEditTasks || canDeleteTasks || canAssignTasks;

  // Settings permissions
  const canViewSettings = hasPermission('manage_settings', 'view');
  const canEditSettings = hasPermission('manage_settings', 'edit');
  const canManageSettings = canEditSettings;

  // Integration permissions
  const canManageIntegrations = hasPermission('manage_settings', 'manage');

  return {
    // Role checks
    isSuperAdmin,
    isOrgAdmin,
    isAdmin,
    isUser,
    isClient,

    // Permission checks
    hasPermission,

    // Company permissions
    canViewCompany,
    canEditCompany,
    canManageCompany,

    // User permissions
    canViewUsers,
    canCreateUsers,
    canEditUsers,
    canDeleteUsers,
    canManageUsers,

    // Role permissions
    canViewRoles,
    canCreateRoles,
    canEditRoles,
    canDeleteRoles,
    canManageRoles,

    // Client permissions
    canViewClients,
    canCreateClients,
    canEditClients,
    canDeleteClients,
    canManageClients,

    // Job permissions
    canViewJobs,
    canCreateJobs,
    canEditJobs,
    canDeleteJobs,
    canApproveJobs,
    canManageJobs,

    // Report permissions
    canViewReports,
    canCreateReports,
    canEditReports,
    canDeleteReports,
    canApproveReports,
    canManageReports,

    // Invoice permissions
    canViewInvoices,
    canCreateInvoices,
    canEditInvoices,
    canDeleteInvoices,
    canApproveInvoices,
    canManageInvoices,

    // Variation permissions
    canViewVariations,
    canCreateVariations,
    canEditVariations,
    canDeleteVariations,
    canApproveVariations,
    canManageVariations,

    // Task permissions
    canViewTasks,
    canCreateTasks,
    canEditTasks,
    canDeleteTasks,
    canAssignTasks,
    canManageTasks,

    // Settings permissions
    canViewSettings,
    canEditSettings,
    canManageSettings,
    canManageIntegrations,
  };
}

export { PERMISSIONS, SYSTEM_ROLES } from '../constants/permissions';
export type { Permission, UserRole, PermissionAction } from '../types/auth';