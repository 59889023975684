import React from 'react';

interface FormFieldProps {
  label: string;
  name: string;
  type?: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  rows?: number;
  min?: string | number;
  max?: string | number;
  step?: string | number;
  prefix?: string;
  suffix?: string;
  multiline?: boolean;
}

export default function FormField({
  label,
  name,
  type = 'text',
  value = '',
  onChange,
  onBlur,
  required,
  placeholder,
  disabled,
  rows = 3,
  min,
  max,
  step,
  prefix,
  suffix,
  multiline = false,
}: FormFieldProps) {
  const inputClasses = `
    w-full px-3 py-2 border border-gray-300 rounded-lg 
    focus:ring-2 focus:ring-teal-500 focus:border-teal-500 
    disabled:bg-gray-50 disabled:text-gray-500 
    transition-colors
    ${prefix ? 'pl-8' : ''}
    ${suffix ? 'pr-8' : ''}
  `;

  return (
    <div className="space-y-1">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>

      <div className="relative">
        {prefix && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500">{prefix}</span>
          </div>
        )}

        {multiline ? (
          <textarea
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
            className={inputClasses}
          />
        ) : (
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            min={min}
            max={max}
            step={step}
            className={inputClasses}
          />
        )}

        {suffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500">{suffix}</span>
          </div>
        )}
      </div>
    </div>
  );
}