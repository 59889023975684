import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MessageSquare, Clock } from 'lucide-react';

interface CommentsProgressProps {
  comments: string;
}

export default function CommentsProgress({ comments }: CommentsProgressProps) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100" ref={ref}>
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-violet-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Project Comments</h2>
              <p className="text-sm text-gray-500 mt-1">Additional notes and observations</p>
            </div>
          </div>
          <div className="p-2 bg-violet-50 rounded-xl">
            <MessageSquare className="text-violet-600" size={24} />
          </div>
        </div>

        <div className="bg-violet-50/50 rounded-xl p-6 border border-violet-100 transition-all hover:shadow-md">
          <div className="flex items-start gap-4">
            <div className="flex-shrink-0">
              <div className="w-10 h-10 bg-violet-100 rounded-full flex items-center justify-center">
                <Clock className="text-violet-600" size={20} />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex items-center gap-2 mb-3">
                <h3 className="font-semibold text-gray-900">Latest Update</h3>
                <span className="text-xs bg-violet-100 text-violet-600 px-2 py-1 rounded-full">
                  {new Date().toLocaleDateString()}
                </span>
              </div>
              <div className="prose prose-sm max-w-none">
                <p className="text-gray-600 whitespace-pre-line leading-relaxed">
                  {comments}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}