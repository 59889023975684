import { useCallback } from 'react';
import { useData } from '../context/DataContext';
import { Client } from '../types';
import { generateId } from '../utils/helpers';
import { mockClients } from '../services/mockData';

export function useClients() {
  const { state, dispatch } = useData();

  const fetchClients = useCallback(async (companyId?: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const clients = companyId
        ? mockClients.filter(client => client.companyId === companyId)
        : mockClients;
      dispatch({ type: 'SET_CLIENTS', payload: clients });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch clients' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const addClient = useCallback(async (client: Partial<Client>) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const newClient: Client = {
        ...client,
        id: generateId(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Client;
      dispatch({ type: 'ADD_CLIENT', payload: newClient });
      return newClient;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add client' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const updateClient = useCallback(async (client: Client) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedClient = {
        ...client,
        updatedAt: new Date(),
      };
      dispatch({ type: 'UPDATE_CLIENT', payload: updatedClient });
      return updatedClient;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update client' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const deleteClient = useCallback(async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'DELETE_CLIENT', payload: id });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete client' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  return {
    clients: state.clients,
    loading: state.loading,
    error: state.error,
    fetchClients,
    addClient,
    updateClient,
    deleteClient,
  };
}