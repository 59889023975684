import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, CheckCircle2, Clock, FileEdit } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Invoice } from '../../types/invoice';

interface InvoiceTableProps {
  invoices: Invoice[];
}

export default function InvoiceTable({ invoices }: InvoiceTableProps) {
  const navigate = useNavigate();
  const total = invoices.reduce((sum, invoice) => sum + invoice.total, 0);

  const getStatusConfig = (status: string) => {
    switch (status.toLowerCase()) {
      case 'paid':
        return {
          icon: CheckCircle2,
          color: 'text-emerald-600',
          bg: 'bg-emerald-50',
          border: 'border-emerald-200',
        };
      case 'pending':
        return {
          icon: Clock,
          color: 'text-amber-600',
          bg: 'bg-amber-50',
          border: 'border-amber-200',
        };
      default:
        return {
          icon: FileEdit,
          color: 'text-gray-600',
          bg: 'bg-gray-50',
          border: 'border-gray-200',
        };
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-emerald-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Invoices</h2>
              <p className="text-sm text-gray-500 mt-1">Payment history and invoice tracking</p>
            </div>
          </div>
          <div className="p-2 bg-emerald-50 rounded-xl">
            <DollarSign className="text-emerald-500" size={24} />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-100">
                <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Invoice No</th>
                <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Reference</th>
                <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Date</th>
                <th className="text-right py-3 px-4 text-sm font-medium text-gray-500">Amount</th>
                <th className="text-right py-3 px-4 text-sm font-medium text-gray-500">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50">
              {invoices.map((invoice, index) => {
                const statusConfig = getStatusConfig(invoice.status);
                const StatusIcon = statusConfig.icon;
                
                return (
                  <motion.tr
                    key={invoice.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    onClick={() => navigate(`/app/invoices/${invoice.id}`)}
                    className="cursor-pointer hover:bg-gray-50 transition-colors"
                  >
                    <td className="py-3 px-4 text-sm text-gray-900 font-medium">
                      {invoice.invoiceNumber}
                    </td>
                    <td className="py-3 px-4 text-sm text-gray-600">
                      {invoice.reference}
                    </td>
                    <td className="py-3 px-4 text-sm text-gray-600">
                      {new Date(invoice.date).toLocaleDateString()}
                    </td>
                    <td className="py-3 px-4 text-sm text-gray-900 text-right font-medium">
                      ${invoice.total.toLocaleString()}
                    </td>
                    <td className="py-3 px-4">
                      <div className="flex justify-end">
                        <motion.div 
                          className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full border ${statusConfig.bg} ${statusConfig.border}`}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <StatusIcon size={14} className={statusConfig.color} />
                          <span className={`text-xs font-medium ${statusConfig.color}`}>
                            {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                          </span>
                        </motion.div>
                      </div>
                    </td>
                  </motion.tr>
                );
              })}
              <tr className="bg-gray-50 font-medium">
                <td className="py-3 px-4 text-sm text-gray-600" colSpan={3}>
                  Total
                </td>
                <td className="py-3 px-4 text-sm text-gray-900 text-right font-medium">
                  ${total.toLocaleString()}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}