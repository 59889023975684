import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  ArrowUpRight, Calendar, DollarSign, ChevronUp, ChevronDown,
  AlertTriangle, CheckCircle2, Clock, TrendingUp, TrendingDown,
  Eye, FileText, Briefcase
} from 'lucide-react';
import { Job } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface JobTableProps {
  jobs: Job[];
  onSelect: (job: Job) => void;
  onEdit: (job: Job) => void;
  onDelete: (job: Job) => void;
}

type SortField = 'name' | 'status' | 'progress' | 'contractAmount' | 'totalInvoiced' | 'startDate';
type SortDirection = 'asc' | 'desc';

export default function JobTable({ jobs, onSelect, onEdit, onDelete }: JobTableProps) {
  const [sortField, setSortField] = useState<SortField>('startDate');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedJobs = [...jobs].sort((a, b) => {
    let comparison = 0;
    
    switch (sortField) {
      case 'name':
        comparison = a.name.localeCompare(b.name);
        break;
      case 'status':
        comparison = a.status.localeCompare(b.status);
        break;
      case 'progress':
        const progressA = (a.totalInvoiced / a.contractAmount) * 100;
        const progressB = (b.totalInvoiced / b.contractAmount) * 100;
        comparison = progressA - progressB;
        break;
      case 'contractAmount':
        comparison = a.contractAmount - b.contractAmount;
        break;
      case 'totalInvoiced':
        comparison = a.totalInvoiced - b.totalInvoiced;
        break;
      case 'startDate':
        comparison = new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        break;
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <ChevronUp className="opacity-0 group-hover:opacity-50" size={14} />;
    return sortDirection === 'asc' ? 
      <ChevronUp className="text-blue-500" size={14} /> : 
      <ChevronDown className="text-blue-500" size={14} />;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-emerald-50 text-emerald-700 border-emerald-200';
      case 'in-progress':
        return 'bg-blue-50 text-blue-700 border-blue-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 size={14} className="text-emerald-500" />;
      case 'in-progress':
        return <Clock size={14} className="text-blue-500" />;
      default:
        return <AlertTriangle size={14} className="text-gray-500" />;
    }
  };

  const getProgressColor = (percentage: number) => {
    if (percentage >= 75) return 'bg-emerald-500';
    if (percentage >= 50) return 'bg-blue-500';
    if (percentage >= 25) return 'bg-amber-500';
    return 'bg-gray-500';
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50">
              <th 
                className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('name')}
              >
                <div className="flex items-center gap-2">
                  <Briefcase size={16} className="text-gray-400" />
                  <span>Job</span>
                  <SortIcon field="name" />
                </div>
              </th>
              <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">
                <div className="flex items-center gap-2">
                  <Eye size={16} className="text-gray-400" />
                  <span>Details</span>
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('status')}
              >
                <div className="flex items-center justify-center gap-2">
                  <FileText size={16} className="text-gray-400" />
                  <span>Status</span>
                  <SortIcon field="status" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('progress')}
              >
                <div className="flex items-center justify-center gap-2">
                  <TrendingUp size={16} className="text-gray-400" />
                  <span>Progress</span>
                  <SortIcon field="progress" />
                </div>
              </th>
              <th 
                className="text-right py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('contractAmount')}
              >
                <div className="flex items-center justify-end gap-2">
                  <DollarSign size={16} className="text-gray-400" />
                  <span>Contract Value</span>
                  <SortIcon field="contractAmount" />
                </div>
              </th>
              <th 
                className="text-right py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('totalInvoiced')}
              >
                <div className="flex items-center justify-end gap-2">
                  <DollarSign size={16} className="text-gray-400" />
                  <span>Invoiced</span>
                  <SortIcon field="totalInvoiced" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('startDate')}
              >
                <div className="flex items-center justify-center gap-2">
                  <Calendar size={16} className="text-gray-400" />
                  <span>Timeline</span>
                  <SortIcon field="startDate" />
                </div>
              </th>
              <th className="text-right py-4 px-6 text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50">
            {sortedJobs.map((job) => {
              const progressPercentage = Math.round((job.totalInvoiced / job.contractAmount) * 100);
              const isOverdue = new Date(job.estimatedEndDate) < new Date() && job.status !== 'completed';
              const isHovered = hoveredRow === job.id;
              
              return (
                <motion.tr
                  key={job.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`group hover:bg-gray-50 cursor-pointer transition-colors ${
                    isOverdue ? 'bg-red-50/30' : ''
                  }`}
                  onClick={() => onSelect(job)}
                  onMouseEnter={() => setHoveredRow(job.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      <div className={`p-2 rounded-lg transition-colors ${
                        isHovered ? 'bg-blue-100' : 'bg-blue-50'
                      }`}>
                        <Briefcase size={16} className="text-blue-600" />
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">{job.name}</p>
                        <p className="text-sm text-gray-500">{job.jobId || 'No Job ID'}</p>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="max-w-xs">
                      <p className="text-sm text-gray-600 line-clamp-2">{job.address}</p>
                      {job.description && (
                        <p className="text-xs text-gray-500 mt-1 line-clamp-1">{job.description}</p>
                      )}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-center">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium border ${
                        getStatusColor(job.status)
                      }`}>
                        {getStatusIcon(job.status)}
                        {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex flex-col items-center gap-1">
                      <div className="flex items-center justify-center w-full gap-2">
                        <div className="w-24 h-2 bg-gray-100 rounded-full overflow-hidden">
                          <motion.div
                            className={`h-full ${getProgressColor(progressPercentage)}`}
                            initial={{ width: 0 }}
                            animate={{ width: `${progressPercentage}%` }}
                            transition={{ duration: 0.5, ease: "easeOut" }}
                          />
                        </div>
                        <span className="text-sm font-medium text-gray-900">
                          {progressPercentage}%
                        </span>
                      </div>
                      <div className="flex items-center gap-1 text-xs">
                        {progressPercentage > 0 && (
                          <>
                            {progressPercentage >= job.totalInvoiced / job.contractAmount * 100 ? (
                              <TrendingUp size={12} className="text-emerald-500" />
                            ) : (
                              <TrendingDown size={12} className="text-red-500" />
                            )}
                            <span className={progressPercentage >= job.totalInvoiced / job.contractAmount * 100 ? 
                              'text-emerald-600' : 'text-red-600'}>
                              {Math.abs(progressPercentage - (job.totalInvoiced / job.contractAmount * 100)).toFixed(1)}% diff
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6 text-right">
                    <span className="text-sm font-medium text-gray-900">
                      ${job.contractAmount.toLocaleString()}
                    </span>
                    {job.approvedVariationValue > 0 && (
                      <div className="text-xs text-emerald-600 mt-1">
                        +${job.approvedVariationValue.toLocaleString()}
                      </div>
                    )}
                  </td>
                  <td className="py-4 px-6 text-right">
                    <span className="text-sm font-medium text-gray-900">
                      ${job.totalInvoiced.toLocaleString()}
                    </span>
                    {job.pendingVariationValue > 0 && (
                      <div className="text-xs text-amber-600 mt-1">
                        +${job.pendingVariationValue.toLocaleString()} pending
                      </div>
                    )}
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex flex-col items-center text-sm text-gray-500">
                      <div className="flex items-center gap-1">
                        <Calendar size={14} />
                        <span>{formatDate(job.startDate)}</span>
                      </div>
                      <span className="text-xs">to</span>
                      <div className="flex items-center gap-1">
                        <Calendar size={14} />
                        <span className={isOverdue ? 'text-red-500 font-medium' : ''}>
                          {formatDate(job.estimatedEndDate)}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex items-center justify-end gap-2">
                      <ActionMenu
                        onEdit={() => onEdit(job)}
                        onDelete={() => onDelete(job)}
                      />
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelect(job);
                        }}
                        className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                      >
                        <ArrowUpRight size={16} />
                      </button>
                    </div>
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}