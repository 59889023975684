import React from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Users, Building2, Mail, Phone, Globe, MapPin,
  Briefcase, FileText, DollarSign, GitBranch,
  ChevronLeft, ArrowUpRight
} from 'lucide-react';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import { useReports } from '../../hooks/useReports';
import { useInvoices } from '../../hooks/useInvoices';
import { useVariations } from '../../hooks/useVariations';

export default function ClientView() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { reports } = useReports();
  const { invoices } = useInvoices();
  const { variations } = useVariations();

  const client = clients.find(c => c.id === clientId);
  
  // Filter related items
  const clientJobs = jobs.filter(j => j.clientId === clientId);
  const clientReports = reports.filter(r => r.clientId === clientId);
  const clientInvoices = invoices.filter(i => i.clientId === clientId);
  const clientVariations = variations.filter(v => v.clientId === clientId);

  if (!client) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Client Not Found</h2>
          <p className="text-gray-500 mb-4">The requested client could not be found.</p>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 text-sm font-medium text-white bg-violet-500 rounded-lg hover:bg-violet-600"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Back Navigation */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
      >
        <ChevronLeft size={16} />
        <span>Back to Clients</span>
      </button>

      {/* Client Header */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            {client.logo ? (
              <img 
                src={client.logo} 
                alt={client.name}
                className="w-16 h-16 rounded-xl object-cover"
              />
            ) : (
              <div className="w-16 h-16 bg-violet-50 rounded-xl flex items-center justify-center">
                <Users className="text-violet-600" size={32} />
              </div>
            )}
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{client.name}</h1>
              <div className="flex items-center gap-2 text-sm text-gray-500 mt-1">
                <Building2 size={14} />
                <span>{client.clientId || 'No Client ID'}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3">
            {client.status && (
              <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                client.status === 'active' 
                  ? 'bg-emerald-50 text-emerald-700' 
                  : 'bg-gray-50 text-gray-700'
              }`}>
                {client.status.charAt(0).toUpperCase() + client.status.slice(1)}
              </span>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Contact Information */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900">Contact Information</h2>
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <Mail size={16} className="text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-900">Email</p>
                  <a 
                    href={`mailto:${client.email}`}
                    className="text-sm text-violet-600 hover:underline"
                  >
                    {client.email}
                  </a>
                </div>
              </div>
              {client.billingEmail && (
                <div className="flex items-center gap-3">
                  <Mail size={16} className="text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">Billing Email</p>
                    <a 
                      href={`mailto:${client.billingEmail}`}
                      className="text-sm text-violet-600 hover:underline"
                    >
                      {client.billingEmail}
                    </a>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-3">
                <Phone size={16} className="text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-900">Phone</p>
                  <a 
                    href={`tel:${client.phone}`}
                    className="text-sm text-violet-600 hover:underline"
                  >
                    {client.phone}
                  </a>
                </div>
              </div>
              {client.website && (
                <div className="flex items-center gap-3">
                  <Globe size={16} className="text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">Website</p>
                    <a 
                      href={client.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-violet-600 hover:underline"
                    >
                      {new URL(client.website).hostname}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Address & Additional Info */}
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900">Additional Information</h2>
            <div className="space-y-3">
              {client.address && (
                <div className="flex items-start gap-3">
                  <MapPin size={16} className="text-gray-400 mt-1" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">Address</p>
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {client.address}
                    </p>
                  </div>
                </div>
              )}
              {client.notes && (
                <div className="flex items-start gap-3">
                  <FileText size={16} className="text-gray-400 mt-1" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">Notes</p>
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {client.notes}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Related Items */}
      <div className="space-y-6">
        {/* Jobs */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Briefcase className="text-blue-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Jobs</h2>
            </div>
            <button
              onClick={() => navigate(`/app/clients/${clientId}/jobs/new`)}
              className="text-sm text-blue-600 hover:text-blue-700"
            >
              View All Jobs
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {clientJobs.slice(0, 3).map(job => (
              <motion.div
                key={job.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/jobs/${job.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{job.name}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <p className="text-sm text-gray-500 line-clamp-2">{job.address}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Reports */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-teal-50 rounded-lg">
                <FileText className="text-teal-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Progress Reports</h2>
            </div>
            <button
              onClick={() => navigate(`/app/clients/${clientId}/reports`)}
              className="text-sm text-teal-600 hover:text-teal-700"
            >
              View All Reports
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {clientReports.slice(0, 3).map(report => (
              <motion.div
                key={report.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/reports/${report.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{report.reportId}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <p className="text-sm text-gray-500">{report.jobName}</p>
                <div className="mt-2 flex items-center gap-2">
                  <div className="flex-1 h-1.5 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-teal-500"
                      style={{ width: `${report.progressPercentage}%` }}
                    />
                  </div>
                  <span className="text-xs font-medium text-gray-500">
                    {report.progressPercentage}%
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Invoices */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <DollarSign className="text-emerald-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Invoices</h2>
            </div>
            <button
              onClick={() => navigate(`/app/clients/${clientId}/invoices`)}
              className="text-sm text-emerald-600 hover:text-emerald-700"
            >
              View All Invoices
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {clientInvoices.slice(0, 3).map(invoice => (
              <motion.div
                key={invoice.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/invoices/${invoice.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{invoice.invoiceNumber}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-500">
                    {new Date(invoice.date).toLocaleDateString()}
                  </span>
                  <span className="font-medium text-gray-900">
                    ${invoice.total.toLocaleString()}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Variations */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <GitBranch className="text-amber-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Variations</h2>
            </div>
            <button
              onClick={() => navigate(`/app/clients/${clientId}/variations`)}
              className="text-sm text-amber-600 hover:text-amber-700"
            >
              View All Variations
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {clientVariations.slice(0, 3).map(variation => (
              <motion.div
                key={variation.id}
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/app/variations/${variation.id}`)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{variation.name}</h3>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
                <p className="text-sm text-gray-500 line-clamp-2">{variation.description}</p>
                <div className="mt-2 flex items-center justify-between text-sm">
                  <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                    variation.status === 'approved'
                      ? 'bg-emerald-50 text-emerald-600'
                      : 'bg-amber-50 text-amber-600'
                  }`}>
                    {variation.status === 'approved' ? 'Approved' : 'Pending'}
                  </span>
                  <span className="font-medium text-gray-900">
                    ${variation.quotedAmount.toLocaleString()}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}