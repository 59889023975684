import React from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../../context/AuthContext';
import { usePermissions } from '../../hooks/usePermissions';
import AdminDashboard from './dashboards/AdminDashboard';
import CompanyDashboard from './dashboards/CompanyDashboard';
import StaffDashboard from './dashboards/StaffDashboard';
import ClientDashboard from './dashboards/ClientDashboard';
import LoadingState from '../ui/LoadingState';

export default function DashboardOverview() {
  const { user, isLoading } = useAuth();
  const { isSuperAdmin, isOrgAdmin, isClient } = usePermissions();

  if (isLoading) {
    return <LoadingState />;
  }

  // Render appropriate dashboard based on user role
  if (isSuperAdmin) {
    return <AdminDashboard />;
  }

  if (isOrgAdmin) {
    return <CompanyDashboard />;
  }

  if (isClient) {
    return <ClientDashboard />;
  }

  // Default to Staff dashboard for other roles
  return <StaffDashboard />;
}