import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Search, 
  Bell, 
  User, 
  Settings, 
  LogOut, 
  ChevronDown, 
  Hammer,
  FileText,
  Users,
  Briefcase,
  DollarSign,
  GitBranch,
  X
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { useData } from '../../context/DataContext';
import { useAppNavigation } from '../../routes/useNavigation';

interface SearchResult {
  id: string;
  type: 'client' | 'job' | 'report' | 'invoice' | 'variation';
  title: string;
  subtitle: string;
}

const MAX_DROPDOWN_RESULTS = 5;

export default function TopHeader() {
  const navigate = useAppNavigation();
  const { user, company, logout } = useAuth();
  const { addNotification } = useNotification();
  const { state } = useData();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate.goToLogin();
    } catch (error) {
      addNotification('error', 'Failed to log out');
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    const results: SearchResult[] = [];
    const searchTerm = query.toLowerCase();

    // Search clients
    state.clients.forEach(client => {
      if (
        client.name.toLowerCase().includes(searchTerm) ||
        client.email.toLowerCase().includes(searchTerm)
      ) {
        results.push({
          id: client.id,
          type: 'client',
          title: client.name,
          subtitle: client.email
        });
      }
    });

    // Search jobs
    state.jobs.forEach(job => {
      if (
        job.name.toLowerCase().includes(searchTerm) ||
        job.jobId?.toLowerCase().includes(searchTerm)
      ) {
        results.push({
          id: job.id,
          type: 'job',
          title: job.name,
          subtitle: job.jobId || 'No Job ID'
        });
      }
    });

    // Search reports
    state.reports.forEach(report => {
      if (
        report.reportId.toLowerCase().includes(searchTerm) ||
        report.jobName?.toLowerCase().includes(searchTerm)
      ) {
        results.push({
          id: report.id,
          type: 'report',
          title: report.reportId,
          subtitle: report.jobName || ''
        });
      }
    });

    // Search invoices
    state.invoices.forEach(invoice => {
      if (
        invoice.invoiceNumber.toLowerCase().includes(searchTerm) ||
        invoice.reference?.toLowerCase().includes(searchTerm)
      ) {
        results.push({
          id: invoice.id,
          type: 'invoice',
          title: invoice.invoiceNumber,
          subtitle: `$${invoice.total.toLocaleString()}`
        });
      }
    });

    // Search variations
    state.variations.forEach(variation => {
      if (
        variation.name.toLowerCase().includes(searchTerm) ||
        variation.description.toLowerCase().includes(searchTerm)
      ) {
        results.push({
          id: variation.id,
          type: 'variation',
          title: variation.name,
          subtitle: variation.variationId
        });
      }
    });

    setSearchResults(results.slice(0, MAX_DROPDOWN_RESULTS));
  };

  const handleSearchResultClick = (result: SearchResult) => {
    switch (result.type) {
      case 'client':
        navigate.goToClient(result.id);
        break;
      case 'job':
        navigate.goToJob(result.id);
        break;
      case 'report':
        navigate.goToReport(result.id);
        break;
      case 'invoice':
        navigate.goToInvoice(result.id);
        break;
      case 'variation':
        navigate.goToVariation(result.id);
        break;
    }
    setShowSearch(false);
    setSearchQuery('');
    setSearchResults([]);
  };

  const getResultIcon = (type: string) => {
    switch (type) {
      case 'client':
        return <Users size={16} className="text-violet-500" />;
      case 'job':
        return <Briefcase size={16} className="text-blue-500" />;
      case 'report':
        return <FileText size={16} className="text-teal-500" />;
      case 'invoice':
        return <DollarSign size={16} className="text-emerald-500" />;
      case 'variation':
        return <GitBranch size={16} className="text-amber-500" />;
      default:
        return null;
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 h-16 bg-white border-b border-gray-200 z-40">
      <div className="h-full px-4 flex items-center justify-between">
        {/* Logo & Brand */}
        <div className="flex items-center gap-3">
          <Link to="/app" className="flex items-center gap-2">
            <div className="w-8 h-8 bg-gray-900 rounded-lg flex items-center justify-center">
              <Hammer className="text-white" size={20} />
            </div>
            <span className="text-lg font-semibold text-gray-900">
              The Tradie App
            </span>
          </Link>
        </div>

        {/* Right Actions */}
        <div className="flex items-center gap-2">
          {/* Search */}
          <div className="relative">
            <AnimatePresence>
              {showSearch ? (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 400 }}
                  exit={{ opacity: 0, width: 0 }}
                  className="relative"
                >
                  <div className="relative">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search clients, jobs, reports..."
                      className="w-full pl-10 pr-10 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900"
                      autoFocus
                    />
                    <Search 
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" 
                      size={18} 
                    />
                    {searchQuery && (
                      <button
                        onClick={() => {
                          setSearchQuery('');
                          setSearchResults([]);
                        }}
                        className="absolute right-3 top-1/2 -translate-y-1/2 p-1 hover:bg-gray-200 rounded-full"
                      >
                        <X size={14} className="text-gray-500" />
                      </button>
                    )}
                  </div>
                  
                  {/* Search Results Dropdown */}
                  {searchResults.length > 0 && (
                    <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg border border-gray-100 overflow-hidden">
                      <div className="max-h-[400px] overflow-y-auto">
                        {searchResults.map((result) => (
                          <button
                            key={`${result.type}-${result.id}`}
                            onClick={() => handleSearchResultClick(result)}
                            className="w-full flex items-center gap-3 p-3 hover:bg-gray-50 transition-colors text-left"
                          >
                            {getResultIcon(result.type)}
                            <div>
                              <p className="font-medium text-gray-900">{result.title}</p>
                              <p className="text-sm text-gray-500">{result.subtitle}</p>
                            </div>
                          </button>
                        ))}

                        {/* Show all results link */}
                        {searchResults.length === MAX_DROPDOWN_RESULTS && (
                          <div className="p-3 bg-gray-50 border-t border-gray-100">
                            <button
                              onClick={() => {
                                setShowSearch(false);
                                navigate.goToSearch(searchQuery);
                              }}
                              className="w-full text-center text-sm font-medium text-blue-600 hover:text-blue-700"
                            >
                              Show all results
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </motion.div>
              ) : (
                <motion.button
                  onClick={() => setShowSearch(true)}
                  className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <Search size={20} />
                </motion.button>
              )}
            </AnimatePresence>
          </div>

          {/* Notifications */}
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="relative p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
          >
            <Bell size={20} />
            <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full" />
          </motion.button>

          {/* Profile Menu */}
          <div className="relative">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded-lg"
            >
              {user?.profileImage ? (
                <img 
                  src={user.profileImage} 
                  alt={user.name}
                  className="w-8 h-8 rounded-lg object-cover"
                />
              ) : (
                <div className="w-8 h-8 bg-gray-900 rounded-lg flex items-center justify-center">
                  <User size={16} className="text-white" />
                </div>
              )}
              <div className="hidden md:block text-left">
                <p className="text-sm font-medium text-gray-900">{user?.name}</p>
                <p className="text-xs text-gray-500">{company?.name}</p>
              </div>
              <ChevronDown size={16} className="hidden md:block text-gray-400" />
            </motion.button>

            <AnimatePresence>
              {showProfileMenu && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1 z-50"
                >
                  <button
                    onClick={() => {
                      navigate.goToProfile();
                      setShowProfileMenu(false);
                    }}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <User size={16} />
                    <span>My Profile</span>
                  </button>
                  <button
                    onClick={() => {
                      navigate.goToSettings();
                      setShowProfileMenu(false);
                    }}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <Settings size={16} />
                    <span>Settings</span>
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                  >
                    <LogOut size={16} />
                    <span>Sign Out</span>
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </header>
  );
}