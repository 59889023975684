// Generate a unique ID (temporary solution until we have a backend)
export function generateId(): string {
  return Math.random().toString(36).substr(2, 9);
}

// Format date to local string
export function formatDate(date: Date): string {
  return new Date(date).toLocaleDateString();
}

// Format currency
export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

// Calculate progress percentage
export function calculateProgress(current: number, total: number): number {
  return Math.round((current / total) * 100);
}

// Sort array by date
export function sortByDate<T extends { date: Date }>(array: T[], ascending = false): T[] {
  return [...array].sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return ascending ? dateA - dateB : dateB - dateA;
  });
}

// Group array by key
export function groupBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
  return array.reduce((groups, item) => {
    const groupKey = String(item[key]);
    return {
      ...groups,
      [groupKey]: [...(groups[groupKey] || []), item],
    };
  }, {} as { [key: string]: T[] });
}

// Generate a unique shareable link
export function generateShareableLink(reportId: string): string {
  const baseUrl = window.location.origin;
  return `${baseUrl}/shared/reports/${reportId}`;
}

// Format shareable link for display
export function formatShareableLink(url: string): string {
  return url.replace(/^https?:\/\//, '');
}