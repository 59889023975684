import { useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '../context/NotificationContext';
import { User } from '../types/auth';
import { mockDataService } from '../services/mockDataService';

export function useUsers() {
  const { company } = useAuth();
  const { addNotification } = useNotification();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedUsers = await mockDataService.get<User[]>('users');
      // Filter by company if needed
      const filteredUsers = company?.id 
        ? fetchedUsers.filter(user => user.companyId === company.id)
        : fetchedUsers;
      setUsers(filteredUsers);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to fetch users';
      setError(message);
      addNotification('error', message);
    } finally {
      setLoading(false);
    }
  }, [company?.id, addNotification]);

  const addUser = useCallback(async (userData: Omit<User, 'id' | 'createdAt' | 'updatedAt'>) => {
    setLoading(true);
    try {
      const newUser = await mockDataService.create<User>('users', userData);
      setUsers(prev => [...prev, newUser]);
      addNotification('success', 'User created successfully');
      return newUser;
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to create user';
      setError(message);
      addNotification('error', message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [addNotification]);

  const updateUser = useCallback(async (id: string, updates: Partial<User>) => {
    setLoading(true);
    try {
      const updatedUser = await mockDataService.update<User>('users', id, updates);
      setUsers(prev => prev.map(user => 
        user.id === id ? updatedUser : user
      ));
      addNotification('success', 'User updated successfully');
      return updatedUser;
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to update user';
      setError(message);
      addNotification('error', message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [addNotification]);

  const deleteUser = useCallback(async (id: string) => {
    setLoading(true);
    try {
      await mockDataService.delete('users', id);
      setUsers(prev => prev.filter(user => user.id !== id));
      addNotification('success', 'User deleted successfully');
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to delete user';
      setError(message);
      addNotification('error', message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [addNotification]);

  return {
    users,
    loading,
    error,
    fetchUsers,
    addUser,
    updateUser,
    deleteUser,
  };
}