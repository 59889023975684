import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Building2, Users, DollarSign, Share2, Download, Printer } from 'lucide-react';
import { useInvoices } from '../../hooks/useInvoices';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import { useNotification } from '../../context/NotificationContext';
import InvoiceDetails from './InvoiceDetails';
import InvoiceItems from './InvoiceItems';
import InvoiceTotals from './InvoiceTotals';
import InvoiceStatus from './InvoiceStatus';
import InvoiceActions from './InvoiceActions';
import PaymentHistory from './PaymentHistory';
import XeroSync from './XeroSync';

export default function InvoiceView() {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const navigate = useNavigate();
  const { invoices, updateInvoice, addPayment } = useInvoices();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { addNotification } = useNotification();

  const invoice = invoices.find(i => i.id === invoiceId);
  const company = invoice ? companies.find(c => c.id === invoice.companyId) : null;
  const client = invoice ? clients.find(c => c.id === invoice.clientId) : null;

  const handleStatusChange = async (newStatus: typeof invoice.status) => {
    if (!invoice) return;

    try {
      await updateInvoice({
        ...invoice,
        status: newStatus,
        updatedAt: new Date(),
      });
      addNotification('success', 'Invoice status updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update invoice status');
    }
  };

  const handlePayment = async (amount: number) => {
    if (!invoice) return;

    try {
      // Add payment
      await addPayment({
        invoiceId: invoice.id,
        date: new Date(),
        amount,
        method: 'bank',
      });

      // Update invoice
      const newAmountPaid = invoice.amountPaid + amount;
      const newBalance = invoice.total - newAmountPaid;
      
      await updateInvoice({
        ...invoice,
        amountPaid: newAmountPaid,
        balance: newBalance,
        status: newBalance <= 0 ? 'paid' : invoice.status,
        updatedAt: new Date(),
      });

      addNotification('success', 'Payment recorded successfully');
    } catch (error) {
      addNotification('error', 'Failed to record payment');
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    addNotification('success', 'Invoice link copied to clipboard');
  };

  const handleDownload = () => {
    // Implement PDF download
    addNotification('info', 'PDF download coming soon');
  };

  if (!invoice || !company || !client) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Invoice Not Found</h2>
          <p className="text-gray-500 mb-4">The requested invoice could not be found.</p>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <DollarSign className="text-blue-600" size={24} />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Invoice {invoice.invoiceNumber}
              </h1>
              <div className="flex items-center gap-4 text-sm text-gray-500 mt-1">
                <div className="flex items-center gap-2">
                  <Building2 size={14} />
                  <span>{company.name}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Users size={14} />
                  <span>{client.name}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            {/* Action Buttons */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleShare}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Share2 size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleDownload}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Download size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handlePrint}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Printer size={20} />
            </motion.button>
            <div className="w-px h-8 bg-gray-200 mx-2" />
            <InvoiceStatus
              status={invoice.status}
              onStatusChange={handleStatusChange}
            />
            <InvoiceActions
              invoice={invoice}
              onPayment={handlePayment}
            />
          </div>
        </div>

        {/* Progress Bar */}
        {invoice.amountPaid > 0 && (
          <div>
            <div className="flex items-center justify-between text-sm mb-2">
              <span className="text-gray-500">Payment Progress</span>
              <span className="text-gray-900 font-medium">
                {Math.round((invoice.amountPaid / invoice.total) * 100)}%
              </span>
            </div>
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${(invoice.amountPaid / invoice.total) * 100}%` }}
                transition={{ duration: 1, ease: "easeOut" }}
                className="h-full bg-green-500"
              />
            </div>
          </div>
        )}
      </div>

      {/* Content */}
      <div className="grid grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="col-span-2 space-y-6">
          <InvoiceDetails
            invoice={invoice}
            client={client}
            company={company}
          />
          <InvoiceItems
            invoice={invoice}
            jobs={jobs}
          />
          <InvoiceTotals
            invoice={invoice}
          />
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          <PaymentHistory
            invoice={invoice}
          />
          <XeroSync
            invoice={invoice}
          />
        </div>
      </div>
    </div>
  );
}