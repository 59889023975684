import React from 'react';
import { motion } from 'framer-motion';
import { Job } from '../../types';
import { Invoice } from '../../types/invoice';

interface InvoiceItemsProps {
  invoice: Invoice;
  jobs: Job[];
}

export default function InvoiceItems({ invoice, jobs }: InvoiceItemsProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-6">Line Items</h2>
      
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100">
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Description</th>
              <th className="text-center py-3 px-4 text-sm font-medium text-gray-500">Quantity</th>
              <th className="text-right py-3 px-4 text-sm font-medium text-gray-500">Unit Price</th>
              <th className="text-right py-3 px-4 text-sm font-medium text-gray-500">Tax Rate</th>
              <th className="text-right py-3 px-4 text-sm font-medium text-gray-500">Amount</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50">
            {invoice.items.map((item, index) => {
              const job = item.jobId ? jobs.find(j => j.id === item.jobId) : null;
              const amount = item.quantity * item.unitPrice;
              
              return (
                <motion.tr
                  key={item.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="group"
                >
                  <td className="py-3 px-4">
                    <p className="font-medium text-gray-900">{item.description}</p>
                    {job && (
                      <p className="text-sm text-gray-500 mt-1">
                        Job: {job.name}
                      </p>
                    )}
                  </td>
                  <td className="py-3 px-4 text-center text-gray-600">
                    {item.quantity}
                  </td>
                  <td className="py-3 px-4 text-right text-gray-600">
                    ${item.unitPrice.toLocaleString()}
                  </td>
                  <td className="py-3 px-4 text-right text-gray-600">
                    {(item.taxRate * 100).toFixed(0)}%
                  </td>
                  <td className="py-3 px-4 text-right font-medium text-gray-900">
                    ${amount.toLocaleString()}
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}