import React from 'react';
import { motion } from 'framer-motion';
import { RefreshCw, CheckCircle2, AlertCircle } from 'lucide-react';
import { Invoice } from '../../types/invoice';

interface XeroSyncProps {
  invoice: Invoice;
}

export default function XeroSync({ invoice }: XeroSyncProps) {
  const [syncing, setSyncing] = React.useState(false);

  const handleSync = async () => {
    setSyncing(true);
    // In a real app, implement Xero sync here
    await new Promise(resolve => setTimeout(resolve, 2000));
    setSyncing(false);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-6">Xero Integration</h2>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            {invoice.xeroId ? (
              <div className="p-2 bg-green-100 rounded-lg">
                <CheckCircle2 size={16} className="text-green-600" />
              </div>
            ) : (
              <div className="p-2 bg-yellow-100 rounded-lg">
                <AlertCircle size={16} className="text-yellow-600" />
              </div>
            )}
            <div>
              <p className="font-medium text-gray-900">
                {invoice.xeroId ? 'Synced with Xero' : 'Not synced'}
              </p>
              <p className="text-sm text-gray-500">
                {invoice.xeroId 
                  ? `Xero ID: ${invoice.xeroId}`
                  : 'Invoice has not been synced to Xero'
                }
              </p>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSync}
            disabled={syncing}
            className={`
              flex items-center gap-2 px-4 py-2.5 rounded-lg text-sm font-medium shadow-sm
              transition-all duration-200
              ${syncing
                ? 'bg-blue-100 text-blue-600 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
              }
            `}
          >
            <RefreshCw 
              size={18}
              className={syncing ? 'animate-spin' : ''} 
            />
            <span>{syncing ? 'Syncing...' : 'Sync Now'}</span>
          </motion.button>
        </div>
      </div>
    </div>
  );
}