import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowUpRight, Calendar, ChevronUp, ChevronDown,
  FileText, CheckCircle2, Clock, XCircle, AlertTriangle,
  DollarSign, Building2, Users, Link
} from 'lucide-react';
import { Invoice } from '../../types/invoice';
import { Client } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface InvoiceTableProps {
  invoices: Invoice[];
  clients: Client[];
  onSelect: (invoice: Invoice) => void;
  onEdit: (invoice: Invoice) => void;
  onDelete: (invoice: Invoice) => void;
}

type SortField = 'invoiceNumber' | 'date' | 'dueDate' | 'total' | 'balance' | 'status';
type SortDirection = 'asc' | 'desc';

export default function InvoiceTable({ invoices, clients, onSelect, onEdit, onDelete }: InvoiceTableProps) {
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedInvoices = [...invoices].sort((a, b) => {
    let comparison = 0;
    
    switch (sortField) {
      case 'invoiceNumber':
        comparison = a.invoiceNumber.localeCompare(b.invoiceNumber);
        break;
      case 'date':
        comparison = new Date(a.date).getTime() - new Date(b.date).getTime();
        break;
      case 'dueDate':
        comparison = new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        break;
      case 'total':
        comparison = a.total - b.total;
        break;
      case 'balance':
        comparison = a.balance - b.balance;
        break;
      case 'status':
        comparison = a.status.localeCompare(b.status);
        break;
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <ChevronUp className="opacity-0 group-hover:opacity-50" size={14} />;
    return sortDirection === 'asc' ? 
      <ChevronUp className="text-blue-500" size={14} /> : 
      <ChevronDown className="text-blue-500" size={14} />;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'paid':
        return 'bg-emerald-50 text-emerald-700 border-emerald-200';
      case 'pending':
        return 'bg-amber-50 text-amber-700 border-amber-200';
      case 'overdue':
        return 'bg-red-50 text-red-700 border-red-200';
      case 'void':
        return 'bg-gray-50 text-gray-700 border-gray-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'paid':
        return <CheckCircle2 size={14} className="text-emerald-500" />;
      case 'pending':
        return <Clock size={14} className="text-amber-500" />;
      case 'overdue':
        return <AlertTriangle size={14} className="text-red-500" />;
      case 'void':
        return <XCircle size={14} className="text-gray-500" />;
      default:
        return <Clock size={14} className="text-gray-500" />;
    }
  };

  const formatDate = (date: Date | string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-100">
              <th 
                className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('invoiceNumber')}
              >
                <div className="flex items-center gap-2">
                  <FileText size={16} className="text-gray-400" />
                  <span>Invoice</span>
                  <SortIcon field="invoiceNumber" />
                </div>
              </th>
              <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">
                <div className="flex items-center gap-2">
                  <Users size={16} className="text-gray-400" />
                  <span>Client</span>
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('date')}
              >
                <div className="flex items-center justify-center gap-2">
                  <Calendar size={16} className="text-gray-400" />
                  <span>Date</span>
                  <SortIcon field="date" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('dueDate')}
              >
                <div className="flex items-center justify-center gap-2">
                  <Calendar size={16} className="text-gray-400" />
                  <span>Due Date</span>
                  <SortIcon field="dueDate" />
                </div>
              </th>
              <th 
                className="text-right py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('total')}
              >
                <div className="flex items-center justify-end gap-2">
                  <DollarSign size={16} className="text-gray-400" />
                  <span>Total</span>
                  <SortIcon field="total" />
                </div>
              </th>
              <th 
                className="text-right py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('balance')}
              >
                <div className="flex items-center justify-end gap-2">
                  <DollarSign size={16} className="text-gray-400" />
                  <span>Balance</span>
                  <SortIcon field="balance" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('status')}
              >
                <div className="flex items-center justify-center gap-2">
                  <CheckCircle2 size={16} className="text-gray-400" />
                  <span>Status</span>
                  <SortIcon field="status" />
                </div>
              </th>
              <th className="text-right py-4 px-6 text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50">
            {sortedInvoices.map((invoice) => {
              const client = clients.find(c => c.id === invoice.clientId);
              const isHovered = hoveredRow === invoice.id;
              const isOverdue = new Date(invoice.dueDate) < new Date() && invoice.status !== 'paid';
              
              return (
                <motion.tr
                  key={invoice.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`group hover:bg-gray-50 cursor-pointer transition-colors ${
                    isOverdue ? 'bg-red-50/30' : ''
                  }`}
                  onClick={() => onSelect(invoice)}
                  onMouseEnter={() => setHoveredRow(invoice.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      <div className={`p-2 rounded-lg transition-colors ${
                        isHovered ? 'bg-blue-100' : 'bg-blue-50'
                      }`}>
                        <FileText size={16} className="text-blue-600" />
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">{invoice.invoiceNumber}</p>
                        {invoice.reference && (
                          <p className="text-xs text-gray-500">Ref: {invoice.reference}</p>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      {client?.logo ? (
                        <img 
                          src={client.logo} 
                          alt={client.name}
                          className="w-8 h-8 rounded-lg object-cover"
                        />
                      ) : (
                        <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center">
                          <Building2 size={16} className="text-gray-400" />
                        </div>
                      )}
                      <span className="font-medium text-gray-900">{client?.name}</span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-center text-sm text-gray-500">
                      {formatDate(invoice.date)}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-center text-sm text-gray-500">
                      {formatDate(invoice.dueDate)}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-right">
                      <span className="text-sm font-medium text-gray-900">
                        ${invoice.total.toLocaleString()}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-right">
                      <span className={`text-sm font-medium ${
                        invoice.balance > 0 ? 'text-amber-600' : 'text-emerald-600'
                      }`}>
                        ${invoice.balance.toLocaleString()}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-center">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium border ${
                        getStatusColor(invoice.status)
                      }`}>
                        {getStatusIcon(invoice.status)}
                        {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex items-center justify-end gap-2">
                      <ActionMenu
                        onEdit={() => onEdit(invoice)}
                        onDelete={() => onDelete(invoice)}
                      />
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelect(invoice);
                        }}
                        className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                      >
                        <ArrowUpRight size={16} />
                      </button>
                    </div>
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}