import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  FileText, 
  Users, 
  DollarSign, 
  TrendingUp,
  CheckCircle2,
  ArrowRight,
  Star,
  Shield,
  Clock,
  Tool,
  Hammer,
  Building2
} from 'lucide-react';
import PublicLayout from '../../layouts/PublicLayout';

export default function LandingPage() {
  const features = [
    {
      icon: FileText,
      title: 'Progress Reports',
      description: 'Create beautiful, detailed progress reports that keep your clients in the loop and showcase your professionalism.'
    },
    {
      icon: Users,
      title: 'Client Portal', 
      description: 'Give your clients secure access to their project updates, photos, and documentation in one place.'
    },
    {
      icon: DollarSign,
      title: 'Financial Tracking',
      description: 'Stay on top of your project finances with easy-to-use tools for invoicing, variations, and payment tracking.'
    },
    {
      icon: TrendingUp,
      title: 'Project Management',
      description: 'Keep your projects on track with milestone tracking, task management, and team collaboration tools.'
    }
  ];

  const testimonials = [
    {
      quote: "The Tradie App has transformed how we manage our construction projects. The progress reports are a game-changer.",
      author: "Mike Johnson",
      role: "Construction Manager",
      company: "Johnson Builders",
      image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&fit=crop"
    },
    {
      quote: "Our clients love the transparency and real-time updates. It's made our communication so much more efficient.",
      author: "Sarah Thompson",
      role: "Project Director",
      company: "Elite Construction",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&fit=crop"
    },
    {
      quote: "The financial tracking features have saved us countless hours in admin work. Highly recommended!",
      author: "David Wilson",
      role: "Operations Manager",
      company: "Pacific Developments",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&fit=crop"
    }
  ];

  const stats = [
    { value: '500+', label: 'Active Projects' },
    { value: '10k+', label: 'Progress Reports' },
    { value: '98%', label: 'Client Satisfaction' },
    { value: '24/7', label: 'Support' }
  ];

  return (
    <PublicLayout>
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-gray-900 py-20 sm:py-32">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1541888946425-d81bb19240f5?auto=format&fit=crop&w=2000&q=80"
            alt="Construction site"
            className="h-full w-full object-cover opacity-20"
          />
          <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-900/95 to-gray-900/90" />
        </div>
        
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1 
              className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Construction Progress
              <span className="block text-teal-500">Made Simple</span>
            </motion.h1>
            <motion.p 
              className="mx-auto mt-6 max-w-2xl text-lg text-gray-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              The all-in-one platform for managing construction projects, creating professional progress reports, and keeping your clients informed.
            </motion.p>
            
            <motion.div 
              className="mt-10 flex items-center justify-center gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Link
                to="/signup"
                className="rounded-lg bg-teal-500 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-400"
              >
                Start Free Trial
              </Link>
              <Link
                to="/features"
                className="rounded-lg bg-gray-800 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
              >
                Learn More
              </Link>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 sm:py-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Everything you need to manage your projects
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Streamline your construction project management with our comprehensive suite of tools.
            </p>
          </div>

          <div className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="relative bg-white p-6 rounded-2xl shadow-sm border border-gray-100"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center justify-center w-12 h-12 bg-teal-50 text-teal-600 rounded-xl mb-4">
                  <feature.icon size={24} />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                <p className="mt-2 text-gray-500">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-gray-900 py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <p className="text-4xl font-bold text-white">{stat.value}</p>
                <p className="mt-2 text-sm text-gray-400">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-20 sm:py-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Trusted by Construction Professionals
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              See what our customers have to say about The Tradie App
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={testimonial.author}
                className="bg-white p-8 rounded-2xl shadow-sm border border-gray-100"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="font-semibold text-gray-900">{testimonial.author}</h3>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                <div className="mt-4 flex items-center gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      size={16}
                      className="text-yellow-400 fill-current"
                    />
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative overflow-hidden bg-teal-600 py-32">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1565008447742-97f6f38c985c?auto=format&fit=crop&w=2000&q=80"
            alt="Construction site"
            className="h-full w-full object-cover opacity-10"
          />
          <div className="absolute inset-0 bg-gradient-to-br from-teal-600 to-teal-700" />
        </div>
        
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to streamline your construction projects?
            </h2>
            <p className="mt-4 text-lg text-teal-100">
              Start your free trial today. No credit card required.
            </p>
            <Link
              to="/signup"
              className="mt-8 inline-flex items-center gap-2 rounded-lg bg-white px-6 py-3 text-base font-semibold text-teal-600 shadow-sm hover:bg-teal-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Get Started
              <ArrowRight size={16} />
            </Link>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
}