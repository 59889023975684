import React from 'react';
import { motion } from 'framer-motion';
import { 
  Building2, Users, FileText, Settings,
  Activity, Server, Shield, Database,
  Plus, ArrowUpRight, TrendingUp, TrendingDown,
  AlertTriangle, CheckCircle2, Globe,
  Clock, DollarSign
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useCompanies } from '../../../hooks/useCompanies';
import { useClients } from '../../../hooks/useClients';
import { useReports } from '../../../hooks/useReports';
import { useInvoices } from '../../../hooks/useInvoices';
import MetricCard from '../metrics/MetricCard';

export default function AdminDashboard() {
  const navigate = useNavigate();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { reports } = useReports();
  const { invoices } = useInvoices();

  // Calculate system-wide metrics
  const activeCompanies = companies.filter(c => c.status === 'active');
  const totalRevenue = invoices.reduce((sum, inv) => sum + inv.total, 0);
  const monthlyGrowth = 12.5; // Mock value - implement actual calculation
  const totalUsers = clients.length * 2; // Mock value - implement actual user count

  const metrics = [
    {
      label: 'Active Companies',
      value: activeCompanies.length,
      icon: Building2,
      trend: `+${monthlyGrowth}%`,
      color: 'blue'
    },
    {
      label: 'Total Users',
      value: totalUsers,
      icon: Users,
      trend: '+8%',
      color: 'violet'
    },
    {
      label: 'System Revenue',
      value: totalRevenue,
      icon: DollarSign,
      trend: '+15%',
      color: 'emerald'
    },
    {
      label: 'Active Reports',
      value: reports.length,
      icon: FileText,
      trend: '+24%',
      color: 'amber'
    }
  ];

  const systemHealth = [
    { 
      name: 'API Status',
      status: 'healthy',
      uptime: '99.9%',
      icon: Server,
      lastIncident: '30 days ago'
    },
    { 
      name: 'Database',
      status: 'healthy',
      uptime: '99.99%',
      icon: Database,
      lastIncident: '45 days ago'
    },
    { 
      name: 'Auth Service',
      status: 'healthy',
      uptime: '100%',
      icon: Shield,
      lastIncident: 'No incidents'
    },
    { 
      name: 'Storage',
      status: 'warning',
      uptime: '98.5%',
      icon: Settings,
      lastIncident: '2 hours ago'
    }
  ];

  const recentCompanies = companies
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .slice(0, 5)
    .map(company => ({
      ...company,
      users: Math.floor(Math.random() * 50) + 10, // Mock value
      reports: reports.filter(r => r.companyId === company.id).length
    }));

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">System Overview</h1>
          <p className="text-gray-500">Monitor and manage all companies and system health.</p>
        </div>
        <button
          onClick={() => navigate('/companies/new')}
          className="flex items-center gap-2 px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
        >
          <Plus size={20} />
          <span>Add Company</span>
        </button>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>

      {/* System Health & Recent Companies */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* System Health */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <Activity className="text-emerald-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">System Health</h2>
            </div>
            <span className="text-sm text-gray-500">Last checked 2 minutes ago</span>
          </div>

          <div className="grid grid-cols-2 gap-4">
            {systemHealth.map((service, index) => (
              <motion.div
                key={service.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors cursor-pointer"
                whileHover={{ y: -2 }}
              >
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-2">
                    <service.icon size={16} className="text-gray-400" />
                    <span className="font-medium text-gray-900">{service.name}</span>
                  </div>
                  <span className={`text-xs font-medium px-2 py-1 rounded-full ${
                    service.status === 'healthy' 
                      ? 'bg-emerald-50 text-emerald-600'
                      : 'bg-amber-50 text-amber-600'
                  }`}>
                    {service.uptime}
                  </span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-500">Last incident:</span>
                  <span className="text-gray-900">{service.lastIncident}</span>
                </div>
                <div className="mt-2 h-1 bg-gray-200 rounded-full overflow-hidden">
                  <motion.div
                    className={`h-full ${
                      service.status === 'healthy' ? 'bg-emerald-500' : 'bg-amber-500'
                    }`}
                    initial={{ width: 0 }}
                    animate={{ width: service.uptime.replace('%', '') + '%' }}
                    transition={{ duration: 1, ease: "easeOut" }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Recent Companies */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Building2 className="text-blue-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Recent Companies</h2>
            </div>
            <button 
              onClick={() => navigate('/companies')}
              className="text-sm text-blue-600 hover:text-blue-700"
            >
              View All
            </button>
          </div>

          <div className="space-y-4">
            {recentCompanies.map((company) => (
              <motion.div
                key={company.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 cursor-pointer group"
                onClick={() => navigate(`/companies/${company.id}`)}
                whileHover={{ x: 4 }}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-3">
                    {company.logo ? (
                      <img 
                        src={company.logo} 
                        alt={company.name}
                        className="w-8 h-8 rounded-lg object-cover"
                      />
                    ) : (
                      <div className="w-8 h-8 bg-gray-200 rounded-lg flex items-center justify-center">
                        <Building2 size={16} className="text-gray-400" />
                      </div>
                    )}
                    <div>
                      <h3 className="font-medium text-gray-900">{company.name}</h3>
                      <p className="text-xs text-gray-500">
                        Created {new Date(company.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    company.status === 'active'
                      ? 'bg-emerald-50 text-emerald-600'
                      : 'bg-gray-100 text-gray-600'
                  }`}>
                    {company.status}
                  </span>
                </div>
                <div className="flex items-center justify-between mt-3 text-sm">
                  <div className="flex items-center gap-4">
                    <span className="text-gray-500">
                      <Users className="inline-block w-4 h-4 mr-1" />
                      {company.users} Users
                    </span>
                    <span className="text-gray-500">
                      <FileText className="inline-block w-4 h-4 mr-1" />
                      {company.reports} Reports
                    </span>
                  </div>
                  <ArrowUpRight 
                    size={16} 
                    className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* System Insights */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Usage Trends */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-violet-50 rounded-lg">
                <TrendingUp className="text-violet-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Usage Trends</h2>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <p className="text-sm text-gray-500">Active Users</p>
                <p className="text-lg font-semibold text-gray-900">1,234</p>
              </div>
              <TrendingUp className="text-emerald-500" size={20} />
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <p className="text-sm text-gray-500">Reports Generated</p>
                <p className="text-lg font-semibold text-gray-900">5,678</p>
              </div>
              <TrendingUp className="text-emerald-500" size={20} />
            </div>
          </div>
        </div>

        {/* System Alerts */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <AlertTriangle className="text-amber-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">System Alerts</h2>
            </div>
          </div>

          <div className="space-y-4">
            <div className="p-4 bg-amber-50 rounded-lg border border-amber-100">
              <div className="flex items-center gap-2 text-amber-600 mb-2">
                <AlertTriangle size={16} />
                <p className="font-medium">Storage Usage High</p>
              </div>
              <p className="text-sm text-amber-600">
                Storage capacity at 85%. Consider cleanup or expansion.
              </p>
            </div>
          </div>
        </div>

        {/* Global Activity */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <Globe className="text-emerald-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Global Activity</h2>
            </div>
          </div>

          <div className="space-y-4">
            {[...Array(3)].map((_, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center gap-3 p-4 bg-gray-50 rounded-lg"
              >
                <div className="p-2 bg-white rounded-full">
                  <Clock size={16} className="text-gray-400" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">New company registered</p>
                  <p className="text-xs text-gray-500">2 minutes ago</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}