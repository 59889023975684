import { useCallback } from 'react';
import { useData } from '../context/DataContext';
import { useNotification } from '../context/NotificationContext';
import { Company } from '../types';
import { generateId } from '../utils/helpers';

export function useCompanies() {
  const { state, dispatch } = useData();
  const { addNotification } = useNotification();

  const fetchCompanies = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'SET_COMPANIES', payload: state.companies });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch companies' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch, state.companies]);

  const addCompany = useCallback(async (company: Partial<Company>) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const newCompany: Company = {
        ...company,
        id: generateId(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Company;
      dispatch({ type: 'ADD_COMPANY', payload: newCompany });
      addNotification('success', 'Company created successfully');
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add company' });
      addNotification('error', 'Failed to create company');
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch, addNotification]);

  const updateCompany = useCallback(async (company: Company) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedCompany = {
        ...company,
        updatedAt: new Date(),
      };
      dispatch({ type: 'UPDATE_COMPANY', payload: updatedCompany });
      addNotification('success', 'Company updated successfully');
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update company' });
      addNotification('error', 'Failed to update company');
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch, addNotification]);

  const deleteCompany = useCallback(async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'DELETE_COMPANY', payload: id });
      addNotification('success', 'Company deleted successfully');
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete company' });
      addNotification('error', 'Failed to delete company');
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch, addNotification]);

  return {
    companies: state.companies,
    loading: state.loading,
    error: state.error,
    fetchCompanies,
    addCompany,
    updateCompany,
    deleteCompany,
  };
}