import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, Users, Clock, MessageSquare } from 'lucide-react';

interface ProjectStatusProps {
  keyUpdates: string;
  clientCommunication: string;
}

export default function ProjectStatus({
  keyUpdates,
  clientCommunication,
}: ProjectStatusProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-amber-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Project Status</h2>
              <p className="text-sm text-gray-500 mt-1">Current project status and communication updates</p>
            </div>
          </div>
          <div className="p-2 bg-amber-50 rounded-xl">
            <AlertCircle className="text-amber-500" size={24} />
          </div>
        </div>

        {/* Content */}
        <div className="grid md:grid-cols-2 gap-12">
          {/* Key Updates */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-amber-50 rounded-lg">
                <Clock className="text-amber-500" size={20} />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Key Updates</h3>
                <p className="text-sm text-amber-500">Latest Project Status</p>
              </div>
            </div>

            <div className="bg-amber-50/30 rounded-xl p-6">
              {keyUpdates.split('\n').map((update, index) => (
                <div 
                  key={index} 
                  className={`py-3 ${index !== 0 ? 'border-t border-amber-100' : ''}`}
                >
                  <p className="text-gray-600 leading-relaxed">{update}</p>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Client Communication */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-blue-50 rounded-lg">
                <MessageSquare className="text-blue-500" size={20} />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Client Communication</h3>
                <p className="text-sm text-blue-500">Latest Interactions</p>
              </div>
            </div>

            <div className="bg-blue-50/30 rounded-xl p-6">
              {clientCommunication.split('\n').map((comm, index) => (
                <div 
                  key={index} 
                  className={`py-3 ${index !== 0 ? 'border-t border-blue-100' : ''}`}
                >
                  <p className="text-gray-600 leading-relaxed">{comm}</p>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}