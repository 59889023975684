import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Building2, Mail, Phone, Globe, MapPin, Loader2, CheckCircle2 } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { usePermissions } from '../../hooks/usePermissions';
import FormField from '../ui/FormField';

export default function CompanySettings() {
  const { company, updateCompany } = useAuth();
  const { addNotification } = useNotification();
  const { canEditCompany } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: company?.name || '',
    email: company?.email || '',
    phone: company?.phone || '',
    website: company?.website || '',
    address: company?.address || '',
    billingEmail: company?.billingEmail || '',
    contactName: company?.contactName || '',
    contactPhone: company?.contactPhone || '',
    contactEmail: company?.contactEmail || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!canEditCompany) {
      addNotification('error', 'You do not have permission to edit company settings');
      return;
    }

    setIsLoading(true);
    try {
      await updateCompany(formData);
      addNotification('success', 'Company settings updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update company settings');
    } finally {
      setIsLoading(false);
    }
  };

  if (!canEditCompany) {
    return (
      <div className="text-center py-12">
        <Building2 className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Access</h3>
        <p className="mt-1 text-sm text-gray-500">
          You don't have permission to edit company settings.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Company Information */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Building2 className="text-blue-600" size={20} />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Company Information</h2>
            <p className="text-sm text-gray-500">Update your company details</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField
            label="Company Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            disabled={!canEditCompany}
          />

          <FormField
            label="Website"
            name="website"
            type="url"
            value={formData.website}
            onChange={handleChange}
            icon={<Globe size={16} className="text-gray-400" />}
            disabled={!canEditCompany}
          />

          <FormField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            icon={<Mail size={16} className="text-gray-400" />}
            disabled={!canEditCompany}
          />

          <FormField
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            required
            icon={<Phone size={16} className="text-gray-400" />}
            disabled={!canEditCompany}
          />

          <div className="md:col-span-2">
            <FormField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              multiline
              rows={3}
              icon={<MapPin size={16} className="text-gray-400" />}
              disabled={!canEditCompany}
            />
          </div>
        </div>
      </div>

      {/* Billing Information */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-emerald-50 rounded-lg">
            <Mail className="text-emerald-600" size={20} />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Billing Information</h2>
            <p className="text-sm text-gray-500">Manage your billing details</p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6">
          <FormField
            label="Billing Email"
            name="billingEmail"
            type="email"
            value={formData.billingEmail}
            onChange={handleChange}
            icon={<Mail size={16} className="text-gray-400" />}
            disabled={!canEditCompany}
          />
        </div>
      </div>

      {/* Primary Contact */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-violet-50 rounded-lg">
            <Building2 className="text-violet-600" size={20} />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Primary Contact</h2>
            <p className="text-sm text-gray-500">Main point of contact for your company</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <FormField
            label="Contact Name"
            name="contactName"
            value={formData.contactName}
            onChange={handleChange}
            disabled={!canEditCompany}
          />

          <FormField
            label="Contact Phone"
            name="contactPhone"
            type="tel"
            value={formData.contactPhone}
            onChange={handleChange}
            icon={<Phone size={16} className="text-gray-400" />}
            disabled={!canEditCompany}
          />

          <FormField
            label="Contact Email"
            name="contactEmail"
            type="email"
            value={formData.contactEmail}
            onChange={handleChange}
            icon={<Mail size={16} className="text-gray-400" />}
            disabled={!canEditCompany}
          />
        </div>
      </div>

      {/* Save Button */}
      {canEditCompany && (
        <div className="flex justify-end">
          <motion.button
            type="submit"
            disabled={isLoading}
            className="flex items-center gap-2 px-6 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin" size={16} />
                <span>Saving...</span>
              </>
            ) : (
              <>
                <CheckCircle2 size={16} />
                <span>Save Changes</span>
              </>
            )}
          </motion.button>
        </div>
      )}
    </form>
  );
}