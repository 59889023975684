import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, CheckCircle2, ClipboardCheck, Shield, HardHat, FileCheck } from 'lucide-react';

interface ComplianceProps {
  accidents: string;
  councilInspections: string;
}

export default function ComplianceSection({ accidents, councilInspections }: ComplianceProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-violet-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Compliance Overview</h2>
              <p className="text-sm text-gray-500 mt-1">Health & safety and council inspection updates</p>
            </div>
          </div>
          <div className="p-2 bg-violet-50 rounded-xl">
            <ClipboardCheck className="text-violet-600" size={24} />
          </div>
        </div>
        
        <div className="grid md:grid-cols-2 gap-6">
          {/* Health & Safety Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="relative overflow-hidden"
          >
            <div className="bg-gradient-to-br from-amber-50 to-orange-50 rounded-xl p-6 border border-amber-100 h-full">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-amber-100 rounded-lg">
                  <HardHat className="text-amber-600" size={20} />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900">Health & Safety</h3>
                  <p className="text-sm text-amber-600">Incidents & Observations</p>
                </div>
              </div>

              <div className="space-y-4">
                {accidents.split('\n').map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`flex items-start gap-3 py-3 ${
                      index !== 0 ? 'border-t border-amber-200' : ''
                    }`}
                  >
                    <div className="p-1 bg-white rounded-full mt-1">
                      {item.toLowerCase().includes('no accidents') || item.toLowerCase().includes('no incidents') ? (
                        <CheckCircle2 size={16} className="text-emerald-500" />
                      ) : (
                        <AlertTriangle size={16} className="text-amber-500" />
                      )}
                    </div>
                    <p className="text-gray-700 leading-relaxed">{item}</p>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Decorative Elements */}
            <div className="absolute top-0 right-0 w-32 h-32 opacity-5">
              <Shield className="w-full h-full" />
            </div>
          </motion.div>

          {/* Council Inspections Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="relative overflow-hidden"
          >
            <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 border border-blue-100 h-full">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-blue-100 rounded-lg">
                  <FileCheck className="text-blue-600" size={20} />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900">Council Inspections</h3>
                  <p className="text-sm text-blue-600">Compliance Updates</p>
                </div>
              </div>

              <div className="space-y-4">
                {councilInspections.split('\n').map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`flex items-start gap-3 py-3 ${
                      index !== 0 ? 'border-t border-blue-200' : ''
                    }`}
                  >
                    <div className="p-1 bg-white rounded-full mt-1">
                      {item.toLowerCase().includes('passed') || item.toLowerCase().includes('approved') ? (
                        <CheckCircle2 size={16} className="text-emerald-500" />
                      ) : (
                        <ClipboardCheck size={16} className="text-blue-500" />
                      )}
                    </div>
                    <p className="text-gray-700 leading-relaxed">{item}</p>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Decorative Elements */}
            <div className="absolute top-0 right-0 w-32 h-32 opacity-5">
              <ClipboardCheck className="w-full h-full" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}