import { useCallback } from 'react';
import { useData } from '../context/DataContext';
import { Report } from '../types';
import { generateId } from '../utils/helpers';

export function useReports() {
  const { state, dispatch } = useData();

  const fetchReports = useCallback(async (jobId?: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const reports = jobId
        ? state.reports.filter(report => report.jobId === jobId)
        : state.reports;
      dispatch({ type: 'SET_REPORTS', payload: reports });
      return reports;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch reports' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch, state.reports]);

  const addReport = useCallback(async (report: Partial<Report>): Promise<Report> => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const newReport: Report = {
        ...report,
        id: generateId(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Report;
      dispatch({ type: 'ADD_REPORT', payload: newReport });
      return newReport;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add report' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const updateReport = useCallback(async (report: Report): Promise<Report> => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedReport = {
        ...report,
        updatedAt: new Date(),
      };
      dispatch({ type: 'UPDATE_REPORT', payload: updatedReport });
      return updatedReport;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update report' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const deleteReport = useCallback(async (id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'DELETE_REPORT', payload: id });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete report' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const getReportsByJob = useCallback((jobId: string) => {
    return state.reports.filter(report => report.jobId === jobId);
  }, [state.reports]);

  const getReportsByClient = useCallback((clientId: string) => {
    return state.reports.filter(report => report.clientId === clientId);
  }, [state.reports]);

  const getReportsByCompany = useCallback((companyId: string) => {
    return state.reports.filter(report => report.companyId === companyId);
  }, [state.reports]);

  return {
    reports: state.reports,
    loading: state.loading,
    error: state.error,
    fetchReports,
    addReport,
    updateReport,
    deleteReport,
    getReportsByJob,
    getReportsByClient,
    getReportsByCompany,
  };
}