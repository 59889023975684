import React from 'react';
import { Building2, Mail, Phone, MapPin } from 'lucide-react';
import { Invoice } from '../../types/invoice';
import { Client } from '../../types';

interface InvoiceDetailsProps {
  invoice: Invoice;
  client: Client;
}

export default function InvoiceDetails({ invoice, client }: InvoiceDetailsProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-6">Invoice Details</h2>
      
      <div className="grid md:grid-cols-2 gap-8">
        {/* Client Information */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-4">Bill To</h3>
          <div className="space-y-3">
            <div className="flex items-start gap-3">
              <Building2 size={16} className="text-gray-400 mt-1 flex-shrink-0" />
              <div>
                <p className="font-medium text-gray-900">{client.name}</p>
                {client.contactName && (
                  <p className="text-sm text-gray-500">Attn: {client.contactName}</p>
                )}
              </div>
            </div>
            
            {client.address && (
              <div className="flex items-start gap-3">
                <MapPin size={16} className="text-gray-400 mt-1 flex-shrink-0" />
                <p className="text-sm text-gray-600 whitespace-pre-line">
                  {client.address}
                </p>
              </div>
            )}
            
            <div className="flex items-center gap-3">
              <Mail size={16} className="text-gray-400 flex-shrink-0" />
              <a 
                href={`mailto:${client.billingEmail || client.email}`}
                className="text-sm text-blue-600 hover:underline"
              >
                {client.billingEmail || client.email}
              </a>
            </div>
            
            {client.phone && (
              <div className="flex items-center gap-3">
                <Phone size={16} className="text-gray-400 flex-shrink-0" />
                <a 
                  href={`tel:${client.phone}`}
                  className="text-sm text-blue-600 hover:underline"
                >
                  {client.phone}
                </a>
              </div>
            )}
          </div>
        </div>

        {/* Invoice Information */}
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Invoice Number</p>
              <p className="font-medium text-gray-900">{invoice.invoiceNumber}</p>
            </div>
            {invoice.reference && (
              <div>
                <p className="text-sm text-gray-500">Reference</p>
                <p className="font-medium text-gray-900">{invoice.reference}</p>
              </div>
            )}
            <div>
              <p className="text-sm text-gray-500">Invoice Date</p>
              <p className="font-medium text-gray-900">
                {new Date(invoice.date).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Due Date</p>
              <p className="font-medium text-gray-900">
                {new Date(invoice.dueDate).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Payment Terms</p>
              <p className="font-medium text-gray-900">{invoice.paymentTerms}</p>
            </div>
          </div>

          {invoice.notes && (
            <div className="pt-4 border-t border-gray-100">
              <p className="text-sm text-gray-500 mb-2">Notes</p>
              <p className="text-sm text-gray-600 whitespace-pre-line">
                {invoice.notes}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}