import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  User,
  Mail, 
  Phone, 
  Lock,
  Upload,
  X,
  Camera,
  Loader2,
  CheckCircle2,
  Shield,
  Building2,
  Clock,
  Briefcase,
  Settings,
  Bell,
  Key,
  UserCheck
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import FormField from '../../components/ui/FormField';
import FormError from '../../components/ui/FormError';
import { useFormValidation } from '../../hooks/useFormValidation';

interface ProfileFormData {
  name: string;
  email: string;
  jobTitle: string;
  phone: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default function ProfilePage() {
  const { user, company, updateProfile } = useAuth();
  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [showPasswordSection, setShowPasswordSection] = useState(false);

  const {
    formData,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
    setFormData,
  } = useFormValidation<ProfileFormData>({
    name: user?.name || '',
    email: user?.email || '',
    jobTitle: user?.jobTitle || '',
    phone: user?.phone || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }, {
    name: [
      { required: true, message: 'Name is required' },
      { minLength: 2, message: 'Name must be at least 2 characters' },
    ],
    email: [
      { required: true, message: 'Email is required' },
      { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email format' },
    ],
    jobTitle: [
      { required: true, message: 'Job title is required' },
      { minLength: 2, message: 'Job title must be at least 2 characters' },
    ],
    phone: [
      { pattern: /^[0-9+\-\s()]*$/, message: 'Invalid phone number format' },
    ],
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      let imageUrl = user?.profileImage;
      if (profileImage) {
        // Handle profile image upload
        imageUrl = await handleImageUpload(profileImage);
      }

      await updateProfile({
        ...formData,
        profileImage: imageUrl,
      });

      addNotification('success', 'Profile updated successfully');
      if (showPasswordSection) {
        setShowPasswordSection(false);
        setFormData(prev => ({
          ...prev,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }));
      }
    } catch (error) {
      addNotification('error', 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    // Mock image upload - replace with actual implementation
    await new Promise(resolve => setTimeout(resolve, 1000));
    return URL.createObjectURL(file);
  };

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const getAccountAge = () => {
    if (!user?.createdAt) return 'N/A';
    const months = Math.floor(
      (new Date().getTime() - new Date(user.createdAt).getTime()) / 
      (1000 * 60 * 60 * 24 * 30)
    );
    return months < 1 ? 'Less than a month' : `${months} months`;
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <div className="flex items-center gap-3">
        <div className="p-2 bg-violet-50 rounded-lg">
          <User className="text-violet-600" size={20} />
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">My Profile</h1>
          <p className="text-gray-500">Manage your account settings and preferences</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Profile Image */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-6">Profile Image</h2>
          <div className="flex items-center gap-6">
            <div className="relative">
              {profileImage || user?.profileImage ? (
                <img 
                  src={profileImage ? URL.createObjectURL(profileImage) : user?.profileImage}
                  alt={user?.name}
                  className="w-24 h-24 rounded-xl object-cover"
                />
              ) : (
                <div className="w-24 h-24 bg-violet-100 rounded-xl flex items-center justify-center">
                  <span className="text-2xl font-semibold text-violet-600">
                    {getInitials(user?.name || '')}
                  </span>
                </div>
              )}
              <label className="absolute -bottom-2 -right-2 p-2 bg-violet-500 text-white rounded-full cursor-pointer hover:bg-violet-600 transition-colors">
                <Camera size={16} />
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => e.target.files && setProfileImage(e.target.files[0])}
                />
              </label>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Profile Photo</h3>
              <p className="text-sm text-gray-500 mt-1">
                Upload a new profile photo or drag and drop one here
              </p>
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-6">Personal Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <FormField
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                icon={<User size={16} className="text-gray-400" />}
              />
              <FormError errors={errors.name} touched={touched.name} />
            </div>
            <div>
              <FormField
                label="Job Title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                icon={<Briefcase size={16} className="text-gray-400" />}
              />
              <FormError errors={errors.jobTitle} touched={touched.jobTitle} />
            </div>
            <div>
              <FormField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                icon={<Mail size={16} className="text-gray-400" />}
              />
              <FormError errors={errors.email} touched={touched.email} />
            </div>
            <div>
              <FormField
                label="Phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                icon={<Phone size={16} className="text-gray-400" />}
              />
              <FormError errors={errors.phone} touched={touched.phone} />
            </div>
          </div>
        </div>

        {/* Password Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <Key className="text-amber-600" size={20} />
              </div>
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Password</h2>
                <p className="text-sm text-gray-500">Update your password</p>
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowPasswordSection(!showPasswordSection)}
              className="text-sm font-medium text-violet-600 hover:text-violet-700"
            >
              {showPasswordSection ? 'Cancel' : 'Change Password'}
            </button>
          </div>

          {showPasswordSection && (
            <div className="space-y-4">
              <div>
                <FormField
                  label="Current Password"
                  name="currentPassword"
                  type="password"
                  value={formData.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  icon={<Lock size={16} className="text-gray-400" />}
                />
                <FormError errors={errors.currentPassword} touched={touched.currentPassword} />
              </div>
              <div>
                <FormField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  value={formData.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  icon={<Lock size={16} className="text-gray-400" />}
                />
                <FormError errors={errors.newPassword} touched={touched.newPassword} />
              </div>
              <div>
                <FormField
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  icon={<Lock size={16} className="text-gray-400" />}
                />
                <FormError errors={errors.confirmPassword} touched={touched.confirmPassword} />
              </div>
            </div>
          )}
        </div>

        {/* Account Information */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Shield className="text-blue-600" size={20} />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Account Information</h2>
              <p className="text-sm text-gray-500">Your account details and role</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <motion.div
              className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-center gap-3 mb-2">
                <UserCheck size={16} className="text-gray-400" />
                <p className="text-sm font-medium text-gray-900">Account Type</p>
              </div>
              <p className="text-sm text-gray-600 ml-9">{user?.role}</p>
            </motion.div>

            <motion.div
              className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-center gap-3 mb-2">
                <Clock size={16} className="text-gray-400" />
                <p className="text-sm font-medium text-gray-900">Member Since</p>
              </div>
              <p className="text-sm text-gray-600 ml-9">{getAccountAge()}</p>
            </motion.div>

            {company && (
              <motion.div
                className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                whileHover={{ y: -2 }}
              >
                <div className="flex items-center gap-3 mb-2">
                  <Building2 size={16} className="text-gray-400" />
                  <p className="text-sm font-medium text-gray-900">Company</p>
                </div>
                <p className="text-sm text-gray-600 ml-9">{company.name}</p>
              </motion.div>
            )}

            <motion.div
              className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-center gap-3 mb-2">
                <Settings size={16} className="text-gray-400" />
                <p className="text-sm font-medium text-gray-900">Preferences</p>
              </div>
              <div className="flex items-center gap-4 ml-9">
                <button className="text-sm text-violet-600 hover:text-violet-700">
                  Notification Settings
                </button>
                <button className="text-sm text-violet-600 hover:text-violet-700">
                  Privacy Settings
                </button>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <motion.button
            type="submit"
            disabled={isLoading}
            className="flex items-center gap-2 px-6 py-2 text-white bg-violet-500 rounded-lg hover:bg-violet-600 disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin" size={16} />
                <span>Saving...</span>
              </>
            ) : (
              <>
                <CheckCircle2 size={16} />
                <span>Save Changes</span>
              </>
            )}
          </motion.button>
        </div>
      </form>
    </div>
  );
}