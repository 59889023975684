import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface MetricCardProps {
  label: string;
  value: number;
  icon: LucideIcon;
  trend: string;
  color: 'blue' | 'violet' | 'emerald' | 'amber';
}

const colorStyles = {
  blue: 'text-blue-500 bg-blue-50',
  violet: 'text-violet-500 bg-violet-50',
  emerald: 'text-emerald-500 bg-emerald-50',
  amber: 'text-amber-500 bg-amber-50',
};

export default function MetricCard({ label, value, icon: Icon, trend, color }: MetricCardProps) {
  return (
    <motion.div
      className="bg-white rounded-xl border border-gray-100 p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -4 }}
      transition={{ duration: 0.2 }}
    >
      <div className="flex items-center justify-between">
        <div className={`p-2 rounded-lg ${colorStyles[color]}`}>
          <Icon size={20} />
        </div>
        <span className="text-sm text-gray-500">{trend}</span>
      </div>
      <p className="mt-4 text-2xl font-bold text-gray-900">
        {typeof value === 'number' && value > 999 
          ? `$${value.toLocaleString()}`
          : value}
      </p>
      <p className="text-sm text-gray-500">{label}</p>
    </motion.div>
  );
}