import React from 'react';
import { motion } from 'framer-motion';
import { NavLink, Outlet } from 'react-router-dom';
import { 
  Settings, 
  Building2, 
  Users, 
  Link as LinkIcon, 
  Palette,
  Shield
} from 'lucide-react';
import { usePermissions } from '../../hooks/usePermissions';
import { ROUTES } from '../../routes/paths';

export default function SettingsLayout() {
  const { 
    canManageCompany,
    canManageUsers,
    canManageRoles,
    canManageIntegrations,
    canViewSettings 
  } = usePermissions();

  const getSettingsNavItems = () => {
    const items = [
      { 
        id: 'general',
        label: 'General Settings',
        icon: Settings,
        path: ROUTES.SETTINGS_GENERAL,
        show: canViewSettings
      }
    ];

    if (canManageCompany) {
      items.push(
        {
          id: 'company',
          label: 'Company Profile',
          icon: Building2,
          path: ROUTES.SETTINGS_COMPANY,
          show: true
        },
        {
          id: 'branding',
          label: 'Branding',
          icon: Palette,
          path: ROUTES.SETTINGS_BRANDING,
          show: true
        }
      );
    }

    if (canManageUsers) {
      items.push({
        id: 'users',
        label: 'Users',
        icon: Users,
        path: ROUTES.SETTINGS_USERS,
        show: true
      });
    }

    if (canManageRoles) {
      items.push({
        id: 'roles',
        label: 'Roles & Permissions',
        icon: Shield,
        path: ROUTES.SETTINGS_ROLES,
        show: true
      });
    }

    if (canManageIntegrations) {
      items.push({
        id: 'integrations',
        label: 'Integrations',
        icon: LinkIcon,
        path: ROUTES.SETTINGS_INTEGRATIONS,
        show: true
      });
    }

    return items.filter(item => item.show);
  };

  const navItems = getSettingsNavItems();

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex items-center gap-3 mb-8">
        <div className="p-2 bg-gray-100 rounded-lg">
          <Settings className="text-gray-600" size={20} />
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
          <p className="text-gray-500">Manage your account settings and preferences</p>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-8">
        {/* Navigation */}
        <nav className="space-y-1">
          {navItems.map((item) => {
            const Icon = item.icon;
            return (
              <NavLink
                key={item.id}
                to={item.path}
                end={item.path === ROUTES.SETTINGS}
                className={({ isActive }) => `
                  flex items-center gap-3 px-4 py-3 rounded-lg transition-colors
                  ${isActive 
                    ? 'bg-gray-900 text-white' 
                    : 'text-gray-600 hover:bg-gray-100'
                  }
                `}
              >
                <Icon size={18} />
                <span className="text-sm font-medium">{item.label}</span>
              </NavLink>
            );
          })}
        </nav>

        {/* Content */}
        <div className="col-span-3">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            <Outlet />
          </motion.div>
        </div>
      </div>
    </div>
  );
}