// src/services/mockData.ts

import { Company, Client, Job, Report } from '../types';
import { Invoice, Payment } from '../types/invoice';
import { Variation } from '../types/variation';
import { Task } from '../types/task';
import { User, UserRole } from '../types/auth';

// Company Logos (using stable Unsplash URLs)
const companyLogos = {
  agrade: 'https://images.unsplash.com/photo-1572177191856-3cde618dee1f?w=100&h=100&fit=crop',
  elite: 'https://images.unsplash.com/photo-1581094794329-c8112a89af12?w=100&h=100&fit=crop'
};

// Client Logos
const clientLogos = {
  johnson: 'https://images.unsplash.com/photo-1579547944212-c2dcdb7f1dcd?w=100&h=100&fit=crop',
  pacific: 'https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?w=100&h=100&fit=crop'
};

// User Profile Images
const userImages = {
  admin: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&fit=crop',
  staff: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=100&fit=crop',
  client: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&fit=crop'
};

// Mock Users
export const mockUsers: User[] = [
  {
    id: 'user-1',
    email: 'admin@company.com',
    name: 'John Smith',
    password: '$2a$10$xxxxxxxxxxx', // Hashed password
    role: 'org_admin',
    status: 'active',
    profileImage: userImages.admin,
    companyId: 'comp-1',
    jobTitle: 'Operations Manager',
    lastActive: new Date(),
    createdAt: new Date('2024-01-01'),
    updatedAt: new Date('2024-01-01')
  },
  // Add more mock users...
];

// Mock Companies
export const mockCompanies: Company[] = [
  {
    id: 'comp-1',
    name: 'A-Grade Construction Ltd',
    logo: companyLogos.agrade,
    email: 'info@agradeconstruction.com',
    billingEmail: 'accounts@agradeconstruction.com',
    website: 'https://agradeconstruction.com',
    phone: '+64 21 123 4567',
    address: '123 Builder Street\nAuckland CBD\nAuckland 1010\nNew Zealand',
    contactName: 'John Smith',
    contactPhone: '+64 21 987 6543',
    contactEmail: 'john.smith@agradeconstruction.com',
    companyId: 'AGC001',
    xeroId: 'xero-123456',
    status: 'active',
    createdAt: new Date('2024-01-01'),
    updatedAt: new Date('2024-01-01')
  },
  // Add more mock companies...
];

// Mock Clients
export const mockClients: Client[] = [
  {
    id: 'client-1',
    companyId: 'comp-1',
    name: 'Johnson Family Trust',
    logo: clientLogos.johnson,
    website: 'https://johnsongroup.co.nz',
    email: 'contact@johnsongroup.co.nz',
    billingEmail: 'accounts@johnsongroup.co.nz',
    phone: '+64 21 345 6789',
    address: '789 Client Road\nParnell\nAuckland 1052\nNew Zealand',
    contactName: 'Michael Johnson',
    contactPhone: '+64 21 765 4321',
    contactEmail: 'michael@johnsongroup.co.nz',
    clientId: 'JFT001',
    xeroId: 'xero-345678',
    status: 'active',
    notes: 'Premium client with multiple ongoing projects',
    createdAt: new Date('2024-01-03'),
    updatedAt: new Date('2024-01-03')
  },
  // Add more mock clients...
];

// Mock Jobs
export const mockJobs: Job[] = [
  {
    id: 'job-1',
    jobId: 'J2024-001',
    clientId: 'client-1',
    companyId: 'comp-1',
    name: 'New Family Home Construction',
    address: '123 New Street, Remuera, Auckland 1050',
    startDate: new Date('2024-01-15'),
    estimatedEndDate: new Date('2024-08-15'),
    status: 'in-progress',
    projectManagerName: 'Max Nimon',
    quoteNumber: 'Q2024-001',
    claimNumber: 'CL-001',
    jobManager: 'Tom Wilson',
    description: 'Construction of a new 4-bedroom luxury family home with high-end finishes',
    contractAmount: 850000,
    totalInvoiced: 297500,
    pendingVariationValue: 15000,
    approvedVariationValue: 25000,
    notes: 'Client has requested additional sustainable features',
    createdAt: new Date('2024-01-15'),
    updatedAt: new Date('2024-01-15')
  },
  // Add more mock jobs...
];

// Mock Tasks
export const mockTasks: Task[] = [
  {
    id: 'task-1',
    title: 'Foundation Inspection',
    description: 'Conduct foundation inspection for Building Consent',
    type: 'inspection',
    status: 'todo',
    priority: 'high',
    assignedToId: 'user-1',
    createdById: 'user-1',
    companyId: 'comp-1',
    jobId: 'job-1',
    startDate: new Date('2024-03-15'),
    dueDate: new Date('2024-03-15'),
    estimatedHours: 2,
    value: 500,
    tags: ['inspection', 'building-consent'],
    attachments: [],
    comments: [],
    subtasks: [],
    dependencies: [],
    watchers: [],
    createdAt: new Date(),
    updatedAt: new Date()
  },
  // Add more mock tasks...
];

// Mock Reports, Variations, Invoices, and Payments...
export const mockReports: Report[] = [/* ... */];
export const mockVariations: Variation[] = [/* ... */];
export const mockInvoices: Invoice[] = [/* ... */];
export const mockPayments: Payment[] = [/* ... */];

// Export all mock data
export const mockData = {
  users: mockUsers,
  companies: mockCompanies,
  clients: mockClients,
  jobs: mockJobs,
  tasks: mockTasks,
  reports: mockReports,
  variations: mockVariations,
  invoices: mockInvoices,
  payments: mockPayments
};
