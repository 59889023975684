import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Shield, X, ChevronDown } from 'lucide-react';
import { PERMISSIONS } from '../../constants/permissions';

interface RolePermission {
  id: string;
  name: string;
  description: string;
  actions: string[];
}

interface CustomRole {
  id: string;
  name: string;
  description: string;
  permissions: RolePermission[];
}

interface RoleFormModalProps {
  role?: CustomRole | null;
  onClose: () => void;
  onSubmit: (role: CustomRole) => void;
}

const permissionCategories = {
  'Company': ['MANAGE_COMPANY'],
  'Users': ['MANAGE_USERS', 'MANAGE_ROLES'],
  'Clients': ['MANAGE_CLIENTS'],
  'Projects': ['MANAGE_JOBS', 'MANAGE_REPORTS', 'MANAGE_VARIATIONS'],
  'Tasks': ['MANAGE_TASKS'],
  'Financial': ['MANAGE_INVOICES'],
  'Settings': ['MANAGE_SETTINGS']
};

export default function RoleFormModal({ role, onClose, onSubmit }: RoleFormModalProps) {
  const [formData, setFormData] = useState<CustomRole>({
    id: role?.id || Math.random().toString(36).substr(2, 9),
    name: role?.name || '',
    description: role?.description || '',
    permissions: role?.permissions || []
  });

  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.name || !formData.description) return;
    onSubmit(formData);
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-xl shadow-lg max-w-2xl w-full max-h-[90vh] overflow-hidden flex flex-col"
      >
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-gray-100">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-violet-50 rounded-lg">
              <Shield className="text-violet-600" size={20} />
            </div>
            <h3 className="text-lg font-semibold text-gray-900">
              {role ? 'Edit Role' : 'Add Custom Role'}
            </h3>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X size={20} className="text-gray-500" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-6">
          <form id="roleForm" onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Role Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <input
                  type="text"
                  value={formData.description}
                  onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                  required
                />
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Permissions</h4>
              <div className="space-y-2">
                {Object.entries(permissionCategories).map(([category, permissionIds]) => (
                  <div
                    key={category}
                    className="border border-gray-200 rounded-lg overflow-hidden"
                  >
                    <button
                      type="button"
                      onClick={() => setExpandedSection(expandedSection === category ? null : category)}
                      className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
                    >
                      <span className="font-medium text-gray-900">{category}</span>
                      <ChevronDown 
                        size={16} 
                        className={`text-gray-500 transform transition-transform ${
                          expandedSection === category ? 'rotate-180' : ''
                        }`} 
                      />
                    </button>
                    
                    {expandedSection === category && (
                      <div className="p-4 space-y-4">
                        {permissionIds.map(id => {
                          const permission = PERMISSIONS[id];
                          return (
                            <div key={id} className="space-y-2">
                              <div className="flex items-center gap-3">
                                <input
                                  type="checkbox"
                                  checked={formData.permissions.some(p => p.id === permission.id)}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      setFormData(prev => ({
                                        ...prev,
                                        permissions: [...prev.permissions, permission]
                                      }));
                                    } else {
                                      setFormData(prev => ({
                                        ...prev,
                                        permissions: prev.permissions.filter(p => p.id !== permission.id)
                                      }));
                                    }
                                  }}
                                  className="w-4 h-4 text-violet-600 border-gray-300 rounded focus:ring-violet-500"
                                />
                                <div>
                                  <p className="text-sm font-medium text-gray-900">
                                    {permission.name}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {permission.description}
                                  </p>
                                </div>
                              </div>

                              {formData.permissions.some(p => p.id === permission.id) && (
                                <div className="ml-7 flex flex-wrap gap-2">
                                  {permission.actions.map(action => (
                                    <label
                                      key={action}
                                      className="inline-flex items-center gap-2 px-3 py-1.5 bg-gray-50 rounded-lg border border-gray-200"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={formData.permissions
                                          .find(p => p.id === permission.id)
                                          ?.actions.includes(action) || false}
                                        onChange={e => {
                                          setFormData(prev => ({
                                            ...prev,
                                            permissions: prev.permissions.map(p => {
                                              if (p.id !== permission.id) return p;
                                              return {
                                                ...p,
                                                actions: e.target.checked
                                                  ? [...p.actions, action]
                                                  : p.actions.filter(a => a !== action)
                                              };
                                            })
                                          }));
                                        }}
                                        className="w-4 h-4 text-violet-600 border-gray-300 rounded focus:ring-violet-500"
                                      />
                                      <span className="text-sm text-gray-700 capitalize">
                                        {action}
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 p-6 border-t border-gray-100">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            form="roleForm"
            className="px-4 py-2 text-sm font-medium text-white bg-violet-500 hover:bg-violet-600 rounded-lg transition-colors"
          >
            {role ? 'Update Role' : 'Create Role'}
          </button>
        </div>
      </motion.div>
    </div>
  );
}