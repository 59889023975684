import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, CreditCard, Wallet } from 'lucide-react';
import { Invoice } from '../../types/invoice';
import { useData } from '../../context/DataContext';

interface PaymentHistoryProps {
  invoice: Invoice;
}

export default function PaymentHistory({ invoice }: PaymentHistoryProps) {
  const { state } = useData();
  const payments = state.payments.filter(p => p.invoiceId === invoice.id);

  if (payments.length === 0) {
    return null;
  }

  const methodIcons = {
    bank: CreditCard,
    cash: Wallet,
    credit: CreditCard,
    other: DollarSign,
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-6">Payment History</h2>
      
      <div className="space-y-4">
        {payments.map((payment, index) => {
          const Icon = methodIcons[payment.method];
          
          return (
            <motion.div
              key={payment.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center gap-3">
                <div className="p-2 bg-green-100 rounded-lg">
                  <Icon size={16} className="text-green-600" />
                </div>
                <div>
                  <p className="font-medium text-gray-900">
                    ${payment.amount.toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-500">
                    {new Date(payment.date).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <p className="text-sm font-medium text-gray-900">
                  {payment.method.charAt(0).toUpperCase() + payment.method.slice(1)}
                </p>
                {payment.reference && (
                  <p className="text-xs text-gray-500">
                    Ref: {payment.reference}
                  </p>
                )}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}