import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Building2, Plus, LayoutGrid, Table2 } from 'lucide-react';
import { useInvoices } from '../../hooks/useInvoices';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { Invoice } from '../../types/invoice';
import InvoiceFormModal from '../modals/InvoiceFormModal';
import InvoiceCard from './InvoiceCard';
import InvoiceTable from './InvoiceTable';
import LoadingState from '../ui/LoadingState';
import ErrorState from '../ui/ErrorState';
import EmptyState from '../ui/EmptyState';
import ConfirmDialog from '../ui/ConfirmDialog';
import { useNotification } from '../../context/NotificationContext';
import { useSearch } from '../../hooks/useSearch';
import SearchAndFilter from '../ui/SearchAndFilter';
import { useViewMode } from '../../hooks/useViewMode';
import { useAppNavigation } from '../../routes/useNavigation';

export default function InvoiceList() {
  const navigation = useAppNavigation();
  const [viewMode, setViewMode] = useViewMode('invoiceListViewMode', 'card');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState<Invoice | null>(null);
  const [invoiceToEdit, setInvoiceToEdit] = useState<Invoice | null>(null);
  const { invoices, loading, error, fetchInvoices, addInvoice, updateInvoice, deleteInvoice } = useInvoices();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { addNotification } = useNotification();

  const {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  } = useSearch({
    data: invoices,
    searchFields: ['invoiceNumber', 'reference'],
    filterConfig: {
      status: (invoice, values) => values.includes(invoice.status),
    },
  });

  const filters = [
    {
      id: 'status',
      label: 'Status',
      options: [
        { value: 'draft', label: 'Draft' },
        { value: 'pending', label: 'Pending' },
        { value: 'paid', label: 'Paid' },
        { value: 'overdue', label: 'Overdue' },
        { value: 'void', label: 'Void' },
      ],
    },
  ];

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  const handleDelete = (invoice: Invoice) => {
    setInvoiceToDelete(invoice);
  };

  const handleEdit = (invoice: Invoice) => {
    setInvoiceToEdit(invoice);
  };

  const handleConfirmDelete = async () => {
    if (invoiceToDelete) {
      try {
        await deleteInvoice(invoiceToDelete.id);
        addNotification('success', 'Invoice deleted successfully');
      } catch (error) {
        addNotification('error', 'Failed to delete invoice');
      }
      setInvoiceToDelete(null);
    }
  };

  const handleSubmit = async (data: Partial<Invoice>) => {
    try {
      if (invoiceToEdit) {
        await updateInvoice({ ...data, id: invoiceToEdit.id } as Invoice);
        addNotification('success', 'Invoice updated successfully');
        setInvoiceToEdit(null);
      } else {
        await addInvoice(data);
        addNotification('success', 'Invoice created successfully');
        setIsAddModalOpen(false);
      }
    } catch (error) {
      addNotification('error', `Failed to ${invoiceToEdit ? 'update' : 'create'} invoice`);
    }
  };

  const handleInvoiceClick = (invoice: Invoice) => {
    navigation.goToInvoice(invoice.id);
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} onRetry={fetchInvoices} />;
  if (!invoices.length) {
    return (
      <EmptyState
        title="No Invoices Found"
        message="Get started by creating your first invoice."
        icon={<DollarSign size={24} className="text-gray-400" />}
        actionLabel="Create Invoice"
        onAction={() => setIsAddModalOpen(true)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <DollarSign className="text-blue-600" size={24} />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Invoices</h1>
            <p className="text-sm text-gray-500">Manage your invoices and payments</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* View Toggle */}
          <div className="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewMode('card')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'card'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <LayoutGrid size={20} />
            </button>
            <button
              onClick={() => setViewMode('table')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'table'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Table2 size={20} />
            </button>
          </div>

          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
          >
            <div className="flex items-center gap-2">
              <Plus size={16} />
              <span>Create Invoice</span>
            </div>
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <SearchAndFilter
        searchPlaceholder="Search invoices..."
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={filters}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
        showFilters={showFilters}
        onToggleFilters={toggleFilters}
      />

      {/* Invoices List */}
      {viewMode === 'card' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((invoice) => (
            <InvoiceCard
              key={invoice.id}
              invoice={invoice}
              client={clients.find(c => c.id === invoice.clientId)}
              onSelect={() => handleInvoiceClick(invoice)}
              onEdit={() => handleEdit(invoice)}
              onDelete={() => handleDelete(invoice)}
            />
          ))}
        </div>
      ) : (
        <InvoiceTable
          invoices={filteredData}
          clients={clients}
          onSelect={handleInvoiceClick}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {/* Add/Edit Invoice Modal */}
      {(isAddModalOpen || invoiceToEdit) && (
        <InvoiceFormModal
          isOpen={true}
          onClose={() => {
            setIsAddModalOpen(false);
            setInvoiceToEdit(null);
          }}
          onSubmit={handleSubmit}
          initialData={invoiceToEdit || undefined}
          companyId={companies[0]?.id || ''}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={!!invoiceToDelete}
        onClose={() => setInvoiceToDelete(null)}
        onConfirm={handleConfirmDelete}
        title="Delete Invoice"
        message={`Are you sure you want to delete invoice ${invoiceToDelete?.invoiceNumber}? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </div>
  );
}