import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Users, 
  Briefcase, 
  Settings, 
  Link, 
  ArrowRight, 
  ArrowLeft,
  Mail,
  Plus,
  Loader2,
  CheckCircle2,
  Building2
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

type Step = 'welcome' | 'team' | 'project' | 'integrations' | 'complete';

interface TeamMember {
  email: string;
  role: 'admin' | 'user';
}

interface FormData {
  // Team Members
  teamMembers: TeamMember[];
  // Initial Project
  projectName: string;
  projectType: string;
  clientName: string;
  clientEmail: string;
  // Integrations
  xeroEnabled: boolean;
  xeroEmail?: string;
  microsoftEnabled: boolean;
  microsoftEmail?: string;
}

export default function OnboardingFlow() {
  const navigate = useNavigate();
  const { user, company, updateCompany } = useAuth();
  const [currentStep, setCurrentStep] = React.useState<Step>('welcome');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [formData, setFormData] = React.useState<FormData>({
    teamMembers: [{ email: '', role: 'user' }],
    projectName: '',
    projectType: '',
    clientName: '',
    clientEmail: '',
    xeroEnabled: false,
    microsoftEnabled: false
  });

  const steps: { id: Step; title: string; description: string }[] = [
    {
      id: 'welcome',
      title: 'Welcome',
      description: 'Let\'s get you set up'
    },
    {
      id: 'team',
      title: 'Invite Team',
      description: 'Add your team members'
    },
    {
      id: 'project',
      title: 'First Project',
      description: 'Create your first project'
    },
    {
      id: 'integrations',
      title: 'Integrations',
      description: 'Connect your tools'
    },
    {
      id: 'complete',
      title: 'All Set',
      description: 'Ready to go'
    }
  ];

  const validateStep = () => {
    setError('');

    switch (currentStep) {
      case 'team':
        if (formData.teamMembers.some(member => !member.email)) {
          setError('Please enter email addresses for all team members');
          return false;
        }
        break;

      case 'project':
        if (!formData.projectName || !formData.projectType || !formData.clientName || !formData.clientEmail) {
          setError('All fields are required');
          return false;
        }
        break;

      case 'integrations':
        if (formData.xeroEnabled && !formData.xeroEmail) {
          setError('Please enter your Xero email');
          return false;
        }
        if (formData.microsoftEnabled && !formData.microsoftEmail) {
          setError('Please enter your Microsoft email');
          return false;
        }
        break;
    }

    return true;
  };

  const handleNext = () => {
    if (!validateStep()) return;

    const stepIndex = steps.findIndex(step => step.id === currentStep);
    if (stepIndex < steps.length - 1) {
      setCurrentStep(steps[stepIndex + 1].id);
    }
  };

  const handleBack = () => {
    const stepIndex = steps.findIndex(step => step.id === currentStep);
    if (stepIndex > 0) {
      setCurrentStep(steps[stepIndex - 1].id);
    }
  };

  const handleComplete = async () => {
    setIsLoading(true);
    try {
      // In real app, send data to API
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate('/app');
    } catch (err) {
      setError('Failed to complete setup');
    } finally {
      setIsLoading(false);
    }
  };

  const addTeamMember = () => {
    setFormData(prev => ({
      ...prev,
      teamMembers: [...prev.teamMembers, { email: '', role: 'user' }]
    }));
  };

  const removeTeamMember = (index: number) => {
    setFormData(prev => ({
      ...prev,
      teamMembers: prev.teamMembers.filter((_, i) => i !== index)
    }));
  };

  const updateTeamMember = (index: number, field: keyof TeamMember, value: string) => {
    setFormData(prev => ({
      ...prev,
      teamMembers: prev.teamMembers.map((member, i) => 
        i === index ? { ...member, [field]: value } : member
      )
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex items-center justify-center gap-2">
          <div className="w-10 h-10 bg-gray-900 rounded-xl flex items-center justify-center">
            <Building2 className="text-white" size={24} />
          </div>
          <span className="text-xl font-semibold text-gray-900">ProgressPro</span>
        </div>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <motion.div
          className="bg-white py-8 px-4 shadow-sm sm:rounded-xl sm:px-10 border border-gray-100"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Progress Steps */}
          <div className="mb-8">
            <div className="flex justify-between">
              {steps.map((step, index) => (
                <div key={step.id} className="flex-1">
                  <div className="flex items-center">
                    <div className={`flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full border-2 ${
                      currentStep === step.id
                        ? 'border-teal-500 bg-teal-50 text-teal-500'
                        : index < steps.findIndex(s => s.id === currentStep)
                        ? 'border-teal-500 bg-teal-500 text-white'
                        : 'border-gray-300 bg-white text-gray-500'
                    }`}>
                      {index < steps.findIndex(s => s.id === currentStep) ? (
                        <CheckCircle2 size={16} />
                      ) : (
                        index + 1
                      )}
                    </div>
                    {index < steps.length - 1 && (
                      <div className={`flex-1 h-0.5 mx-2 ${
                        index < steps.findIndex(s => s.id === currentStep)
                          ? 'bg-teal-500'
                          : 'bg-gray-300'
                      }`} />
                    )}
                  </div>
                  <div className="mt-2">
                    <p className="text-xs font-medium text-gray-500">{step.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {error && (
            <div className="mb-4 p-4 bg-red-50 text-red-600 text-sm rounded-lg">
              {error}
            </div>
          )}

          {/* Step Content */}
          <div className="space-y-6">
            <AnimatePresence mode="wait">
              {currentStep === 'welcome' && (
                <motion.div
                  key="welcome"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="text-center"
                >
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    Welcome to ProgressPro
                  </h2>
                  <p className="text-gray-500 mb-8">
                    Let's get your account set up in just a few steps.
                  </p>
                  <div className="flex justify-center">
                    <img
                      src={company?.logo || '/placeholder-logo.png'}
                      alt={company?.name}
                      className="w-32 h-32 object-contain rounded-lg"
                    />
                  </div>
                </motion.div>
              )}

              {currentStep === 'team' && (
                <motion.div
                  key="team"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="space-y-6"
                >
                  <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">
                      Invite Your Team
                    </h2>
                    <p className="text-gray-500">
                      Add team members to collaborate on projects
                    </p>
                  </div>

                  <div className="space-y-4">
                    {formData.teamMembers.map((member, index) => (
                      <div key={index} className="flex items-center gap-4">
                        <div className="flex-1">
                          <div className="relative">
                            <input
                              type="email"
                              value={member.email}
                              onChange={e => updateTeamMember(index, 'email', e.target.value)}
                              placeholder="Email address"
                              className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                            />
                            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                          </div>
                        </div>
                        <select
                          value={member.role}
                          onChange={e => updateTeamMember(index, 'role', e.target.value)}
                          className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        >
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                        </select>
                        {index > 0 && (
                          <button
                            type="button"
                            onClick={() => removeTeamMember(index)}
                            className="p-2 text-gray-400 hover:text-gray-500"
                          >
                            &times;
                          </button>
                        )}
                      </div>
                    ))}
                  </div>

                  <button
                    type="button"
                    onClick={addTeamMember}
                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                  >
                    <Plus size={16} />
                    Add Another
                  </button>
                </motion.div>
              )}

              {currentStep === 'project' && (
                <motion.div
                  key="project"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="space-y-6"
                >
                  <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">
                      Create Your First Project
                    </h2>
                    <p className="text-gray-500">
                      Set up a project to get started
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Project Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={formData.projectName}
                        onChange={e => setFormData(prev => ({ ...prev, projectName: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Project Type
                    </label>
                    <select
                      value={formData.projectType}
                      onChange={e => setFormData(prev => ({ ...prev, projectType: e.target.value }))}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                    >
                      <option value="">Select type</option>
                      <option value="residential">Residential Construction</option>
                      <option value="commercial">Commercial Construction</option>
                      <option value="industrial">Industrial Construction</option>
                      <option value="renovation">Renovation</option>
                    </select>
                  </div>

                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Client Name
                      </label>
                      <input
                        type="text"
                        value={formData.clientName}
                        onChange={e => setFormData(prev => ({ ...prev, clientName: e.target.value }))}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Client Email
                      </label>
                      <input
                        type="email"
                        value={formData.clientEmail}
                        onChange={e => setFormData(prev => ({ ...prev, clientEmail: e.target.value }))}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                      />
                    </div>
                  </div>
                </motion.div>
              )}

              {currentStep === 'integrations' && (
                <motion.div
                  key="integrations"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="space-y-6"
                >
                  <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">
                      Connect Your Tools
                    </h2>
                    <p className="text-gray-500">
                      Integrate with your existing software
                    </p>
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-3">
                        <img
                          src="https://www.xero.com/favicon.ico"
                          alt="Xero"
                          className="w-8 h-8"
                        />
                        <div>
                          <h3 className="font-medium text-gray-900">Xero</h3>
                          <p className="text-sm text-gray-500">Connect your accounting software</p>
                        </div>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={formData.xeroEnabled}
                          onChange={e => setFormData(prev => ({ 
                            ...prev, 
                            xeroEnabled: e.target.checked 
                          }))}
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-teal-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                      </label>
                    </div>

                    {formData.xeroEnabled && (
                      <div className="ml-12">
                        <input
                          type="email"
                          value={formData.xeroEmail}
                          onChange={e => setFormData(prev => ({ 
                            ...prev, 
                            xeroEmail: e.target.value 
                          }))}
                          placeholder="Xero account email"
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        />
                      </div>
                    )}

                    <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-3">
                        <img
                          src="https://www.microsoft.com/favicon.ico"
                          alt="Microsoft"
                          className="w-8 h-8"
                        />
                        <div>
                          <h3 className="font-medium text-gray-900">Microsoft</h3>
                          <p className="text-sm text-gray-500">Connect your Microsoft account</p>
                        </div>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={formData.microsoftEnabled}
                          onChange={e => setFormData(prev => ({ 
                            ...prev, 
                            microsoftEnabled: e.target.checked 
                          }))}
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-teal-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                      </label>
                    </div>

                    {formData.microsoftEnabled && (
                      <div className="ml-12">
                        <input
                          type="email"
                          value={formData.microsoftEmail}
                          onChange={e => setFormData(prev => ({ 
                            ...prev, 
                            microsoftEmail: e.target.value 
                          }))}
                          placeholder="Microsoft account email"
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        />
                      </div>
                    )}
                  </div>
                </motion.div>
              )}

              {currentStep === 'complete' && (
                <motion.div
                  key="complete"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="text-center"
                >
                  <div className="inline-flex items-center justify-center w-16 h-16 bg-teal-100 rounded-full mb-6">
                    <CheckCircle2 size={32} className="text-teal-600" />
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    You're All Set!
                  </h2>
                  <p className="text-gray-500 mb-8">
                    Your account is ready to use. Let's get started!
                  </p>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Navigation */}
            <div className="flex items-center justify-between pt-6 border-t border-gray-100">
              {currentStep !== 'welcome' && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                >
                  <ArrowLeft size={16} />
                  Back
                </button>
              )}

              {currentStep === 'complete' ? (
                <button
                  type="button"
                  onClick={handleComplete}
                  disabled={isLoading}
                  className="flex items-center gap-2 px-6 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed ml-auto"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="animate-spin" size={16} />
                      <span>Setting up...</span>
                    </>
                  ) : (
                    <>
                      <span>Go to Dashboard</span>
                      <ArrowRight size={16} />
                    </>
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleNext}
                  className="flex items-center gap-2 px-6 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-800 ml-auto"
                >
                  <span>Continue</span>
                  <ArrowRight size={16} />
                </button>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}