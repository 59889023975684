import React, { createContext, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';

interface BrandingContextType {
  primaryColor: string;
  secondaryColor: string;
  applyBrandColor: (color: string, opacity?: number) => string;
  generateBrandClasses: (variant: 'primary' | 'secondary') => {
    bg: string;
    text: string;
    border: string;
    hover: string;
  };
}

const BrandingContext = createContext<BrandingContextType | null>(null);

export function BrandingProvider({ children }: { children: React.ReactNode }) {
  const { company } = useAuth();
  const [primaryColor, setPrimaryColor] = React.useState('#10B981');
  const [secondaryColor, setSecondaryColor] = React.useState('#6366F1');

  useEffect(() => {
    if (company?.brandColors) {
      setPrimaryColor(company.brandColors.primary);
      setSecondaryColor(company.brandColors.secondary);
      
      // Update CSS variables
      const root = document.documentElement;
      root.style.setProperty('--brand-primary', company.brandColors.primary);
      root.style.setProperty('--brand-secondary', company.brandColors.secondary);
    }
  }, [company]);

  const applyBrandColor = (color: string, opacity = 1) => {
    // Convert hex to RGB and apply opacity
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const generateBrandClasses = (variant: 'primary' | 'secondary') => {
    const color = variant === 'primary' ? primaryColor : secondaryColor;
    return {
      bg: `bg-[${color}]`,
      text: `text-[${color}]`,
      border: `border-[${color}]`,
      hover: `hover:bg-[${applyBrandColor(color, 0.9)}]`
    };
  };

  return (
    <BrandingContext.Provider value={{
      primaryColor,
      secondaryColor,
      applyBrandColor,
      generateBrandClasses
    }}>
      {children}
    </BrandingContext.Provider>
  );
}

export function useBranding() {
  const context = useContext(BrandingContext);
  if (!context) {
    throw new Error('useBranding must be used within a BrandingProvider');
  }
  return context;
}