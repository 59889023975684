import { RouteConfig } from '../types/routes';
import { PERMISSIONS } from '../constants/permissions';

// Components
import DashboardOverview from '../components/dashboard/DashboardOverview';
import ClientList from '../components/dashboard/ClientList';
import JobList from '../components/dashboard/JobList';
import ReportList from '../components/dashboard/ReportList';
import InvoiceList from '../components/dashboard/InvoiceList';
import VariationList from '../components/variations/VariationList';
import TaskBoard from '../components/tasks/TaskBoard';
import TaskCalendarView from '../components/tasks/TaskCalendarView';
import SettingsLayout from '../components/settings/SettingsLayout';
import GeneralSettings from '../components/settings/GeneralSettings';
import CompanySettings from '../components/settings/CompanySettings';
import BrandingSettings from '../components/settings/BrandingSettings';
import UserPermissions from '../components/settings/UserPermissions';
import RoleManagement from '../components/settings/RoleManagement';
import IntegrationSettings from '../components/settings/IntegrationSettings';

// Route Paths
export const ROUTE_PATHS = {
  // Public Routes
  HOME: '/',
  FEATURES: '/features',
  PRICING: '/pricing',
  CONTACT: '/contact',

  // Auth Routes
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  ACCEPT_INVITE: '/accept-invite/:token',
  ONBOARDING: '/onboarding',

  // App Base
  APP: '/app',
  DASHBOARD: '/app',
  PROFILE: '/app/profile',

  // Tasks
  TASKS: '/app/tasks',
  TASK_BOARD: '/app/tasks/board',
  TASK_CALENDAR: '/app/tasks/calendar',
  TASK_VIEW: '/app/tasks/:taskId',

  // Companies
  COMPANIES: '/app/companies',
  COMPANY_VIEW: '/app/companies/:companyId',
  COMPANY_SETTINGS: '/app/companies/:companyId/settings',

  // Clients
  CLIENTS: '/app/clients',
  CLIENT_VIEW: '/app/clients/:clientId',
  CLIENT_JOBS: '/app/clients/:clientId/jobs',

  // Jobs
  JOBS: '/app/jobs',
  JOB_VIEW: '/app/jobs/:jobId',
  JOB_REPORTS: '/app/jobs/:jobId/reports',

  // Reports
  REPORTS: '/app/reports',
  REPORT_VIEW: '/app/reports/:reportId',
  SHARED_REPORT: '/shared/reports/:reportId',

  // Invoices
  INVOICES: '/app/invoices',
  INVOICE_VIEW: '/app/invoices/:invoiceId',

  // Variations
  VARIATIONS: '/app/variations',
  VARIATION_VIEW: '/app/variations/:variationId',

  // Settings
  SETTINGS: '/app/settings',
  SETTINGS_GENERAL: '/app/settings',
  SETTINGS_COMPANY: '/app/settings/company',
  SETTINGS_BRANDING: '/app/settings/branding',
  SETTINGS_USERS: '/app/settings/users',
  SETTINGS_ROLES: '/app/settings/roles',
  SETTINGS_INTEGRATIONS: '/app/settings/integrations',

  // Search
  SEARCH_RESULTS: '/app/search',
} as const;

// App Routes Configuration
export const APP_ROUTES: RouteConfig[] = [
  {
    path: '',
    component: DashboardOverview,
    label: 'Dashboard',
    icon: 'LayoutDashboard',
    allowedRoles: ['super_admin', 'org_admin', 'admin', 'user']
  },
  {
    path: 'tasks',
    component: TaskBoard,
    label: 'Tasks',
    icon: 'ClipboardList',
    permission: { id: 'manage_tasks', action: 'view' },
    allowedRoles: ['super_admin', 'org_admin', 'admin', 'user'],
    children: [
      {
        path: 'board',
        component: TaskBoard,
        label: 'Task Board',
        icon: 'LayoutGrid'
      },
      {
        path: 'calendar',
        component: TaskCalendarView,
        label: 'Task Calendar',
        icon: 'Calendar'
      }
    ]
  },
  // ... rest of the routes remain unchanged
];