import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, TrendingDown } from 'lucide-react';

interface FinancialChartProps {
  contractAmount: number;
  invoicedToDate: number;
  approvedVariations: number;
  pendingVariations: number;
}

export default function FinancialChart({
  contractAmount,
  invoicedToDate,
  approvedVariations,
  pendingVariations,
}: FinancialChartProps) {
  const totalValue = contractAmount + approvedVariations;
  const progressPercentage = totalValue > 0 ? Math.round((invoicedToDate / totalValue) * 100) : 0;

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-4 mb-2">
        <div>
          <h4 className="text-sm font-medium text-gray-500 mb-1">Amount Invoiced</h4>
          <div className="text-xl sm:text-2xl font-bold text-gray-900 flex flex-wrap items-baseline gap-2">
            <span className="flex-shrink-0">
              ${invoicedToDate.toLocaleString()}
            </span>
            <span className="text-sm font-normal text-gray-500 whitespace-nowrap">
              of ${totalValue.toLocaleString()}
            </span>
          </div>
        </div>
        <div className="text-right flex-shrink-0">
          <span className="text-2xl font-bold text-emerald-500">
            {progressPercentage}%
          </span>
          <p className="text-sm text-gray-500">Complete</p>
        </div>
      </div>

      <div className="relative h-4 bg-gray-100 rounded-full overflow-hidden">
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500"
          initial={{ width: 0 }}
          animate={{ width: `${progressPercentage}%` }}
          transition={{ duration: 1.5, ease: [0.34, 1.56, 0.64, 1] }}
        />
      </div>

      <div className="flex justify-between text-sm text-gray-500">
        <span>$0</span>
        <span className="hidden sm:inline">${(totalValue / 2).toLocaleString()}</span>
        <span>${totalValue.toLocaleString()}</span>
      </div>

      <div className="flex items-center gap-2 text-sm mt-2">
        {progressPercentage > 0 && (
          <>
            {progressPercentage >= 50 ? (
              <TrendingUp size={16} className="text-emerald-500" />
            ) : (
              <TrendingDown size={16} className="text-amber-500" />
            )}
            <span className={progressPercentage >= 50 ? 'text-emerald-600' : 'text-amber-600'}>
              {progressPercentage >= 50 ? 'On Track' : 'Behind Schedule'}
            </span>
          </>
        )}
      </div>
    </div>
  );
}