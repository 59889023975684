import React from 'react';
import { motion } from 'framer-motion';
import { GitBranch, Calendar, DollarSign, Clock, CheckCircle2, XCircle } from 'lucide-react';
import { Variation } from '../../types/variation';
import ActionMenu from '../ui/ActionMenu';

interface VariationCardProps {
  variation: Variation;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function VariationCard({ variation, onSelect, onEdit, onDelete }: VariationCardProps) {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'bg-emerald-50 text-emerald-600';
      case 'pending_approval':
        return 'bg-amber-50 text-amber-600';
      default:
        return 'bg-gray-50 text-gray-600';
    }
  };

  const getProgressColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-emerald-50 text-emerald-600';
      case 'in_progress':
        return 'bg-blue-50 text-blue-600';
      default:
        return 'bg-gray-50 text-gray-600';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'approved':
        return <CheckCircle2 size={14} />;
      case 'pending_approval':
        return <Clock size={14} />;
      default:
        return <XCircle size={14} />;
    }
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden cursor-pointer group"
    >
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3" onClick={onSelect}>
            <div className="p-2 bg-violet-50 rounded-lg group-hover:bg-violet-100 transition-colors">
              <GitBranch className="text-violet-600" size={20} />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 group-hover:text-violet-600 transition-colors">
                {variation.name}
              </h3>
              <p className="text-sm text-gray-500">{variation.variationId}</p>
            </div>
          </div>
          <ActionMenu
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>

        <div className="space-y-4">
          <p className="text-sm text-gray-600 line-clamp-2">
            {variation.description}
          </p>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <DollarSign size={14} className="text-gray-400" />
              <span className="text-sm font-medium text-gray-900">
                ${variation.quotedAmount.toLocaleString()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Calendar size={14} className="text-gray-400" />
              <span className="text-sm text-gray-500">
                {new Date(variation.dateAdded).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${getStatusColor(variation.status)}`}>
              {getStatusIcon(variation.status)}
              {variation.status === 'pending_approval' ? 'Pending Approval' : 'Approved'}
            </span>
            <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${getProgressColor(variation.progressStatus)}`}>
              {variation.progressStatus === 'completed' ? <CheckCircle2 size={14} /> : <Clock size={14} />}
              {variation.progressStatus.split('_').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ')}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}