import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowUpRight, Calendar, ChevronUp, ChevronDown,
  GitBranch, CheckCircle2, Clock, XCircle, AlertTriangle,
  DollarSign, Briefcase, FileText, User
} from 'lucide-react';
import { Variation } from '../../types/variation';
import ActionMenu from '../ui/ActionMenu';

interface VariationTableProps {
  variations: Variation[];
  onSelect: (variation: Variation) => void;
  onEdit: (variation: Variation) => void;
  onDelete: (variation: Variation) => void;
}

type SortField = 'name' | 'status' | 'progressStatus' | 'quotedAmount' | 'dateAdded';
type SortDirection = 'asc' | 'desc';

export default function VariationTable({ variations, onSelect, onEdit, onDelete }: VariationTableProps) {
  const [sortField, setSortField] = useState<SortField>('dateAdded');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedVariations = [...variations].sort((a, b) => {
    let comparison = 0;
    
    switch (sortField) {
      case 'name':
        comparison = a.name.localeCompare(b.name);
        break;
      case 'status':
        comparison = a.status.localeCompare(b.status);
        break;
      case 'progressStatus':
        comparison = a.progressStatus.localeCompare(b.progressStatus);
        break;
      case 'quotedAmount':
        comparison = a.quotedAmount - b.quotedAmount;
        break;
      case 'dateAdded':
        comparison = new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime();
        break;
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <ChevronUp className="opacity-0 group-hover:opacity-50" size={14} />;
    return sortDirection === 'asc' ? 
      <ChevronUp className="text-violet-500" size={14} /> : 
      <ChevronDown className="text-violet-500" size={14} />;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'bg-emerald-50 text-emerald-700 border-emerald-200';
      case 'pending_approval':
        return 'bg-amber-50 text-amber-700 border-amber-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  const getProgressColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-emerald-50 text-emerald-700 border-emerald-200';
      case 'in_progress':
        return 'bg-blue-50 text-blue-700 border-blue-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'approved':
        return <CheckCircle2 size={14} className="text-emerald-500" />;
      case 'pending_approval':
        return <Clock size={14} className="text-amber-500" />;
      default:
        return <AlertTriangle size={14} className="text-gray-500" />;
    }
  };

  const getProgressIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 size={14} className="text-emerald-500" />;
      case 'in_progress':
        return <Clock size={14} className="text-blue-500" />;
      default:
        return <Clock size={14} className="text-gray-500" />;
    }
  };

  const formatDate = (date: Date | string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-100">
              <th 
                className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('name')}
              >
                <div className="flex items-center gap-2">
                  <GitBranch size={16} className="text-gray-400" />
                  <span>Variation</span>
                  <SortIcon field="name" />
                </div>
              </th>
              <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">
                <div className="flex items-center gap-2">
                  <Briefcase size={16} className="text-gray-400" />
                  <span>Job</span>
                </div>
              </th>
              <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">
                <div className="flex items-center gap-2">
                  <User size={16} className="text-gray-400" />
                  <span>Created By</span>
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('status')}
              >
                <div className="flex items-center justify-center gap-2">
                  <FileText size={16} className="text-gray-400" />
                  <span>Status</span>
                  <SortIcon field="status" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('progressStatus')}
              >
                <div className="flex items-center justify-center gap-2">
                  <Clock size={16} className="text-gray-400" />
                  <span>Progress</span>
                  <SortIcon field="progressStatus" />
                </div>
              </th>
              <th 
                className="text-right py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('quotedAmount')}
              >
                <div className="flex items-center justify-end gap-2">
                  <DollarSign size={16} className="text-gray-400" />
                  <span>Amount</span>
                  <SortIcon field="quotedAmount" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('dateAdded')}
              >
                <div className="flex items-center justify-center gap-2">
                  <Calendar size={16} className="text-gray-400" />
                  <span>Date Added</span>
                  <SortIcon field="dateAdded" />
                </div>
              </th>
              <th className="text-right py-4 px-6 text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50">
            {sortedVariations.map((variation) => {
              const isHovered = hoveredRow === variation.id;
              
              return (
                <motion.tr
                  key={variation.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="group hover:bg-gray-50 cursor-pointer transition-colors"
                  onClick={() => onSelect(variation)}
                  onMouseEnter={() => setHoveredRow(variation.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      <div className={`p-2 rounded-lg transition-colors ${
                        isHovered ? 'bg-violet-100' : 'bg-violet-50'
                      }`}>
                        <GitBranch size={16} className="text-violet-600" />
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">{variation.name}</p>
                        <p className="text-xs text-gray-500">{variation.variationId}</p>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="max-w-xs">
                      <p className="text-sm text-gray-900 line-clamp-2">{variation.description}</p>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-sm text-gray-600">
                      {variation.projectManagerId || 'Not Assigned'}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-center">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium border ${
                        getStatusColor(variation.status)
                      }`}>
                        {getStatusIcon(variation.status)}
                        {variation.status === 'pending_approval' ? 'Pending' : 'Approved'}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-center">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium border ${
                        getProgressColor(variation.progressStatus)
                      }`}>
                        {getProgressIcon(variation.progressStatus)}
                        {variation.progressStatus.split('_').map(word => 
                          word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' ')}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        ${variation.quotedAmount.toLocaleString()}
                      </p>
                      {variation.invoicedToDate > 0 && (
                        <p className="text-xs text-emerald-600 mt-1">
                          ${variation.invoicedToDate.toLocaleString()} invoiced
                        </p>
                      )}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-center text-sm text-gray-500">
                      {formatDate(variation.dateAdded)}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex items-center justify-end gap-2">
                      <ActionMenu
                        onEdit={() => onEdit(variation)}
                        onDelete={() => onDelete(variation)}
                      />
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelect(variation);
                        }}
                        className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                      >
                        <ArrowUpRight size={16} />
                      </button>
                    </div>
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}