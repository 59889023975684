import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertCircle } from 'lucide-react';

interface FormErrorProps {
  errors?: string[];
  touched?: boolean;
}

export default function FormError({ errors, touched }: FormErrorProps) {
  if (!errors?.length || !touched) return null;

  return (
    <AnimatePresence>
      {errors.map((error, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="flex items-center gap-1.5 mt-1"
        >
          <AlertCircle size={14} className="text-red-500 flex-shrink-0" />
          <span className="text-xs text-red-500">{error}</span>
        </motion.div>
      ))}
    </AnimatePresence>
  );
}