import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation, useSearchParams } from 'react-router-dom';
import { 
  Search,
  Users,
  Briefcase,
  FileText,
  DollarSign,
  GitBranch,
  X,
  SlidersHorizontal
} from 'lucide-react';
import { useAppNavigation } from '../routes/useNavigation';
import { useData } from '../context/DataContext';
import { useSearch } from '../hooks/useSearch';
import SearchAndFilter from '../components/ui/SearchAndFilter';

export default function SearchResults() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useAppNavigation();
  const { state } = useData();
  const initialQuery = searchParams.get('q') || '';

  const {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  } = useSearch({
    data: [], // We'll combine all searchable items
    searchFields: ['name', 'description', 'id'],
    filterConfig: {
      type: (item, values) => values.includes(item.type),
    },
  });

  const [results, setResults] = useState<Array<{
    id: string;
    type: 'client' | 'job' | 'report' | 'invoice' | 'variation';
    title: string;
    subtitle: string;
    date?: string;
    status?: string;
    amount?: number;
  }>>([]);

  useEffect(() => {
    const query = searchTerm.toLowerCase();
    if (!query) return;

    const searchResults = [];

    // Search clients
    searchResults.push(...state.clients
      .filter(client => 
        client.name.toLowerCase().includes(query) ||
        client.email.toLowerCase().includes(query)
      )
      .map(client => ({
        id: client.id,
        type: 'client' as const,
        title: client.name,
        subtitle: client.email,
        status: client.status
      })));

    // Search jobs
    searchResults.push(...state.jobs
      .filter(job => 
        job.name.toLowerCase().includes(query) ||
        job.jobId?.toLowerCase().includes(query) ||
        job.address.toLowerCase().includes(query)
      )
      .map(job => ({
        id: job.id,
        type: 'job' as const,
        title: job.name,
        subtitle: job.jobId || 'No Job ID',
        status: job.status,
        date: job.startDate.toString()
      })));

    // Search reports
    searchResults.push(...state.reports
      .filter(report => 
        report.reportId.toLowerCase().includes(query) ||
        report.jobName?.toLowerCase().includes(query)
      )
      .map(report => ({
        id: report.id,
        type: 'report' as const,
        title: report.reportId,
        subtitle: report.jobName || '',
        date: report.date.toString()
      })));

    // Search invoices
    searchResults.push(...state.invoices
      .filter(invoice => 
        invoice.invoiceNumber.toLowerCase().includes(query) ||
        invoice.reference?.toLowerCase().includes(query)
      )
      .map(invoice => ({
        id: invoice.id,
        type: 'invoice' as const,
        title: invoice.invoiceNumber,
        subtitle: invoice.reference || '',
        amount: invoice.total,
        status: invoice.status,
        date: invoice.date.toString()
      })));

    // Search variations
    searchResults.push(...state.variations
      .filter(variation => 
        variation.name.toLowerCase().includes(query) ||
        variation.description.toLowerCase().includes(query)
      )
      .map(variation => ({
        id: variation.id,
        type: 'variation' as const,
        title: variation.name,
        subtitle: variation.variationId,
        amount: variation.quotedAmount,
        status: variation.status,
        date: variation.dateAdded.toString()
      })));

    setResults(searchResults);
    setSearchParams({ q: query });
  }, [searchTerm, state, setSearchParams]);

  const handleResultClick = (result: typeof results[0]) => {
    switch (result.type) {
      case 'client':
        navigation.goToClient(result.id);
        break;
      case 'job':
        navigation.goToJob(result.id);
        break;
      case 'report':
        navigation.goToReport(result.id);
        break;
      case 'invoice':
        navigation.goToInvoice(result.id);
        break;
      case 'variation':
        navigation.goToVariation(result.id);
        break;
    }
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'client':
        return <Users size={20} className="text-violet-500" />;
      case 'job':
        return <Briefcase size={20} className="text-blue-500" />;
      case 'report':
        return <FileText size={20} className="text-teal-500" />;
      case 'invoice':
        return <DollarSign size={20} className="text-emerald-500" />;
      case 'variation':
        return <GitBranch size={20} className="text-amber-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Search Results</h1>
        <p className="text-gray-500">
          {results.length} results found for "{searchTerm}"
        </p>
      </div>

      <div className="space-y-6">
        {/* Search Input */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4">
          <SearchAndFilter
            searchPlaceholder="Search across all items..."
            searchValue={searchTerm}
            onSearchChange={handleSearchChange}
            filters={[
              {
                id: 'type',
                label: 'Type',
                options: [
                  { value: 'client', label: 'Clients' },
                  { value: 'job', label: 'Jobs' },
                  { value: 'report', label: 'Reports' },
                  { value: 'invoice', label: 'Invoices' },
                  { value: 'variation', label: 'Variations' },
                ],
              },
            ]}
            activeFilters={activeFilters}
            onFilterChange={handleFilterChange}
            showFilters={showFilters}
            onToggleFilters={toggleFilters}
          />
        </div>

        {/* Results */}
        <div className="space-y-4">
          {results.map((result, index) => (
            <motion.div
              key={`${result.type}-${result.id}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05 }}
              onClick={() => handleResultClick(result)}
              className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 hover:border-gray-200 cursor-pointer transition-all"
            >
              <div className="flex items-center gap-4">
                <div className="p-2 bg-gray-50 rounded-lg">
                  {getIcon(result.type)}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <h3 className="text-lg font-medium text-gray-900 truncate">
                      {result.title}
                    </h3>
                    <span className="text-sm text-gray-500 capitalize">
                      {result.type}
                    </span>
                  </div>
                  <p className="text-sm text-gray-500 truncate">{result.subtitle}</p>
                </div>
                {result.status && (
                  <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${
                    result.status === 'active' || result.status === 'completed'
                      ? 'bg-emerald-50 text-emerald-700'
                      : 'bg-gray-50 text-gray-700'
                  }`}>
                    {result.status}
                  </span>
                )}
                {result.amount && (
                  <span className="text-sm font-medium text-gray-900">
                    ${result.amount.toLocaleString()}
                  </span>
                )}
                {result.date && (
                  <span className="text-sm text-gray-500">
                    {new Date(result.date).toLocaleDateString()}
                  </span>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}