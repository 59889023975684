import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { UserPlus, Mail, X, Loader2, Building2, Users } from 'lucide-react';
import { useClients } from '../../hooks/useClients';
import { UserRole, ROLE_DESCRIPTIONS } from '../../hooks/usePermissions';
import { useAuth } from '../../context/AuthContext';

interface InviteUserModalProps {
  onClose: () => void;
  onSubmit: (data: { email: string; role: UserRole }) => Promise<void>;
}

export default function InviteUserModal({ onClose, onSubmit }: InviteUserModalProps) {
  const { company } = useAuth();
  const { clients } = useClients();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    role: 'user' as UserRole,
    clientId: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await onSubmit({
        email: formData.email,
        role: formData.role
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-xl shadow-lg max-w-md w-full p-6"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-violet-50 rounded-lg">
              <UserPlus className="text-violet-600" size={20} />
            </div>
            <h3 className="text-lg font-semibold text-gray-900">Invite User</h3>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X size={20} className="text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email Address
            </label>
            <div className="relative">
              <input
                type="email"
                value={formData.email}
                onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                required
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                placeholder="Enter email address"
              />
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Role
            </label>
            <select
              value={formData.role}
              onChange={e => setFormData(prev => ({ 
                ...prev, 
                role: e.target.value as UserRole,
                // Reset clientId if role is not client
                clientId: e.target.value !== 'client' ? '' : prev.clientId
              }))}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              {Object.entries(ROLE_DESCRIPTIONS)
                .filter(([role]) => role !== 'super_admin') // Exclude super_admin from options
                .map(([role, info]) => (
                  <option key={role} value={role}>
                    {info.name}
                  </option>
                ))
              }
            </select>
            <p className="mt-1 text-sm text-gray-500">
              {ROLE_DESCRIPTIONS[formData.role].description}
            </p>
          </div>

          {formData.role === 'client' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Client Company
              </label>
              <select
                value={formData.clientId}
                onChange={e => setFormData(prev => ({ ...prev, clientId: e.target.value }))}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
              >
                <option value="">Select client company</option>
                {clients
                  .filter(client => client.companyId === company?.id)
                  .map(client => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))
                }
              </select>
            </div>
          )}

          <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-violet-500 hover:bg-violet-600 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <>
                  <Loader2 className="animate-spin" size={16} />
                  <span>Sending...</span>
                </>
              ) : (
                <>
                  <UserPlus size={16} />
                  <span>Send Invitation</span>
                </>
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}