import { useState, useCallback } from 'react';

export interface ValidationRule {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  pattern?: RegExp;
  custom?: (value: any) => boolean;
  message: string;
}

export type ValidationRules<T> = {
  [K in keyof T]?: ValidationRule[];
};

export interface ValidationErrors<T> {
  [K in keyof T]?: string[];
}

export function useFormValidation<T extends Record<string, any>>(
  initialData: T,
  validationRules: ValidationRules<T>
) {
  const [formData, setFormData] = useState<T>(initialData);
  const [errors, setErrors] = useState<ValidationErrors<T>>({});
  const [touched, setTouched] = useState<Partial<Record<keyof T, boolean>>>({});

  const validateField = useCallback((name: keyof T, value: any) => {
    const fieldRules = validationRules[name];
    if (!fieldRules) return [];

    return fieldRules
      .map(rule => {
        if (rule.required && !value) {
          return rule.message;
        }
        if (rule.minLength && String(value).length < rule.minLength) {
          return rule.message;
        }
        if (rule.maxLength && String(value).length > rule.maxLength) {
          return rule.message;
        }
        if (rule.min && Number(value) < rule.min) {
          return rule.message;
        }
        if (rule.max && Number(value) > rule.max) {
          return rule.message;
        }
        if (rule.pattern && !rule.pattern.test(String(value))) {
          return rule.message;
        }
        if (rule.custom && !rule.custom(value)) {
          return rule.message;
        }
        return null;
      })
      .filter((message): message is string => message !== null);
  }, [validationRules]);

  const validateForm = useCallback(() => {
    const newErrors: ValidationErrors<T> = {};
    let isValid = true;

    Object.keys(validationRules).forEach(key => {
      const fieldName = key as keyof T;
      const fieldErrors = validateField(fieldName, formData[fieldName]);
      if (fieldErrors.length > 0) {
        newErrors[fieldName] = fieldErrors;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  }, [formData, validateField, validationRules]);

  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const fieldName = name as keyof T;
    
    setFormData(prev => ({
      ...prev,
      [fieldName]: ['number', 'range'].includes(e.target.type) 
        ? parseFloat(value) || 0 
        : value
    }));

    if (touched[fieldName]) {
      const fieldErrors = validateField(fieldName, value);
      setErrors(prev => ({
        ...prev,
        [fieldName]: fieldErrors
      }));
    }
  }, [touched, validateField]);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name } = e.target;
    const fieldName = name as keyof T;
    
    setTouched(prev => ({
      ...prev,
      [fieldName]: true
    }));

    const fieldErrors = validateField(fieldName, formData[fieldName]);
    setErrors(prev => ({
      ...prev,
      [fieldName]: fieldErrors
    }));
  }, [formData, validateField]);

  const reset = useCallback(() => {
    setFormData(initialData);
    setErrors({});
    setTouched({});
  }, [initialData]);

  return {
    formData,
    errors,
    touched,
    setFormData,
    handleChange,
    handleBlur,
    validateForm,
    reset
  };
}