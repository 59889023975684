import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Calendar, ChevronUp, ChevronDown,
  FileText, Edit2, Trash2, TrendingUp
} from 'lucide-react';
import { Report } from '../../types';
import ConfirmDialog from '../ui/ConfirmDialog';

interface ReportTableProps {
  reports: Report[];
  onSelect: (report: Report) => void;
  onEdit: (report: Report) => void;
  onDelete: (report: Report) => void;
}

type SortField = 'reportId' | 'date' | 'jobName' | 'progressPercentage';
type SortDirection = 'asc' | 'desc';

export default function ReportTable({ reports, onSelect, onEdit, onDelete }: ReportTableProps) {
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [reportToDelete, setReportToDelete] = useState<Report | null>(null);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedReports = [...reports].sort((a, b) => {
    let comparison = 0;
    
    switch (sortField) {
      case 'reportId':
        comparison = a.reportId.localeCompare(b.reportId);
        break;
      case 'date':
        comparison = new Date(a.date).getTime() - new Date(b.date).getTime();
        break;
      case 'jobName':
        comparison = (a.jobName || '').localeCompare(b.jobName || '');
        break;
      case 'progressPercentage':
        comparison = a.progressPercentage - b.progressPercentage;
        break;
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <ChevronUp className="opacity-0 group-hover:opacity-50" size={14} />;
    return sortDirection === 'asc' ? 
      <ChevronUp className="text-teal-500" size={14} /> : 
      <ChevronDown className="text-teal-500" size={14} />;
  };

  const getProgressColor = (percentage: number) => {
    if (percentage >= 75) return 'bg-emerald-500';
    if (percentage >= 50) return 'bg-blue-500';
    if (percentage >= 25) return 'bg-amber-500';
    return 'bg-gray-500';
  };

  const formatDate = (date: Date | string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleConfirmDelete = () => {
    if (reportToDelete) {
      onDelete(reportToDelete);
      setReportToDelete(null);
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-100">
                <th 
                  className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                  onClick={() => handleSort('reportId')}
                >
                  <div className="flex items-center gap-2">
                    <FileText size={16} className="text-gray-400" />
                    <span>Report</span>
                    <SortIcon field="reportId" />
                  </div>
                </th>
                <th 
                  className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                  onClick={() => handleSort('date')}
                >
                  <div className="flex items-center justify-center gap-2">
                    <Calendar size={16} className="text-gray-400" />
                    <span>Date</span>
                    <SortIcon field="date" />
                  </div>
                </th>
                <th 
                  className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                  onClick={() => handleSort('jobName')}
                >
                  <div className="flex items-center gap-2">
                    <FileText size={16} className="text-gray-400" />
                    <span>Job Name</span>
                    <SortIcon field="jobName" />
                  </div>
                </th>
                <th 
                  className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                  onClick={() => handleSort('progressPercentage')}
                >
                  <div className="flex items-center justify-center gap-2">
                    <TrendingUp size={16} className="text-gray-400" />
                    <span>Progress</span>
                    <SortIcon field="progressPercentage" />
                  </div>
                </th>
                <th className="text-right py-4 px-6 text-sm font-medium text-gray-500 w-24">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50">
              {sortedReports.map((report) => (
                <motion.tr
                  key={report.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="group hover:bg-gray-50 cursor-pointer transition-colors"
                  onClick={() => onSelect(report)}
                >
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-teal-50 rounded-lg">
                        <FileText size={16} className="text-teal-600" />
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">{report.reportId}</p>
                        <p className="text-[11px] text-gray-400">#{report.jobId || 'No Job ID'}</p>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-center text-sm text-gray-500">
                      {formatDate(report.date)}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div>
                      <p className="text-xs text-gray-500 mb-1">{report.clientName}</p>
                      <p className="text-sm font-medium text-gray-900 line-clamp-2">{report.jobName}</p>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex flex-col items-center gap-2">
                      <div className="flex items-center gap-2">
                        <div className="w-24 h-2 bg-gray-100 rounded-full overflow-hidden">
                          <div
                            className={`h-full ${getProgressColor(report.progressPercentage)}`}
                            style={{ width: `${report.progressPercentage}%` }}
                          />
                        </div>
                        <span className="text-sm font-medium text-gray-900">
                          {report.progressPercentage}%
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div 
                      className="flex items-center justify-end gap-2"
                      onClick={e => e.stopPropagation()}
                    >
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => onEdit(report)}
                        className="p-1 text-gray-400 hover:text-violet-600 hover:bg-violet-50 rounded-lg transition-colors"
                      >
                        <Edit2 size={16} />
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => setReportToDelete(report)}
                        className="p-1 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        <Trash2 size={16} />
                      </motion.button>
                    </div>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={!!reportToDelete}
        onClose={() => setReportToDelete(null)}
        onConfirm={handleConfirmDelete}
        title="Delete Report"
        message={`Are you sure you want to delete report ${reportToDelete?.reportId}? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </>
  );
}