import { useState, useMemo } from 'react';

interface UseSearchOptions<T> {
  data: T[];
  searchFields: (keyof T)[];
  filterConfig?: {
    [key: string]: (item: T, values: string[]) => boolean;
  };
}

export function useSearch<T>({ data, searchFields, filterConfig = {} }: UseSearchOptions<T>) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Record<string, string[]>>({});

  const filteredData = useMemo(() => {
    return data.filter(item => {
      // Apply search
      if (searchTerm) {
        const searchValue = searchTerm.toLowerCase();
        const matchesSearch = searchFields.some(field => {
          const value = item[field];
          return value && String(value).toLowerCase().includes(searchValue);
        });
        if (!matchesSearch) return false;
      }

      // Apply filters
      return Object.entries(activeFilters).every(([filterId, values]) => {
        if (!values.length) return true;
        const filterFn = filterConfig[filterId];
        return filterFn ? filterFn(item, values) : true;
      });
    });
  }, [data, searchTerm, searchFields, activeFilters, filterConfig]);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleFilterChange = (filterId: string, values: string[]) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterId]: values,
    }));
  };

  const toggleFilters = () => {
    setShowFilters(prev => !prev);
  };

  return {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  };
}