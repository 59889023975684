import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, TrendingUp, AlertCircle, ArrowUpRight } from 'lucide-react';
import FinancialChart from './FinancialChart';

interface FinancialOverviewProps {
  contractAmount: number;
  invoicedToDate: number;
  approvedVariations: number;
  pendingVariations: number;
  job?: any;
  invoices?: any[];
  variations?: any[];
}

export default function FinancialOverview({
  contractAmount = 0,
  invoicedToDate = 0,
  approvedVariations = 0,
  pendingVariations = 0,
  job,
  invoices = [],
  variations = []
}: FinancialOverviewProps) {
  const totalValue = contractAmount + approvedVariations;
  const remainingValue = totalValue - invoicedToDate;
  const progressPercentage = totalValue > 0 ? Math.round((invoicedToDate / totalValue) * 100) : 0;

  // Calculate monthly change (mock data - replace with actual calculation)
  const monthlyChange = 15.5;

  const metrics = [
    {
      label: 'Original Contract',
      value: contractAmount,
      icon: DollarSign,
      color: 'emerald',
      trend: '+12.5%',
      subtitle: 'Base contract value'
    },
    {
      label: 'Remaining',
      value: remainingValue,
      icon: TrendingUp,
      color: 'blue',
      trend: '-8.3%',
      subtitle: 'To be invoiced'
    },
    {
      label: 'Variations',
      value: approvedVariations + pendingVariations,
      icon: AlertCircle,
      color: 'violet',
      trend: '+5.2%',
      subtitle: 'Total approved & pending'
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-emerald-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Financial Overview</h2>
              <p className="text-sm text-gray-500 mt-1">Project financial summary and tracking</p>
            </div>
          </div>
          <div className="p-2 bg-emerald-50 rounded-xl">
            <DollarSign className="text-emerald-500" size={24} />
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-8">
          {/* Metric Cards */}
          <div className="grid md:grid-cols-3 gap-6">
            <FinancialCard
              icon={<DollarSign className="text-emerald-500" size={24} />}
              title="Total Contract Value"
              amount={totalValue}
              subtitle="Including approved variations"
            />

            <FinancialCard
              icon={<TrendingUp className="text-blue-500" size={24} />}
              title="Invoiced to Date"
              amount={invoicedToDate}
              subtitle={`${monthlyChange > 0 ? '+' : ''}${monthlyChange.toFixed(1)}% this month`}
              trend={monthlyChange}
            />

            <FinancialCard
              icon={<AlertCircle className="text-amber-500" size={24} />}
              title="Pending Variations"
              amount={pendingVariations}
              subtitle={`${variations.filter(v => v.status === 'pending_approval').length} awaiting approval`}
            />
          </div>

          {/* Charts and Summary */}
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Financial Progress</h3>
                <FinancialChart
                  contractAmount={contractAmount}
                  invoicedToDate={invoicedToDate}
                  approvedVariations={approvedVariations}
                  pendingVariations={pendingVariations}
                />
              </div>

              {/* Summary Cards */}
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                {metrics.map((metric, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
                    className={`bg-${metric.color}-50 rounded-xl p-4 border border-${metric.color}-100 cursor-pointer group`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <motion.div 
                        className={`p-2 rounded-lg bg-${metric.color}-100/50 group-hover:bg-${metric.color}-100 transition-colors`}
                        whileHover={{ scale: 1.1 }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      >
                        <metric.icon className={`text-${metric.color}-500`} size={18} />
                      </motion.div>
                      <motion.div 
                        className={`flex items-center gap-1 text-xs font-medium ${
                          metric.trend.startsWith('+') ? 'text-emerald-500' : 'text-red-500'
                        }`}
                        initial={{ opacity: 0.5 }}
                        whileHover={{ opacity: 1 }}
                      >
                        {metric.trend}
                        <ArrowUpRight size={14} />
                      </motion.div>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-600 group-hover:text-gray-900 transition-colors line-clamp-1">
                        {metric.label}
                      </p>
                      <motion.p 
                        className="text-lg font-bold text-gray-900 truncate"
                        initial={{ scale: 1 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      >
                        ${metric.value.toLocaleString()}
                      </motion.p>
                      <p className="text-xs text-gray-500 group-hover:text-gray-600 transition-colors line-clamp-1">
                        {metric.subtitle}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Summary</h3>
              <div className="space-y-4">
                <SummaryRow label="Original Contract" amount={contractAmount} />
                <SummaryRow label="Approved Variations" amount={approvedVariations} positive />
                <SummaryRow label="Total Contract Value" amount={totalValue} total />
                <SummaryRow label="Invoiced to Date" amount={invoicedToDate} />
                <SummaryRow label="Remaining to Invoice" amount={remainingValue} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface FinancialCardProps {
  icon: React.ReactNode;
  title: string;
  amount: number;
  subtitle: string;
  trend?: number;
}

function FinancialCard({ icon, title, amount, subtitle, trend }: FinancialCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
      className="bg-white rounded-xl p-6 border border-gray-100 hover:border-gray-200 transition-all"
    >
      <div className="flex items-center justify-between mb-4">
        {icon}
        {trend !== undefined && (
          <span className={`text-sm ${trend >= 0 ? 'text-emerald-500' : 'text-red-500'}`}>
            {trend > 0 ? '+' : ''}{trend}%
          </span>
        )}
      </div>
      <div>
        <h3 className="text-sm text-gray-500 mb-1">{title}</h3>
        <p className="text-2xl font-bold text-gray-900">${amount.toLocaleString()}</p>
        <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
      </div>
    </motion.div>
  );
}

interface SummaryRowProps {
  label: string;
  amount: number;
  positive?: boolean;
  total?: boolean;
}

function SummaryRow({ label, amount, positive, total }: SummaryRowProps) {
  return (
    <motion.div 
      className={`flex justify-between items-center py-2 ${total ? 'border-t border-gray-200 pt-4' : ''}`}
      initial={{ opacity: 0, x: -20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      whileHover={{ x: 5 }}
      transition={{ duration: 0.3 }}
    >
      <span className={`text-sm ${total ? 'font-semibold' : ''}`}>{label}</span>
      <motion.span 
        className={`font-semibold ${positive ? 'text-emerald-500' : ''} ${total ? 'text-lg' : ''}`}
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        ${amount.toLocaleString()}
      </motion.span>
    </motion.div>
  );
}