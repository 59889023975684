import { ROUTES } from './paths';

// Type for route parameters
type RouteParams = {
  [key: string]: string | number;
};

/**
 * Generate a route path by replacing parameters in the route pattern
 */
export function generatePath(route: string, params?: RouteParams): string {
  if (!params) return route;
  
  return Object.entries(params).reduce((path, [key, value]) => {
    return path.replace(`:${key}`, String(value));
  }, route);
}

/**
 * Helper functions for generating specific route paths
 */
export const routePaths = {
  // Companies
  companyView: (companyId: string) => 
    generatePath(ROUTES.COMPANY_VIEW, { companyId }),
  companySettings: (companyId: string) => 
    generatePath(ROUTES.COMPANY_SETTINGS, { companyId }),

  // Clients
  clientView: (clientId: string) => 
    generatePath(ROUTES.CLIENT_VIEW, { clientId }),
  clientJobs: (clientId: string) => 
    generatePath(ROUTES.CLIENT_JOBS, { clientId }),

  // Jobs
  jobView: (jobId: string) => 
    generatePath(ROUTES.JOB_VIEW, { jobId }),
  jobReports: (jobId: string) => 
    generatePath(ROUTES.JOB_REPORTS, { jobId }),

  // Reports
  reportView: (reportId: string) => 
    generatePath(ROUTES.REPORT_VIEW, { reportId }),
  sharedReport: (reportId: string) => 
    generatePath(ROUTES.SHARED_REPORT, { reportId }),

  // Invoices
  invoiceView: (invoiceId: string) => 
    generatePath(ROUTES.INVOICE_VIEW, { invoiceId }),

  // Variations
  variationView: (variationId: string) => 
    generatePath(ROUTES.VARIATION_VIEW, { variationId }),

  // Tasks
  taskView: (taskId: string) =>
    generatePath(ROUTES.TASK_VIEW, { taskId }),
};