import React from 'react';
import { motion } from 'framer-motion';
import { Sun, Moon, Globe, Bell } from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';
import { usePermissions } from '../../hooks/usePermissions';

export default function GeneralSettings() {
  const { theme, setTheme } = useTheme();
  const { canEditSettings } = usePermissions();
  const [language, setLanguage] = React.useState('en');
  const [timezone, setTimezone] = React.useState('UTC');
  const [notifications, setNotifications] = React.useState({
    email: {
      reports: true,
      invoices: true,
      payments: true,
    },
    push: {
      reports: true,
      invoices: true,
      payments: true,
    }
  });

  return (
    <div className="space-y-6">
      {/* Theme Settings */}
      <div className="bg-card rounded-xl shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Appearance</h2>
        <div className="grid grid-cols-3 gap-4">
          <ThemeOption
            value="light"
            selected={theme === 'light'}
            onSelect={() => canEditSettings && setTheme('light')}
            icon={<Sun size={20} />}
            label="Light"
            disabled={!canEditSettings}
          />
          <ThemeOption
            value="dark"
            selected={theme === 'dark'}
            onSelect={() => canEditSettings && setTheme('dark')}
            icon={<Moon size={20} />}
            label="Dark"
            disabled={!canEditSettings}
          />
          <ThemeOption
            value="system"
            selected={theme === 'system'}
            onSelect={() => canEditSettings && setTheme('system')}
            icon={<Globe size={20} />}
            label="System"
            disabled={!canEditSettings}
          />
        </div>
      </div>

      {/* Language & Timezone */}
      <div className="bg-card rounded-xl shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Regional</h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-2">
              Language
            </label>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              disabled={!canEditSettings}
              className="w-full px-3 py-2 bg-background border rounded-lg focus:ring-2 focus:ring-primary disabled:bg-gray-100 disabled:cursor-not-allowed"
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fr">Français</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">
              Timezone
            </label>
            <select
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              disabled={!canEditSettings}
              className="w-full px-3 py-2 bg-background border rounded-lg focus:ring-2 focus:ring-primary disabled:bg-gray-100 disabled:cursor-not-allowed"
            >
              <option value="UTC">UTC</option>
              <option value="America/New_York">Eastern Time</option>
              <option value="America/Los_Angeles">Pacific Time</option>
              <option value="Europe/London">London</option>
              <option value="Asia/Tokyo">Tokyo</option>
            </select>
          </div>
        </div>
      </div>

      {/* Notifications */}
      <div className="bg-card rounded-xl shadow-sm p-6">
        <div className="flex items-center gap-3 mb-6">
          <Bell size={20} className="text-muted-foreground" />
          <h2 className="text-lg font-semibold">Notifications</h2>
        </div>

        <div className="space-y-6">
          {/* Email Notifications */}
          <div>
            <h3 className="text-sm font-medium mb-4">Email Notifications</h3>
            <div className="space-y-4">
              {Object.entries(notifications.email).map(([key, enabled]) => (
                <label key={key} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={(e) => setNotifications(prev => ({
                      ...prev,
                      email: {
                        ...prev.email,
                        [key]: e.target.checked 
                      }
                    }))}
                    disabled={!canEditSettings}
                    className="w-4 h-4 border rounded focus:ring-primary disabled:bg-gray-100 disabled:cursor-not-allowed"
                  />
                  <span className="ml-3 text-sm">
                    {key.charAt(0).toUpperCase() + key.slice(1)} updates
                  </span>
                </label>
              ))}
            </div>
          </div>

          {/* Push Notifications */}
          <div>
            <h3 className="text-sm font-medium mb-4">Push Notifications</h3>
            <div className="space-y-4">
              {Object.entries(notifications.push).map(([key, enabled]) => (
                <label key={key} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={(e) => setNotifications(prev => ({
                      ...prev,
                      push: {
                        ...prev.push,
                        [key]: e.target.checked 
                      }
                    }))}
                    disabled={!canEditSettings}
                    className="w-4 h-4 border rounded focus:ring-primary disabled:bg-gray-100 disabled:cursor-not-allowed"
                  />
                  <span className="ml-3 text-sm">
                    {key.charAt(0).toUpperCase() + key.slice(1)} updates
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Save Button */}
      {canEditSettings && (
        <div className="flex justify-end">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="px-6 py-2 bg-primary text-primary-foreground rounded-lg hover:bg-primary/90 transition-colors"
          >
            Save Changes
          </motion.button>
        </div>
      )}
    </div>
  );
}

interface ThemeOptionProps {
  value: string;
  selected: boolean;
  onSelect: () => void;
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
}

function ThemeOption({ value, selected, onSelect, icon, label, disabled }: ThemeOptionProps) {
  return (
    <motion.button
      whileHover={!disabled ? { scale: 1.02 } : undefined}
      whileTap={!disabled ? { scale: 0.98 } : undefined}
      onClick={onSelect}
      disabled={disabled}
      className={`
        flex flex-col items-center gap-3 p-4 rounded-lg border-2 transition-all
        ${selected 
          ? 'border-primary bg-accent' 
          : 'border-muted hover:border-input'
        }
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
      `}
    >
      <div className={`p-2 rounded-lg ${selected ? 'bg-primary text-primary-foreground' : 'bg-muted text-muted-foreground'}`}>
        {icon}
      </div>
      <span className={`text-sm font-medium ${selected ? 'text-foreground' : 'text-muted-foreground'}`}>
        {label}
      </span>
    </motion.button>
  );
}