import { Permission, PermissionCategory, UserRole, Role } from '../types/auth';

// Define all available permissions
export const PERMISSIONS = {
  MANAGE_COMPANY: {
    id: 'manage_company',
    name: 'Company Management',
    description: 'Manage company profile and settings',
    category: 'company',
    actions: ['view', 'edit']
  },
  MANAGE_USERS: {
    id: 'manage_users',
    name: 'User Management',
    description: 'Manage users and their access',
    category: 'users',
    actions: ['view', 'create', 'edit', 'delete']
  },
  MANAGE_ROLES: {
    id: 'manage_roles',
    name: 'Role Management',
    description: 'Manage user roles and permissions',
    category: 'users',
    actions: ['view', 'create', 'edit', 'delete']
  },
  MANAGE_CLIENTS: {
    id: 'manage_clients',
    name: 'Client Management',
    description: 'Manage client information and access',
    category: 'clients',
    actions: ['view', 'create', 'edit', 'delete']
  },
  MANAGE_JOBS: {
    id: 'manage_jobs',
    name: 'Job Management',
    description: 'Manage construction jobs and projects',
    category: 'jobs',
    actions: ['view', 'create', 'edit', 'delete', 'approve']
  },
  MANAGE_REPORTS: {
    id: 'manage_reports',
    name: 'Report Management',
    description: 'Manage progress reports',
    category: 'reports',
    actions: ['view', 'create', 'edit', 'delete', 'approve']
  },
  MANAGE_INVOICES: {
    id: 'manage_invoices',
    name: 'Invoice Management',
    description: 'Manage invoices and payments',
    category: 'invoices',
    actions: ['view', 'create', 'edit', 'delete', 'approve']
  },
  MANAGE_VARIATIONS: {
    id: 'manage_variations',
    name: 'Variation Management',
    description: 'Manage project variations',
    category: 'variations',
    actions: ['view', 'create', 'edit', 'delete', 'approve']
  },
  MANAGE_TASKS: {
    id: 'manage_tasks',
    name: 'Task Management',
    description: 'Manage and assign tasks',
    category: 'tasks',
    actions: ['view', 'create', 'edit', 'delete', 'assign']
  },
  MANAGE_SETTINGS: {
    id: 'manage_settings',
    name: 'Settings Management',
    description: 'Manage application settings',
    category: 'settings',
    actions: ['view', 'edit']
  }
} as const;

// Define system roles with their default permissions
export const SYSTEM_ROLES: Record<UserRole, Role> = {
  super_admin: {
    id: 'super_admin',
    name: 'Super Admin',
    description: 'Full access to all features and can manage all organizations',
    isSystem: true,
    permissions: Object.values(PERMISSIONS).map(p => ({
      permissionId: p.id,
      actions: p.actions
    }))
  },
  org_admin: {
    id: 'org_admin',
    name: 'Organization Admin',
    description: 'Full access to organization features and can manage users',
    isSystem: true,
    permissions: Object.values(PERMISSIONS).map(p => ({
      permissionId: p.id,
      actions: p.actions
    }))
  },
  admin: {
    id: 'admin',
    name: 'Admin',
    description: 'Can manage most features but cannot modify organization settings',
    isSystem: true,
    permissions: Object.values(PERMISSIONS)
      .filter(p => !['manage_company', 'manage_roles'].includes(p.id))
      .map(p => ({
        permissionId: p.id,
        actions: p.actions
      }))
  },
  user: {
    id: 'user',
    name: 'User',
    description: 'Can view and manage assigned resources',
    isSystem: true,
    permissions: [
      {
        permissionId: PERMISSIONS.MANAGE_TASKS.id,
        actions: ['view', 'create', 'edit']
      },
      {
        permissionId: PERMISSIONS.MANAGE_JOBS.id,
        actions: ['view']
      },
      {
        permissionId: PERMISSIONS.MANAGE_REPORTS.id,
        actions: ['view', 'create']
      }
    ]
  },
  client: {
    id: 'client',
    name: 'Client',
    description: 'Can view their own reports and invoices',
    isSystem: true,
    permissions: [
      {
        permissionId: PERMISSIONS.MANAGE_REPORTS.id,
        actions: ['view']
      },
      {
        permissionId: PERMISSIONS.MANAGE_INVOICES.id,
        actions: ['view']
      }
    ]
  }
};

// Role descriptions for UI display
export const ROLE_DESCRIPTIONS = {
  super_admin: {
    name: 'Super Admin',
    description: 'Full access to all features and can manage all organizations'
  },
  org_admin: {
    name: 'Organization Admin',
    description: 'Full access to organization features and can manage users'
  },
  admin: {
    name: 'Admin',
    description: 'Can manage most features but cannot modify organization settings'
  },
  user: {
    name: 'User',
    description: 'Can view and manage assigned resources'
  },
  client: {
    name: 'Client',
    description: 'Can view their own reports and invoices'
  }
};

// Permission categories for grouping in UI
export const PERMISSION_CATEGORIES: Record<PermissionCategory, string> = {
  company: 'Company',
  users: 'Users & Access',
  clients: 'Clients',
  jobs: 'Jobs',
  reports: 'Reports',
  invoices: 'Invoices',
  variations: 'Variations',
  tasks: 'Tasks',
  settings: 'Settings'
};