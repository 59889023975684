import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowUpRight, Calendar, Mail, Phone, Globe,
  ChevronUp, ChevronDown, CheckCircle2, AlertCircle,
  Users, Building2, MapPin
} from 'lucide-react';
import { Client } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface ClientTableProps {
  clients: Client[];
  onSelect: (client: Client) => void;
  onEdit: (client: Client) => void;
  onDelete: (client: Client) => void;
}

type SortField = 'name' | 'email' | 'phone' | 'status' | 'createdAt';
type SortDirection = 'asc' | 'desc';

export default function ClientTable({ clients, onSelect, onEdit, onDelete }: ClientTableProps) {
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedClients = [...clients].sort((a, b) => {
    let comparison = 0;
    
    switch (sortField) {
      case 'name':
        comparison = a.name.localeCompare(b.name);
        break;
      case 'email':
        comparison = a.email.localeCompare(b.email);
        break;
      case 'phone':
        comparison = a.phone.localeCompare(b.phone);
        break;
      case 'status':
        comparison = (a.status || 'active').localeCompare(b.status || 'active');
        break;
      case 'createdAt':
        comparison = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        break;
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <ChevronUp className="opacity-0 group-hover:opacity-50" size={14} />;
    return sortDirection === 'asc' ? 
      <ChevronUp className="text-violet-500" size={14} /> : 
      <ChevronDown className="text-violet-500" size={14} />;
  };

  const getStatusColor = (status: string = 'active') => {
    switch (status) {
      case 'active':
        return 'bg-emerald-50 text-emerald-700 border-emerald-200';
      case 'inactive':
        return 'bg-gray-50 text-gray-700 border-gray-200';
      default:
        return 'bg-gray-50 text-gray-700 border-gray-200';
    }
  };

  const getStatusIcon = (status: string = 'active') => {
    switch (status) {
      case 'active':
        return <CheckCircle2 size={14} className="text-emerald-500" />;
      case 'inactive':
        return <AlertCircle size={14} className="text-gray-500" />;
      default:
        return <AlertCircle size={14} className="text-gray-500" />;
    }
  };

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50">
              <th 
                className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('name')}
              >
                <div className="flex items-center gap-2">
                  <Users size={16} className="text-gray-400" />
                  <span>Client</span>
                  <SortIcon field="name" />
                </div>
              </th>
              <th className="text-left py-4 px-6 text-sm font-medium text-gray-500">
                <div className="flex items-center gap-2">
                  <Building2 size={16} className="text-gray-400" />
                  <span>Details</span>
                </div>
              </th>
              <th 
                className="text-left py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('email')}
              >
                <div className="flex items-center gap-2">
                  <Mail size={16} className="text-gray-400" />
                  <span>Contact</span>
                  <SortIcon field="email" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('status')}
              >
                <div className="flex items-center justify-center gap-2">
                  <CheckCircle2 size={16} className="text-gray-400" />
                  <span>Status</span>
                  <SortIcon field="status" />
                </div>
              </th>
              <th 
                className="text-center py-4 px-6 text-sm font-medium text-gray-500 cursor-pointer group"
                onClick={() => handleSort('createdAt')}
              >
                <div className="flex items-center justify-center gap-2">
                  <Calendar size={16} className="text-gray-400" />
                  <span>Member Since</span>
                  <SortIcon field="createdAt" />
                </div>
              </th>
              <th className="text-right py-4 px-6 text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50">
            {sortedClients.map((client) => {
              const isHovered = hoveredRow === client.id;
              
              return (
                <motion.tr
                  key={client.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="group hover:bg-gray-50 cursor-pointer transition-colors"
                  onClick={() => onSelect(client)}
                  onMouseEnter={() => setHoveredRow(client.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      {client.logo ? (
                        <img 
                          src={client.logo} 
                          alt={client.name}
                          className="w-10 h-10 rounded-lg object-cover"
                        />
                      ) : (
                        <div className={`p-2 rounded-lg transition-colors ${
                          isHovered ? 'bg-violet-100' : 'bg-violet-50'
                        }`}>
                          <Users size={20} className="text-violet-600" />
                        </div>
                      )}
                      <div>
                        <p className="font-medium text-gray-900">{client.name}</p>
                        <p className="text-sm text-gray-500">
                          {client.clientId ? `#${client.clientId}` : 'No Client ID'}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="max-w-xs space-y-1">
                      {client.website && (
                        <div className="flex items-center gap-2 text-sm">
                          <Globe size={14} className="text-gray-400" />
                          <a 
                            href={client.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => e.stopPropagation()}
                            className="text-violet-600 hover:underline"
                          >
                            {new URL(client.website).hostname}
                          </a>
                        </div>
                      )}
                      {client.address && (
                        <div className="flex items-start gap-2 text-sm">
                          <MapPin size={14} className="text-gray-400 mt-1 flex-shrink-0" />
                          <span className="text-gray-600 line-clamp-2">{client.address}</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2 text-sm">
                        <Mail size={14} className="text-gray-400" />
                        <a 
                          href={`mailto:${client.email}`}
                          onClick={e => e.stopPropagation()}
                          className="text-violet-600 hover:underline"
                        >
                          {client.email}
                        </a>
                      </div>
                      <div className="flex items-center gap-2 text-sm">
                        <Phone size={14} className="text-gray-400" />
                        <a 
                          href={`tel:${client.phone}`}
                          onClick={e => e.stopPropagation()}
                          className="text-violet-600 hover:underline"
                        >
                          {client.phone}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-center">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium border ${
                        getStatusColor(client.status)
                      }`}>
                        {getStatusIcon(client.status)}
                        {(client.status || 'active').charAt(0).toUpperCase() + (client.status || 'active').slice(1)}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-center text-sm text-gray-500">
                      {formatDate(client.createdAt)}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex items-center justify-end gap-2">
                      <ActionMenu
                        onEdit={() => onEdit(client)}
                        onDelete={() => onDelete(client)}
                      />
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelect(client);
                        }}
                        className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                      >
                        <ArrowUpRight size={16} />
                      </button>
                    </div>
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}