import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  LayoutDashboard,
  Users,
  Briefcase,
  FileText,
  Settings,
  ChevronLeft,
  DollarSign,
  GitBranch,
  Hammer,
  ClipboardList,
  LayoutGrid,
  Calendar
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { usePermissions } from '../../hooks/usePermissions';
import { useBranding } from '../../context/BrandingContext';
import { ROUTE_PATHS } from '../../routes/config';

interface AppSidebarProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export default function AppSidebar({ collapsed, onCollapse }: AppSidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, company } = useAuth();
  const { 
    isClient, 
    canViewClients, 
    canViewJobs, 
    canViewReports, 
    canViewInvoices, 
    canViewVariations,
    canViewTasks,
    canViewSettings 
  } = usePermissions();
  const { primaryColor } = useBranding();
  const [isHovered, setIsHovered] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const getNavItems = () => {
    const items = [
      { 
        id: 'dashboard', 
        label: 'Dashboard', 
        icon: LayoutDashboard,
        path: ROUTE_PATHS.DASHBOARD,
        onClick: () => navigate(ROUTE_PATHS.DASHBOARD),
        show: !isClient
      }
    ];

    if (canViewTasks) {
      items.push(
        { 
          id: 'tasks', 
          label: 'Tasks', 
          icon: ClipboardList,
          path: ROUTE_PATHS.TASKS,
          onClick: () => navigate(ROUTE_PATHS.TASKS),
          show: true,
          children: [
            {
              id: 'task-board',
              label: 'Task Board',
              icon: LayoutGrid,
              path: ROUTE_PATHS.TASK_BOARD,
              onClick: () => navigate(ROUTE_PATHS.TASK_BOARD)
            },
            {
              id: 'task-calendar',
              label: 'Task Calendar',
              icon: Calendar,
              path: ROUTE_PATHS.TASK_CALENDAR,
              onClick: () => navigate(ROUTE_PATHS.TASK_CALENDAR)
            }
          ]
        }
      );
    }

    if (canViewClients) {
      items.push({ 
        id: 'clients', 
        label: 'Clients', 
        icon: Users,
        path: ROUTE_PATHS.CLIENTS,
        onClick: () => navigate(ROUTE_PATHS.CLIENTS),
        show: true
      });
    }

    if (canViewJobs) {
      items.push({ 
        id: 'jobs', 
        label: 'Jobs', 
        icon: Briefcase,
        path: ROUTE_PATHS.JOBS,
        onClick: () => navigate(ROUTE_PATHS.JOBS),
        show: true
      });
    }

    if (canViewReports) {
      items.push({ 
        id: 'reports', 
        label: 'Reports', 
        icon: FileText,
        path: ROUTE_PATHS.REPORTS,
        onClick: () => navigate(ROUTE_PATHS.REPORTS),
        show: true
      });
    }

    if (canViewInvoices) {
      items.push({
        id: 'invoices',
        label: 'Invoices',
        icon: DollarSign,
        path: ROUTE_PATHS.INVOICES,
        onClick: () => navigate(ROUTE_PATHS.INVOICES),
        show: true
      });
    }

    if (canViewVariations) {
      items.push({ 
        id: 'variations', 
        label: 'Variations', 
        icon: GitBranch,
        path: ROUTE_PATHS.VARIATIONS,
        onClick: () => navigate(ROUTE_PATHS.VARIATIONS),
        show: true
      });
    }

    if (canViewSettings) {
      items.push({ 
        id: 'settings', 
        label: 'Settings', 
        icon: Settings,
        path: ROUTE_PATHS.SETTINGS,
        onClick: () => navigate(ROUTE_PATHS.SETTINGS),
        show: true
      });
    }

    return items.filter(item => item.show);
  };

  const navItems = getNavItems();

  const isPathActive = (path: string) => {
    // Exact match for dashboard
    if (path === ROUTE_PATHS.DASHBOARD) {
      return location.pathname === path;
    }
    // For other routes, check if the current path starts with the nav item path
    return location.pathname.startsWith(path);
  };

  // Handle click outside to collapse sidebar on mobile
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setIsHovered(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <motion.div 
      ref={sidebarRef}
      className="fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white border-r border-gray-200 flex flex-col shadow-sm z-30"
      animate={{ width: collapsed ? '5rem' : '16rem' }}
      transition={{ duration: 0.2 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Brand */}
      <div className="p-4 border-b border-gray-100">
        <div className="flex items-center gap-3">
          {user?.role === 'client' ? (
            <div className="w-8 h-8 bg-violet-100 rounded-lg flex items-center justify-center">
              <Users className="text-violet-600" size={20} />
            </div>
          ) : company?.logo ? (
            <img
              src={company.logo}
              alt={company.name}
              className="w-8 h-8 rounded-lg object-cover"
            />
          ) : (
            <div className="w-8 h-8 bg-gray-900 rounded-lg flex items-center justify-center flex-shrink-0">
              <Hammer className="text-white" size={20} />
            </div>
          )}

          <AnimatePresence>
            {!collapsed && (
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                className="flex-1 min-w-0"
              >
                <h1 className="font-semibold text-gray-900 truncate">
                  {user?.role === 'client' ? 'Client Portal' : company?.name}
                </h1>
                <p className="text-xs text-gray-500 truncate">
                  {user?.role === 'client' ? 'Welcome back' : 'Company Portal'}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Navigation */}
      <nav className="flex-1 overflow-y-auto p-4">
        <div className="space-y-1">
          {navItems.map((item) => {
            const Icon = item.icon;
            const isActive = isPathActive(item.path);

            return (
              <div key={item.id}>
                <button
                  onClick={item.onClick}
                  className={`
                    w-full flex items-center gap-3 px-4 py-3 rounded-lg 
                    transition-all duration-200
                    ${isActive 
                      ? 'text-white shadow-sm'
                      : 'text-gray-600 hover:bg-gray-100'
                    }
                  `}
                  style={{
                    backgroundColor: isActive ? primaryColor : undefined
                  }}
                >
                  <Icon size={20} />
                  <AnimatePresence>
                    {!collapsed && (
                      <motion.span
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                        className="flex-1 text-left whitespace-nowrap"
                      >
                        {item.label}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </button>

                {/* Sub-items */}
                {!collapsed && item.children?.map(child => {
                  const ChildIcon = child.icon;
                  const isChildActive = isPathActive(child.path);

                  return (
                    <button
                      key={child.id}
                      onClick={child.onClick}
                      className={`
                        w-full flex items-center gap-3 px-4 py-2 ml-6 rounded-lg 
                        transition-all duration-200 text-sm
                        ${isChildActive 
                          ? 'text-white shadow-sm'
                          : 'text-gray-600 hover:bg-gray-100'
                        }
                      `}
                      style={{
                        backgroundColor: isChildActive ? primaryColor : undefined
                      }}
                    >
                      <ChildIcon size={16} />
                      <span className="flex-1 text-left whitespace-nowrap">
                        {child.label}
                      </span>
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </nav>

      {/* Collapse Button */}
      <div className="p-4 border-t border-gray-100">
        <button
          onClick={() => onCollapse(!collapsed)}
          className="w-full flex items-center gap-3 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
        >
          <ChevronLeft
            size={20}
            className={`transform transition-transform ${collapsed ? 'rotate-180' : ''}`}
          />
          <AnimatePresence>
            {!collapsed && (
              <motion.span
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
              >
                Collapse
              </motion.span>
            )}
          </AnimatePresence>
        </button>
      </div>
    </motion.div>
  );
}