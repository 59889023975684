import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Shield, 
  Users, 
  Plus,
  ChevronDown,
  CheckCircle2,
  X,
  Edit2,
  Trash2,
  AlertTriangle,
  Lock,
  Search,
  Building2
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '../../constants/permissions';
import ConfirmDialog from '../ui/ConfirmDialog';
import RoleFormModal from './RoleFormModal';

interface RolePermission {
  id: string;
  name: string;
  description: string;
  actions: string[];
}

interface CustomRole {
  id: string;
  name: string;
  description: string;
  permissions: RolePermission[];
  userCount?: number;
}

export default function RoleManagement() {
  const { user } = useAuth();
  const { addNotification } = useNotification();
  const { canManageRoles } = usePermissions();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddRole, setShowAddRole] = useState(false);
  const [editingRole, setEditingRole] = useState<CustomRole | null>(null);
  const [deleteRole, setDeleteRole] = useState<CustomRole | null>(null);
  const [expandedRole, setExpandedRole] = useState<string | null>(null);
  const [customRoles, setCustomRoles] = useState<CustomRole[]>([
    {
      id: 'project-manager',
      name: 'Project Manager',
      description: 'Can manage projects and their associated resources',
      permissions: [
        {
          ...PERMISSIONS.MANAGE_JOBS,
          actions: ['view', 'create', 'edit', 'delete']
        },
        {
          ...PERMISSIONS.MANAGE_REPORTS,
          actions: ['view', 'create', 'edit']
        },
        {
          ...PERMISSIONS.MANAGE_TASKS,
          actions: ['view', 'create', 'edit', 'assign']
        }
      ],
      userCount: 5
    },
    {
      id: 'finance-manager',
      name: 'Finance Manager',
      description: 'Manages financial aspects and invoicing',
      permissions: [
        {
          ...PERMISSIONS.MANAGE_INVOICES,
          actions: ['view', 'create', 'edit', 'delete']
        }
      ],
      userCount: 3
    }
  ]);

  const filteredRoles = customRoles.filter(role => 
    role.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    role.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddRole = async (role: CustomRole) => {
    if (!canManageRoles) {
      addNotification('error', 'You do not have permission to add roles');
      return;
    }

    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Add the new role to the state
      setCustomRoles(prev => [...prev, { ...role, userCount: 0 }]);
      
      addNotification('success', 'Role added successfully');
      setShowAddRole(false);
    } catch (error) {
      addNotification('error', 'Failed to add role');
    }
  };

  const handleUpdateRole = async (role: CustomRole) => {
    if (!canManageRoles) {
      addNotification('error', 'You do not have permission to update roles');
      return;
    }

    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Update the role in the state
      setCustomRoles(prev => prev.map(r => 
        r.id === role.id ? { ...role, userCount: r.userCount } : r
      ));
      
      addNotification('success', 'Role updated successfully');
      setEditingRole(null);
    } catch (error) {
      addNotification('error', 'Failed to update role');
    }
  };

  const handleDeleteRole = async () => {
    if (!deleteRole || !canManageRoles) {
      addNotification('error', 'You do not have permission to delete roles');
      return;
    }

    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Remove the role from the state
      setCustomRoles(prev => prev.filter(r => r.id !== deleteRole.id));
      
      addNotification('success', 'Role deleted successfully');
      setDeleteRole(null);
    } catch (error) {
      addNotification('error', 'Failed to delete role');
    }
  };

  if (!canManageRoles) {
    return (
      <div className="text-center py-12">
        <Shield className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Access</h3>
        <p className="mt-1 text-sm text-gray-500">
          You don't have permission to manage roles.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-violet-50 rounded-lg">
            <Shield className="text-violet-600" size={20} />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Role Management</h2>
            <p className="text-sm text-gray-500">Define and manage user roles and their permissions</p>
          </div>
        </div>
        <button
          onClick={() => setShowAddRole(true)}
          className="flex items-center gap-2 px-4 py-2 text-white bg-violet-500 rounded-lg hover:bg-violet-600 transition-colors"
        >
          <Plus size={16} />
          <span>Add Custom Role</span>
        </button>
      </div>

      {/* Search */}
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search roles..."
          className="w-full pl-10 pr-4 py-2 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
      </div>

      {/* Roles List */}
      <div className="space-y-4">
        {filteredRoles.map((role) => (
          <motion.div
            key={role.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden"
          >
            <div className="p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="p-2 bg-gray-50 rounded-lg">
                    <Users size={20} className="text-gray-400" />
                  </div>
                  <div>
                    <div className="flex items-center gap-2">
                      <h3 className="font-semibold text-gray-900">{role.name}</h3>
                      <span className="text-xs text-gray-500">
                        ({role.userCount} users)
                      </span>
                    </div>
                    <p className="text-sm text-gray-500">{role.description}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setEditingRole(role)}
                    className="p-2 text-gray-400 hover:text-violet-600 hover:bg-violet-50 rounded-lg transition-colors"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={() => setDeleteRole(role)}
                    className="p-2 text-red-400 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                  <button
                    onClick={() => setExpandedRole(expandedRole === role.id ? null : role.id)}
                    className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                  >
                    <ChevronDown
                      size={16}
                      className={`transform transition-transform ${
                        expandedRole === role.id ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                </div>
              </div>

              {/* Permissions */}
              <AnimatePresence>
                {expandedRole === role.id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="mt-6 pt-6 border-t border-gray-100"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {role.permissions.map((permission) => (
                        <div
                          key={permission.id}
                          className="p-4 bg-gray-50 rounded-lg"
                        >
                          <div className="flex items-center justify-between mb-2">
                            <h4 className="font-medium text-gray-900">
                              {permission.name}
                            </h4>
                            <span className="text-xs text-gray-500">
                              {permission.actions.length} actions
                            </span>
                          </div>
                          <p className="text-sm text-gray-500 mb-4">
                            {permission.description}
                          </p>
                          <div className="flex flex-wrap gap-2">
                            {permission.actions.map(action => (
                              <span
                                key={action}
                                className="inline-flex items-center gap-1 px-2 py-1 bg-white rounded-full text-xs font-medium text-gray-600 border border-gray-200"
                              >
                                <CheckCircle2 size={12} className="text-emerald-500" />
                                {action}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Add/Edit Role Modal */}
      <AnimatePresence>
        {(showAddRole || editingRole) && (
          <RoleFormModal
            role={editingRole}
            onClose={() => {
              setShowAddRole(false);
              setEditingRole(null);
            }}
            onSubmit={editingRole ? handleUpdateRole : handleAddRole}
          />
        )}
      </AnimatePresence>

      {/* Delete Confirmation */}
      <ConfirmDialog
        isOpen={!!deleteRole}
        onClose={() => setDeleteRole(null)}
        onConfirm={handleDeleteRole}
        title="Delete Role"
        message={`Are you sure you want to delete the ${deleteRole?.name} role? This action cannot be undone and will affect all users with this role.`}
        confirmLabel="Delete Role"
        type="danger"
      />
    </div>
  );
}