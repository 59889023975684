import React from 'react';
import { motion } from 'framer-motion';
import { Building2, Phone, Mail, Share2 } from 'lucide-react';

interface OverviewProps {
  jobName: string;
  date: string;
  preparedBy: string;
  companyName: string;
  clientName: string;
  title: string;
  phone: string;
  email: string;
  logo?: string;
}

export default function Overview({ 
  jobName, 
  date, 
  preparedBy,
  companyName,
  clientName,
  title,
  phone, 
  email,
  logo
}: OverviewProps) {
  const formattedDate = new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  return (
    <div className="space-y-4">
      {/* Hero Section */}
      <div className="relative overflow-hidden rounded-t-2xl">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black"></div>
        <div className="absolute inset-0 opacity-10 bg-pattern"></div>
        
        <div className="relative px-8 py-12">
          <div className="max-w-7xl mx-auto">
            {/* Header Content */}
            <div className="space-y-8">
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <div className="flex items-center gap-3">
                    <div className="h-12 w-1 bg-teal-500 rounded-full"></div>
                    <div className="flex-1">
                      <span className="text-teal-400 text-sm tracking-wider uppercase font-medium">Progress Report</span>
                      <h1 className="text-4xl font-bold text-white mt-1">
                        <span className="block text-base font-normal text-gray-400 mb-1">Prepared exclusively for</span>
                        <span className="text-white">{clientName}</span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="text-right flex-shrink-0 ml-8">
                  <p className="text-gray-400 text-sm">Report Date: <span className="text-gray-300">{formattedDate}</span></p>
                  <p className="text-gray-400 text-sm">Reference: <span className="text-gray-300">#PR-2024-001</span></p>
                </div>
              </div>

              {/* Primary Job Card */}
              <div className="bg-gradient-to-br from-teal-500/20 to-emerald-500/20 backdrop-blur-sm rounded-xl p-6 border border-teal-500/20">
                <span className="text-teal-300 text-sm uppercase tracking-wider font-medium">Job</span>
                <p className="text-white text-3xl font-bold mt-2">{jobName}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Company Info & Actions Bar */}
      <div className="bg-white rounded-b-2xl shadow-sm border border-gray-100">
        <div className="px-8 py-6">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-6">
            {/* Company Info */}
            <div className="flex items-center gap-6">
              {logo ? (
                <img
                  src={logo}
                  alt={companyName}
                  className="h-12 w-auto object-contain rounded-lg"
                />
              ) : (
                <div className="h-12 w-12 bg-gray-100 rounded-lg flex items-center justify-center">
                  <Building2 className="text-gray-400" size={24} />
                </div>
              )}
              <div className="h-12 w-px bg-gray-200"></div>
              <div>
                <p className="text-gray-500 text-sm mb-1">Report prepared by:</p>
                <div className="space-y-1">
                  <p className="text-gray-900 font-medium">{companyName}</p>
                  <p className="text-gray-700">{preparedBy}</p>
                  <p className="text-gray-500 text-sm">{title}</p>
                </div>
              </div>
            </div>
            
            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row gap-2 min-w-[160px]">
              <ActionButton
                icon={<Phone size={16} />}
                label="Phone"
                onClick={() => window.location.href = `tel:${phone}`}
              />
              <ActionButton
                icon={<Mail size={16} />}
                label="Email"
                onClick={() => window.location.href = `mailto:${email}`}
              />
              <ActionButton
                icon={<Share2 size={16} />}
                label="Share"
                onClick={() => navigator.clipboard.writeText(window.location.href)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ActionButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  primary?: boolean;
}

function ActionButton({ icon, label, onClick, primary }: ActionButtonProps) {
  return (
    <motion.button
      onClick={onClick}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={`inline-flex items-center justify-center gap-2 px-4 py-2.5 rounded-lg transition-colors ${
        primary
          ? 'bg-teal-500 hover:bg-teal-600 text-white'
          : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
      }`}
    >
      {icon}
      <span className="text-sm font-medium">{label}</span>
    </motion.button>
  );
}