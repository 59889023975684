import React from 'react';
import { motion } from 'framer-motion';
import { 
  Briefcase, FileText, DollarSign,
  Bell, Plus, ArrowUpRight,
  Clock, CheckCircle2, AlertTriangle,
  Calendar, Users, Target
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useJobs } from '../../../hooks/useJobs';
import { useReports } from '../../../hooks/useReports';
import { useVariations } from '../../../hooks/useVariations';
import MetricCard from '../metrics/MetricCard';

export default function StaffDashboard() {
  const navigate = useNavigate();
  const { company, user } = useAuth();
  const { jobs } = useJobs();
  const { reports } = useReports();
  const { variations } = useVariations();

  // Filter data by company and assigned to user
  const assignedJobs = jobs.filter(j => 
    j.companyId === company?.id && 
    (j.projectManagerName === user?.name || j.jobManager === user?.name)
  );
  const recentReports = reports
    .filter(r => r.companyId === company?.id && r.preparedBy === user?.name)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 5);

  const assignedVariations = variations.filter(v => 
    v.companyId === company?.id && 
    v.projectManagerId === user?.id
  );

  // Calculate metrics
  const activeJobs = assignedJobs.filter(job => job.status === 'in-progress');
  const completedJobs = assignedJobs.filter(job => job.status === 'completed');
  const pendingVariations = assignedVariations.filter(v => v.status === 'pending_approval');

  const metrics = [
    {
      label: 'Active Jobs',
      value: activeJobs.length,
      icon: Briefcase,
      trend: `${activeJobs.length} in progress`,
      color: 'blue'
    },
    {
      label: 'Reports Created',
      value: recentReports.length,
      icon: FileText,
      trend: 'This month',
      color: 'violet'
    },
    {
      label: 'Completed Jobs',
      value: completedJobs.length,
      icon: CheckCircle2,
      trend: 'Total completed',
      color: 'emerald'
    },
    {
      label: 'Pending Reviews',
      value: pendingVariations.length,
      icon: Clock,
      trend: 'Awaiting approval',
      color: 'amber'
    }
  ];

  const upcomingMilestones = [
    {
      date: new Date('2024-03-15'),
      title: 'Foundation Inspection',
      job: 'New Family Home Construction',
      type: 'inspection'
    },
    {
      date: new Date('2024-03-20'),
      title: 'Progress Report Due',
      job: 'Commercial Office Renovation',
      type: 'report'
    },
    {
      date: new Date('2024-03-25'),
      title: 'Client Meeting',
      job: 'Retail Store Fitout',
      type: 'meeting'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">My Dashboard</h1>
          <p className="text-gray-500">Welcome back, {user?.name}!</p>
        </div>
        <div className="flex items-center gap-4">
          <button className="relative p-2 text-gray-400 hover:text-gray-500 rounded-lg hover:bg-gray-100">
            <Bell size={20} />
            <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
          </button>
          <button
            onClick={() => navigate('/reports/new')}
            className="flex items-center gap-2 px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
          >
            <Plus size={20} />
            <span>New Report</span>
          </button>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Recent Reports */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-violet-50 rounded-lg">
                <FileText className="text-violet-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">My Recent Reports</h2>
            </div>
            <button 
              onClick={() => navigate('/reports')}
              className="text-sm text-violet-600 hover:text-violet-700"
            >
              View All
            </button>
          </div>

          <div className="space-y-4">
            {recentReports.map((report) => (
              <motion.div
                key={report.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 cursor-pointer group"
                onClick={() => navigate(`/reports/${report.id}`)}
                whileHover={{ x: 4 }}
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-white rounded-lg">
                    <FileText size={16} className="text-violet-500" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{report.reportId}</p>
                    <p className="text-sm text-gray-500">{report.jobName}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-500">
                    {new Date(report.date).toLocaleDateString()}
                  </span>
                  <ArrowUpRight 
                    size={16} 
                    className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Upcoming Milestones */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <Target className="text-amber-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Upcoming Milestones</h2>
            </div>
          </div>

          <div className="space-y-4">
            {upcomingMilestones.map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 cursor-pointer group"
                whileHover={{ y: -2 }}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-3">
                    <div className={`p-2 rounded-lg ${
                      milestone.type === 'inspection' ? 'bg-blue-100 text-blue-600' :
                      milestone.type === 'report' ? 'bg-violet-100 text-violet-600' :
                      'bg-emerald-100 text-emerald-600'
                    }`}>
                      {milestone.type === 'inspection' && <CheckCircle2 size={16} />}
                      {milestone.type === 'report' && <FileText size={16} />}
                      {milestone.type === 'meeting' && <Users size={16} />}
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">{milestone.title}</p>
                      <p className="text-sm text-gray-500">{milestone.job}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-500">
                      {milestone.date.toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}