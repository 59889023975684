import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { DataProvider } from './context/DataContext';
import { NotificationProvider } from './context/NotificationContext';
import { AuthProvider } from './context/AuthContext';
import { BrandingProvider } from './context/BrandingContext';
import ErrorBoundary from './components/ui/ErrorBoundary';
import { ROUTES } from './routes/paths';

// Layouts
import DashboardLayout from './layouts/DashboardLayout';
import PublicLayout from './layouts/PublicLayout';

// Public Pages
import LandingPage from './pages/public/LandingPage';
import Features from './pages/public/Features';
import Pricing from './pages/public/Pricing';
import Contact from './pages/public/Contact';

// Auth Pages
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import OnboardingFlow from './pages/auth/OnboardingFlow';
import AcceptInvite from './pages/auth/AcceptInvite';

// Profile Pages
import ProfilePage from './pages/profile/ProfilePage';

// Dashboard Components
import DashboardOverview from './components/dashboard/DashboardOverview';
import ClientList from './components/dashboard/ClientList';
import JobList from './components/dashboard/JobList';
import ReportList from './components/dashboard/ReportList';
import InvoiceList from './components/dashboard/InvoiceList';
import VariationList from './components/variations/VariationList';

// View Components
import ClientView from './components/client/ClientView';
import JobView from './components/job/JobView';
import ReportView from './components/report/ReportView';
import InvoiceView from './components/invoice/InvoiceView';
import VariationView from './components/variations/VariationView';

// Settings Components
import SettingsLayout from './components/settings/SettingsLayout';
import GeneralSettings from './components/settings/GeneralSettings';
import CompanySettings from './components/settings/CompanySettings';
import BrandingSettings from './components/settings/BrandingSettings';
import UserPermissions from './components/settings/UserPermissions';
import RoleManagement from './components/settings/RoleManagement';
import IntegrationSettings from './components/settings/IntegrationSettings';

// Search
import SearchResults from './pages/SearchResults';

// Protected Route Component
import ProtectedRoute from './components/auth/ProtectedRoute';

export default function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <BrandingProvider>
            <DataProvider>
              <NotificationProvider>
                <ErrorBoundary>
                  <Routes>
                    {/* Public Routes */}
                    <Route path={ROUTES.HOME} element={<LandingPage />} />
                    <Route path={ROUTES.FEATURES} element={<Features />} />
                    <Route path={ROUTES.PRICING} element={<Pricing />} />
                    <Route path={ROUTES.CONTACT} element={<Contact />} />

                    {/* Auth Routes */}
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route path={ROUTES.SIGNUP} element={<SignUp />} />
                    <Route path={ROUTES.ONBOARDING} element={
                      <ProtectedRoute>
                        <OnboardingFlow />
                      </ProtectedRoute>
                    } />
                    <Route path={ROUTES.ACCEPT_INVITE} element={<AcceptInvite />} />

                    {/* App Routes */}
                    <Route path={ROUTES.APP} element={
                      <ProtectedRoute>
                        <DashboardLayout />
                      </ProtectedRoute>
                    }>
                      <Route index element={<DashboardOverview />} />
                      
                      {/* Profile */}
                      <Route path="profile" element={<ProfilePage />} />
                      
                      {/* Main Resources */}
                      <Route path="clients" element={<ClientList />} />
                      <Route path="clients/:clientId" element={<ClientView />} />
                      
                      <Route path="jobs" element={<JobList />} />
                      <Route path="jobs/:jobId" element={<JobView />} />
                      
                      <Route path="reports" element={<ReportList />} />
                      <Route path="reports/:reportId" element={<ReportView />} />
                      
                      <Route path="invoices" element={<InvoiceList />} />
                      <Route path="invoices/:invoiceId" element={<InvoiceView />} />
                      
                      <Route path="variations" element={<VariationList />} />
                      <Route path="variations/:variationId" element={<VariationView />} />

                      {/* Search */}
                      <Route path="search" element={<SearchResults />} />

                      {/* Settings */}
                      <Route path="settings" element={<SettingsLayout />}>
                        <Route index element={<GeneralSettings />} />
                        <Route path="company" element={<CompanySettings />} />
                        <Route path="branding" element={<BrandingSettings />} />
                        <Route path="users" element={<UserPermissions />} />
                        <Route path="roles" element={<RoleManagement />} />
                        <Route path="integrations" element={<IntegrationSettings />} />
                      </Route>
                    </Route>

                    {/* Shared Routes */}
                    <Route path={ROUTES.SHARED_REPORT} element={<ReportView shared />} />

                    {/* Catch all */}
                    <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
                  </Routes>
                </ErrorBoundary>
              </NotificationProvider>
            </DataProvider>
          </BrandingProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}