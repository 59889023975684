import { useCallback } from 'react';
import { useData } from '../context/DataContext';
import { Variation } from '../types/variation';
import { generateId } from '../utils/helpers';
import { mockVariations } from '../services/mockData';

export function useVariations() {
  const { state, dispatch } = useData();

  const fetchVariations = useCallback(async (clientId?: string, jobId?: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      let variations = mockVariations;
      if (jobId) {
        variations = variations.filter(v => v.jobId === jobId);
      } else if (clientId) {
        variations = variations.filter(v => v.clientId === clientId);
      }
      dispatch({ type: 'SET_VARIATIONS', payload: variations });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch variations' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const addVariation = useCallback(async (variation: Partial<Variation>) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const newVariation: Variation = {
        ...variation,
        id: generateId(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Variation;
      dispatch({ type: 'ADD_VARIATION', payload: newVariation });
      return newVariation;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add variation' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const updateVariation = useCallback(async (variation: Variation) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedVariation = {
        ...variation,
        updatedAt: new Date(),
      };
      dispatch({ type: 'UPDATE_VARIATION', payload: updatedVariation });
      return updatedVariation;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update variation' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const deleteVariation = useCallback(async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'DELETE_VARIATION', payload: id });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete variation' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  return {
    variations: state.variations,
    loading: state.loading,
    error: state.error,
    fetchVariations,
    addVariation,
    updateVariation,
    deleteVariation,
  };
}