import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle2, CalendarClock } from 'lucide-react';

interface ProgrammeSectionProps {
  tasksAchieved: string;
  tasksPlanned: string;
}

export default function ProgrammeSection({
  tasksAchieved,
  tasksPlanned,
}: ProgrammeSectionProps) {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 pb-6 border-b border-gray-100">
          <div className="flex items-center gap-4">
            <div className="h-12 w-1 bg-emerald-500 rounded-full" />
            <div>
              <h2 className="text-xl font-bold text-gray-900">Programme</h2>
              <p className="text-sm text-gray-500 mt-1">Tasks achieved and upcoming work schedule</p>
            </div>
          </div>
          <div className="p-2 bg-emerald-50 rounded-xl">
            <CheckCircle2 className="text-emerald-500" size={24} />
          </div>
        </div>

        {/* Content */}
        <div className="grid md:grid-cols-2 gap-12">
          {/* Tasks Achieved */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <CheckCircle2 className="text-emerald-500" size={20} />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Tasks Achieved</h3>
                <p className="text-sm text-emerald-500">Completed Work</p>
              </div>
            </div>

            <div className="bg-emerald-50/30 rounded-xl p-6">
              {tasksAchieved.split('\n').map((task, index) => (
                <div 
                  key={index} 
                  className={`flex items-start gap-3 py-3 ${
                    index !== 0 ? 'border-t border-emerald-100' : ''
                  }`}
                >
                  <CheckCircle2 
                    size={16} 
                    className="text-emerald-500 mt-1 flex-shrink-0" 
                  />
                  <p className="text-gray-600 leading-relaxed">{task}</p>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Tasks Planned */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-50 rounded-lg">
                <CalendarClock className="text-indigo-500" size={20} />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Tasks Planned</h3>
                <p className="text-sm text-indigo-500">Upcoming Work</p>
              </div>
            </div>

            <div className="bg-indigo-50/30 rounded-xl p-6">
              {tasksPlanned.split('\n').map((task, index) => (
                <div 
                  key={index} 
                  className={`flex items-start gap-3 py-3 ${
                    index !== 0 ? 'border-t border-indigo-100' : ''
                  }`}
                >
                  <CalendarClock 
                    size={16} 
                    className="text-indigo-500 mt-1 flex-shrink-0" 
                  />
                  <p className="text-gray-600 leading-relaxed">{task}</p>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}