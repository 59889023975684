import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  GitBranch, 
  Building2, 
  Users, 
  Briefcase,
  ChevronLeft,
  Calendar,
  DollarSign,
  FileText,
  Clock,
  CheckCircle2,
  XCircle,
  Share2,
  Download,
  Printer,
  User,
  AlertTriangle,
  Edit2,
  Link,
  Image as ImageIcon,
  Eye,
  EyeOff
} from 'lucide-react';
import { useVariations } from '../../hooks/useVariations';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import { useNotification } from '../../context/NotificationContext';
import VariationFormModal from '../modals/VariationFormModal';
import ConfirmDialog from '../ui/ConfirmDialog';
import { formatDate, formatCurrency } from '../../utils/helpers';

export default function VariationView() {
  const { companyId, clientId, jobId, variationId } = useParams();
  const navigate = useNavigate();
  const { variations, updateVariation, deleteVariation } = useVariations();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { addNotification } = useNotification();

  const [isEditing, setIsEditing] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showImageViewer, setShowImageViewer] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null);

  const variation = variations.find(v => v.id === variationId);
  const company = companies.find(c => c.id === companyId);
  const client = clients.find(c => c.id === clientId);
  const job = jobs.find(j => j.id === jobId);

  if (!variation) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <AlertTriangle size={48} className="text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Variation Not Found</h2>
          <p className="text-gray-500 mb-4">The requested variation could not be found.</p>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 text-sm font-medium text-white bg-violet-500 rounded-lg hover:bg-violet-600"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  const handleBack = () => {
    if (companyId && clientId && jobId) {
      navigate(`/app/companies/${companyId}/clients/${clientId}/jobs/${jobId}/variations`);
    } else if (companyId && clientId) {
      navigate(`/app/companies/${companyId}/clients/${clientId}/variations`);
    } else {
      navigate('/app/variations');
    }
  };

  const handleStatusChange = async (newStatus: 'pending_approval' | 'approved') => {
    try {
      await updateVariation({
        ...variation,
        status: newStatus,
        dateApproved: newStatus === 'approved' ? new Date() : undefined,
      });
      addNotification('success', 'Variation status updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update variation status');
    }
  };

  const handleProgressChange = async (newStatus: 'pending' | 'in_progress' | 'completed') => {
    try {
      await updateVariation({
        ...variation,
        progressStatus: newStatus,
        dateCompleted: newStatus === 'completed' ? new Date() : undefined,
      });
      addNotification('success', 'Progress status updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update progress status');
    }
  };

  const handleVisibilityToggle = async () => {
    try {
      await updateVariation({
        ...variation,
        showInJobReport: !variation.showInJobReport,
      });
      addNotification('success', `Variation ${variation.showInJobReport ? 'hidden from' : 'shown in'} reports`);
    } catch (error) {
      addNotification('error', 'Failed to update variation visibility');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteVariation(variation.id);
      addNotification('success', 'Variation deleted successfully');
      handleBack();
    } catch (error) {
      addNotification('error', 'Failed to delete variation');
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    addNotification('success', 'Variation link copied to clipboard');
  };

  const handleDownload = () => {
    // Implement PDF download
    addNotification('info', 'Download feature coming soon');
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="space-y-6">
      {/* Back Navigation */}
      <button
        onClick={handleBack}
        className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
      >
        <ChevronLeft size={16} />
        <span>Back to Variations</span>
      </button>

      {/* Header */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-violet-50 rounded-lg">
              <GitBranch className="text-violet-600" size={24} />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  {variation.name}
                </h1>
                <span className="text-sm text-gray-500">({variation.variationId})</span>
              </div>
              <div className="flex items-center gap-4 text-sm text-gray-500 mt-1">
                {company && (
                  <div className="flex items-center gap-2">
                    <Building2 size={14} />
                    <span>{company.name}</span>
                  </div>
                )}
                {client && (
                  <div className="flex items-center gap-2">
                    <Users size={14} />
                    <span>{client.name}</span>
                  </div>
                )}
                {job && (
                  <div className="flex items-center gap-2">
                    <Briefcase size={14} />
                    <span>{job.name}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            {/* Action Buttons */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsEditing(true)}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Edit2 size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleShare}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Share2 size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleDownload}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Download size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handlePrint}
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Printer size={20} />
            </motion.button>
          </div>
        </div>

        {/* Status Badges */}
        <div className="flex items-center gap-4">
          <div className="relative group">
            <button
              className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm font-medium ${
                variation.status === 'approved'
                  ? 'bg-emerald-50 text-emerald-600'
                  : 'bg-amber-50 text-amber-600'
              }`}
            >
              {variation.status === 'approved' ? (
                <CheckCircle2 size={14} />
              ) : (
                <Clock size={14} />
              )}
              {variation.status === 'pending_approval' ? 'Pending Approval' : 'Approved'}
            </button>
            <div className="absolute left-0 top-full mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all z-10">
              <button
                onClick={() => handleStatusChange('pending_approval')}
                className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <Clock size={14} />
                Mark as Pending
              </button>
              <button
                onClick={() => handleStatusChange('approved')}
                className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <CheckCircle2 size={14} />
                Mark as Approved
              </button>
            </div>
          </div>

          <div className="relative group">
            <button
              className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm font-medium ${
                variation.progressStatus === 'completed'
                  ? 'bg-emerald-50 text-emerald-600'
                  : variation.progressStatus === 'in_progress'
                  ? 'bg-blue-50 text-blue-600'
                  : 'bg-gray-50 text-gray-600'
              }`}
            >
              {variation.progressStatus === 'completed' ? (
                <CheckCircle2 size={14} />
              ) : (
                <Clock size={14} />
              )}
              {variation.progressStatus.split('_').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ')}
            </button>
            <div className="absolute left-0 top-full mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all z-10">
              <button
                onClick={() => handleProgressChange('pending')}
                className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <Clock size={14} />
                Mark as Pending
              </button>
              <button
                onClick={() => handleProgressChange('in_progress')}
                className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <Clock size={14} />
                Mark as In Progress
              </button>
              <button
                onClick={() => handleProgressChange('completed')}
                className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <CheckCircle2 size={14} />
                Mark as Completed
              </button>
            </div>
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleVisibilityToggle}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
              variation.showInJobReport
                ? 'bg-emerald-50 text-emerald-600 hover:bg-emerald-100'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
            }`}
          >
            {variation.showInJobReport ? (
              <>
                <Eye size={18} />
                <span>Shown in Reports</span>
              </>
            ) : (
              <>
                <EyeOff size={18} />
                <span>Hidden from Reports</span>
              </>
            )}
          </motion.button>

          <span className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm font-medium ${
            variation.invoiceStatus === 'invoiced'
              ? 'bg-emerald-50 text-emerald-600'
              : 'bg-gray-50 text-gray-600'
          }`}>
            {variation.invoiceStatus === 'invoiced' ? (
              <CheckCircle2 size={14} />
            ) : (
              <Clock size={14} />
            )}
            {variation.invoiceStatus === 'invoiced' ? 'Invoiced' : 'Pending Invoice'}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Description */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Description</h2>
            <p className="text-gray-600 whitespace-pre-line">{variation.description}</p>
          </div>

          {/* Images */}
          {variation.images.length > 0 && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex items-center gap-3 mb-4">
                <ImageIcon className="text-gray-400" size={20} />
                <h2 className="text-lg font-semibold text-gray-900">Images</h2>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {variation.images.map((image, index) => (
                  <div
                    key={index}
                    className="relative aspect-video cursor-pointer group"
                    onClick={() => {
                      setSelectedImage(image.url);
                      setShowImageViewer(true);
                    }}
                  >
                    <img
                      src={image.url}
                      alt={image.caption || `Image ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    {image.caption && (
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-end p-3">
                        <p className="text-sm text-white">{image.caption}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Files */}
          {variation.files.length > 0 && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex items-center gap-3 mb-4">
                <FileText className="text-gray-400" size={20} />
                <h2 className="text-lg font-semibold text-gray-900">Files</h2>
              </div>
              <div className="space-y-2">
                {variation.files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                  >
                    <div className="flex items-center gap-3">
                      <FileText size={20} className="text-gray-400" />
                      <div>
                        <p className="text-sm font-medium text-gray-900">{file.name}</p>
                        <p className="text-xs text-gray-500">
                          {(file.size / 1024 / 1024).toFixed(2)} MB
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => window.open(file.url, '_blank')}
                      className="text-sm font-medium text-violet-600 hover:text-violet-700"
                    >
                      Download
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Financial Details */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Financial Details</h2>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Quoted Amount</span>
                <span className="font-medium text-gray-900">
                  ${variation.quotedAmount.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Cost</span>
                <span className="font-medium text-gray-900">
                  ${variation.cost.toLocaleString()}
                </span>
              </div>
              <div className="pt-4 border-t border-gray-100">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Invoiced to Date</span>
                  <span className="font-medium text-gray-900">
                    ${variation.invoicedToDate.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <span className="text-gray-600">Remaining to Invoice</span>
                  <span className="font-medium text-gray-900">
                    ${variation.remainingToInvoice.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Details */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Details</h2>
            <div className="space-y-4">
              <div>
                <span className="text-sm text-gray-500">Project Manager</span>
                <div className="flex items-center gap-2 mt-1">
                  <User size={14} className="text-gray-400" />
                  <span className="text-gray-900">
                    {variation.projectManagerId ? 'John Smith' : 'Not Assigned'}
                  </span>
                </div>
              </div>
              <div>
                <span className="text-sm text-gray-500">Date Added</span>
                <div className="flex items-center gap-2 mt-1">
                  <Calendar size={14} className="text-gray-400" />
                  <span className="text-gray-900">
                    {formatDate(variation.dateAdded)}
                  </span>
                </div>
              </div>
              {variation.dateApproved && (
                <div>
                  <span className="text-sm text-gray-500">Date Approved</span>
                  <div className="flex items-center gap-2 mt-1">
                    <Calendar size={14} className="text-gray-400" />
                    <span className="text-gray-900">
                      {formatDate(variation.dateApproved)}
                    </span>
                  </div>
                </div>
              )}
              {variation.dateCompleted && (
                <div>
                  <span className="text-sm text-gray-500">Date Completed</span>
                  <div className="flex items-center gap-2 mt-1">
                    <Calendar size={14} className="text-gray-400" />
                    <span className="text-gray-900">
                      {formatDate(variation.dateCompleted)}
                    </span>
                  </div>
                </div>
              )}
              <div>
                <span className="text-sm text-gray-500">Show in Job Report</span>
                <div className="flex items-center gap-2 mt-1">
                  <FileText size={14} className="text-gray-400" />
                  <span className="text-gray-900">
                    {variation.showInJobReport ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
              {job && (
                <div>
                  <span className="text-sm text-gray-500">Linked Job</span>
                  <div className="flex items-center gap-2 mt-1">
                    <Link size={14} className="text-gray-400" />
                    <span className="text-gray-900">{job.name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditing && (
        <VariationFormModal
          isOpen={true}
          onClose={() => setIsEditing(false)}
          onSubmit={async (data) => {
            try {
              await updateVariation({ ...variation, ...data });
              addNotification('success', 'Variation updated successfully');
              setIsEditing(false);
            } catch (error) {
              addNotification('error', 'Failed to update variation');
            }
          }}
          initialData={variation}
          companyId={companyId || ''}
          clientId={clientId}
          jobId={jobId}
        />
      )}

      {/* Delete Confirmation */}
      <ConfirmDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleDelete}
        title="Delete Variation"
        message="Are you sure you want to delete this variation? This action cannot be undone."
        confirmLabel="Delete"
        type="danger"
      />

      {/* Image Viewer */}
      {showImageViewer && selectedImage && (
        <div
          className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4"
          onClick={() => setShowImageViewer(false)}
        >
          <img
            src={selectedImage}
            alt="Variation"
            className="max-w-full max-h-[90vh] object-contain"
          />
        </div>
      )}
    </div>
  );
}