import { useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '../context/NotificationContext';
import { Task, TaskFilter, TaskSummary } from '../types/task';
import { generateId } from '../utils/helpers';

export function useTasks() {
  const { user, company } = useAuth();
  const { addNotification } = useNotification();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchTasks = useCallback(async (filter?: TaskFilter) => {
    if (!company?.id) return;
    
    setLoading(true);
    setError(null);
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      let filteredTasks = tasks;

      if (filter) {
        filteredTasks = tasks.filter(task => {
          if (filter.status && filter.status.length > 0) {
            if (!filter.status.includes(task.status)) return false;
          }
          if (filter.priority && filter.priority.length > 0) {
            if (!filter.priority.includes(task.priority)) return false;
          }
          if (filter.type && filter.type.length > 0) {
            if (!filter.type.includes(task.type)) return false;
          }
          if (filter.assignedTo && filter.assignedTo.length > 0) {
            if (!filter.assignedTo.includes(task.assignedToId)) return false;
          }
          if (filter.dateRange) {
            const taskDate = new Date(task.dueDate);
            if (taskDate < filter.dateRange.start || taskDate > filter.dateRange.end) {
              return false;
            }
          }
          if (filter.tags && filter.tags.length > 0) {
            if (!task.tags.some(tag => filter.tags?.includes(tag))) return false;
          }
          if (filter.search) {
            const searchTerm = filter.search.toLowerCase();
            return (
              task.title.toLowerCase().includes(searchTerm) ||
              task.description.toLowerCase().includes(searchTerm)
            );
          }
          return true;
        });
      }

      setTasks(filteredTasks);
    } catch (error) {
      setError('Failed to fetch tasks');
      addNotification('error', 'Failed to fetch tasks');
    } finally {
      setLoading(false);
    }
  }, [company?.id, tasks, addNotification]);

  const addTask = useCallback(async (taskData: Partial<Task>) => {
    if (!company?.id || !user?.id) return;

    setLoading(true);
    try {
      const newTask: Task = {
        ...taskData,
        id: generateId(),
        companyId: company.id,
        createdById: user.id,
        status: taskData.status || 'todo',
        priority: taskData.priority || 'medium',
        type: taskData.type || 'general',
        tags: taskData.tags || [],
        attachments: taskData.attachments || [],
        comments: taskData.comments || [],
        subtasks: taskData.subtasks || [],
        dependencies: taskData.dependencies || [],
        watchers: taskData.watchers || [],
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Task;

      setTasks(prev => [...prev, newTask]);
      addNotification('success', 'Task created successfully');
      return newTask;
    } catch (error) {
      setError('Failed to create task');
      addNotification('error', 'Failed to create task');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [company?.id, user?.id, addNotification]);

  const updateTask = useCallback(async (taskId: string, updates: Partial<Task>) => {
    setLoading(true);
    try {
      setTasks(prev => prev.map(task => 
        task.id === taskId
          ? { ...task, ...updates, updatedAt: new Date() }
          : task
      ));
      addNotification('success', 'Task updated successfully');
    } catch (error) {
      setError('Failed to update task');
      addNotification('error', 'Failed to update task');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [addNotification]);

  const deleteTask = useCallback(async (taskId: string) => {
    setLoading(true);
    try {
      setTasks(prev => prev.filter(task => task.id !== taskId));
      addNotification('success', 'Task deleted successfully');
    } catch (error) {
      setError('Failed to delete task');
      addNotification('error', 'Failed to delete task');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [addNotification]);

  const getTaskSummary = useCallback((): TaskSummary => {
    const summary: TaskSummary = {
      total: tasks.length,
      completed: tasks.filter(t => t.status === 'completed').length,
      overdue: tasks.filter(t => new Date(t.dueDate) < new Date() && t.status !== 'completed').length,
      byStatus: {
        todo: 0,
        in_progress: 0,
        review: 0,
        completed: 0,
        blocked: 0
      },
      byPriority: {
        low: 0,
        medium: 0,
        high: 0,
        urgent: 0
      },
      byType: {
        general: 0,
        job: 0,
        variation: 0,
        meeting: 0,
        inspection: 0
      },
      byAssignee: {},
      totalEstimatedHours: 0,
      totalActualHours: 0,
      totalValue: 0
    };

    tasks.forEach(task => {
      // Count by status
      summary.byStatus[task.status]++;

      // Count by priority
      summary.byPriority[task.priority]++;

      // Count by type
      summary.byType[task.type]++;

      // Count by assignee
      if (!summary.byAssignee[task.assignedToId]) {
        summary.byAssignee[task.assignedToId] = 0;
      }
      summary.byAssignee[task.assignedToId]++;

      // Sum hours and value
      if (task.estimatedHours) summary.totalEstimatedHours += task.estimatedHours;
      if (task.actualHours) summary.totalActualHours += task.actualHours;
      if (task.value) summary.totalValue += task.value;
    });

    return summary;
  }, [tasks]);

  const getTasksByJob = useCallback((jobId: string) => {
    return tasks.filter(task => task.jobId === jobId);
  }, [tasks]);

  const getTasksByClient = useCallback((clientId: string) => {
    return tasks.filter(task => task.clientId === clientId);
  }, [tasks]);

  const getTasksByAssignee = useCallback((userId: string) => {
    return tasks.filter(task => task.assignedToId === userId);
  }, [tasks]);

  return {
    tasks,
    loading,
    error,
    fetchTasks,
    addTask,
    updateTask,
    deleteTask,
    getTaskSummary,
    getTasksByJob,
    getTasksByClient,
    getTasksByAssignee,
  };
}