import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Activity, TrendingUp } from 'lucide-react';

interface AnimatedProgressCircleProps {
  percentage: number;
}

export default function AnimatedProgressCircle({ percentage }: AnimatedProgressCircleProps) {
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();
  const circumference = 2 * Math.PI * 90;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const pulseVariants = {
    initial: { scale: 1, opacity: 0.5 },
    pulse: { 
      scale: 1.05, 
      opacity: 1,
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  };

  const iconVariants = {
    initial: { scale: 1, rotate: 0 },
    hover: { 
      scale: 1.2,
      rotate: 360,
      transition: { duration: 0.5 }
    }
  };

  const textVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    hover: { 
      scale: 1.1,
      transition: { duration: 0.2 }
    }
  };

  return (
    <div className="flex items-center justify-center">
      <motion.div 
        className="relative w-48 h-48 cursor-pointer"
        onHoverStart={() => {
          setIsHovered(true);
          controls.start("hover");
        }}
        onHoverEnd={() => {
          setIsHovered(false);
          controls.start("initial");
        }}
        animate={controls}
      >
        {/* Outer Glow */}
        <motion.div
          className="absolute inset-0 rounded-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered ? 0.2 : 0 }}
          style={{ 
            background: `radial-gradient(circle, ${
              percentage >= 75 ? '#10B981' : 
              percentage >= 50 ? '#3B82F6' : 
              '#F59E0B'
            } 0%, transparent 70%)` 
          }}
        />

        {/* Background Circle */}
        <svg className="w-full h-full transform -rotate-90">
          <circle
            cx="96"
            cy="96"
            r="90"
            fill="none"
            stroke="#e5e7eb"
            strokeWidth="12"
            className="transition-all duration-300"
            style={{
              opacity: isHovered ? 0.3 : 0.15
            }}
          />

          {/* Progress Circle */}
          <motion.circle
            cx="96"
            cy="96"
            r="90"
            fill="none"
            stroke={`url(#${isHovered ? 'gradientHover' : 'gradient'})`}
            strokeWidth="12"
            strokeLinecap="round"
            strokeDasharray={circumference}
            initial={{ strokeDashoffset: circumference }}
            animate={{ strokeDashoffset }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          />

          {/* Gradient Definitions */}
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#14b8a6" />
              <stop offset="100%" stopColor="#059669" />
            </linearGradient>
            <linearGradient id="gradientHover" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#0EA5E9" />
              <stop offset="100%" stopColor="#14b8a6" />
            </linearGradient>
          </defs>
        </svg>

        {/* Center Content */}
        <motion.div
          className="absolute inset-0 flex flex-col items-center justify-center"
          variants={textVariants}
          initial="initial"
          animate="animate"
          whileHover="hover"
        >
          <motion.span 
            className="text-4xl font-bold text-gray-900"
            variants={textVariants}
          >
            {percentage}%
          </motion.span>
          <motion.div
            className="flex items-center gap-1 mt-1"
            variants={iconVariants}
          >
            <TrendingUp size={16} className="text-emerald-500" />
            <span className="text-sm text-emerald-500">Progress</span>
          </motion.div>
        </motion.div>

        {/* Animated Pulse Ring */}
        <motion.div
          className="absolute inset-0 rounded-full"
          style={{ 
            border: `2px solid ${
              percentage >= 75 ? '#10B981' : 
              percentage >= 50 ? '#3B82F6' : 
              '#F59E0B'
            }`,
            opacity: 0.5
          }}
          variants={pulseVariants}
          initial="initial"
          animate={isHovered ? "pulse" : "initial"}
        />

        {/* Activity Indicator */}
        <motion.div
          className="absolute top-0 right-0 p-2 bg-white rounded-full shadow-lg"
          initial={{ scale: 0 }}
          animate={{ scale: isHovered ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <Activity size={16} className="text-blue-500 animate-pulse" />
        </motion.div>
      </motion.div>
    </div>
  );
}