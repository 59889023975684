import React from 'react';
import { motion } from 'framer-motion';
import { 
  FileText, 
  Users, 
  DollarSign, 
  TrendingUp,
  CheckCircle2,
  Building2,
  Clock,
  Share2,
  Bell,
  Shield,
  Smartphone,
  Cloud
} from 'lucide-react';
import PublicLayout from '../../layouts/PublicLayout';

export default function Features() {
  const mainFeatures = [
    {
      icon: FileText,
      title: 'Progress Reports',
      description: 'Create professional, detailed progress reports with photos, financial updates, and project milestones.',
      benefits: [
        'Customizable templates',
        'Photo galleries with captions',
        'Financial tracking',
        'Project timeline updates',
        'Compliance monitoring'
      ]
    },
    {
      icon: Users,
      title: 'Client Management',
      description: 'Manage your clients and give them secure access to their project reports and updates.',
      benefits: [
        'Client portals',
        'Access control',
        'Communication history',
        'Document sharing',
        'Activity tracking'
      ]
    },
    {
      icon: DollarSign,
      title: 'Financial Tools',
      description: 'Track invoices, variations, and payments with our comprehensive financial management tools.',
      benefits: [
        'Invoice generation',
        'Variation tracking',
        'Payment recording',
        'Financial reporting',
        'Xero integration'
      ]
    },
    {
      icon: Building2,
      title: 'Project Management',
      description: 'Keep track of all your construction projects in one centralized platform.',
      benefits: [
        'Project dashboard',
        'Task management',
        'Resource allocation',
        'Timeline tracking',
        'Document storage'
      ]
    }
  ];

  const additionalFeatures = [
    {
      icon: Clock,
      title: 'Real-time Updates',
      description: 'Keep everyone informed with instant updates and notifications.'
    },
    {
      icon: Share2,
      title: 'Easy Sharing',
      description: 'Share reports and updates with stakeholders in just a few clicks.'
    },
    {
      icon: Bell,
      title: 'Smart Notifications',
      description: 'Stay on top of important updates with customizable notifications.'
    },
    {
      icon: Shield,
      title: 'Secure Access',
      description: 'Enterprise-grade security to protect your sensitive data.'
    },
    {
      icon: Smartphone,
      title: 'Mobile Friendly',
      description: 'Access your projects on any device with our responsive design.'
    },
    {
      icon: Cloud,
      title: 'Cloud Storage',
      description: 'All your project data safely stored and backed up in the cloud.'
    }
  ];

  return (
    <PublicLayout>
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-gray-900 py-20 sm:py-32">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900" />
        <div className="absolute inset-0 bg-pattern opacity-5" />
        
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1 
              className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Powerful Features for
              <span className="block text-teal-500">Modern Construction</span>
            </motion.h1>
            <motion.p 
              className="mx-auto mt-6 max-w-2xl text-lg text-gray-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Everything you need to manage your construction projects efficiently and keep your clients informed.
            </motion.p>
          </div>
        </div>
      </section>

      {/* Main Features */}
      <section className="py-20 sm:py-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            {mainFeatures.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="relative bg-white p-8 rounded-2xl shadow-sm border border-gray-100"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center gap-4 mb-6">
                  <div className="flex items-center justify-center w-12 h-12 bg-teal-50 text-teal-600 rounded-xl">
                    <feature.icon size={24} />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900">{feature.title}</h3>
                </div>
                <p className="text-gray-500 mb-6">{feature.description}</p>
                <ul className="space-y-3">
                  {feature.benefits.map((benefit) => (
                    <li key={benefit} className="flex items-center gap-3">
                      <CheckCircle2 size={16} className="text-teal-500 flex-shrink-0" />
                      <span className="text-gray-600">{benefit}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Additional Features */}
      <section className="py-20 sm:py-32 bg-gray-50">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Everything you need to succeed
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Packed with features to help you manage your projects more efficiently.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {additionalFeatures.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="relative bg-white p-6 rounded-xl shadow-sm border border-gray-100"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="flex items-center justify-center w-10 h-10 bg-teal-50 text-teal-600 rounded-lg">
                    <feature.icon size={20} />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                </div>
                <p className="text-gray-500">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </PublicLayout>
  );
}