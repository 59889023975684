import { useCallback } from 'react';
import { useData } from '../context/DataContext';
import { Job } from '../types';
import { generateId } from '../utils/helpers';
import { mockJobs } from '../services/mockData';

export function useJobs() {
  const { state, dispatch } = useData();

  const fetchJobs = useCallback(async (clientId?: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const jobs = clientId
        ? mockJobs.filter(job => job.clientId === clientId)
        : mockJobs;
      dispatch({ type: 'SET_JOBS', payload: jobs });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch jobs' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const addJob = useCallback(async (job: Partial<Job>) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const newJob: Job = {
        ...job,
        id: generateId(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Job;
      dispatch({ type: 'ADD_JOB', payload: newJob });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add job' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const updateJob = useCallback(async (job: Job) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedJob = {
        ...job,
        updatedAt: new Date(),
      };
      dispatch({ type: 'UPDATE_JOB', payload: updatedJob });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update job' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const deleteJob = useCallback(async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'DELETE_JOB', payload: id });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete job' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const getJobsByClient = useCallback((clientId: string) => {
    return state.jobs.filter(job => job.clientId === clientId);
  }, [state.jobs]);

  const getJobsByCompany = useCallback((companyId: string) => {
    return state.jobs.filter(job => job.companyId === companyId);
  }, [state.jobs]);

  return {
    jobs: state.jobs,
    loading: state.loading,
    error: state.error,
    fetchJobs,
    addJob,
    updateJob,
    deleteJob,
    getJobsByClient,
    getJobsByCompany,
  };
}