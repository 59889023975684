import { useCallback } from 'react';
import { useData } from '../context/DataContext';
import { Invoice, Payment } from '../types/invoice';
import { generateId } from '../utils/helpers';

export function useInvoices() {
  const { state, dispatch } = useData();

  const fetchInvoices = useCallback(async (companyId?: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // In a real app, fetch from API
      await new Promise(resolve => setTimeout(resolve, 1000));
      const invoices = companyId
        ? state.invoices.filter(invoice => invoice.companyId === companyId)
        : state.invoices;
      dispatch({ type: 'SET_INVOICES', payload: invoices });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch invoices' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch, state.invoices]);

  const addInvoice = useCallback(async (invoice: Partial<Invoice>) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // In a real app, send to API
      const newInvoice: Invoice = {
        ...invoice,
        id: generateId(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as Invoice;
      dispatch({ type: 'ADD_INVOICE', payload: newInvoice });
      return newInvoice;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add invoice' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const updateInvoice = useCallback(async (invoice: Invoice) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const updatedInvoice = {
        ...invoice,
        updatedAt: new Date(),
      };
      dispatch({ type: 'UPDATE_INVOICE', payload: updatedInvoice });
      return updatedInvoice;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update invoice' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const deleteInvoice = useCallback(async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch({ type: 'DELETE_INVOICE', payload: id });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete invoice' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  const addPayment = useCallback(async (payment: Partial<Payment>) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const newPayment: Payment = {
        ...payment,
        id: generateId(),
        createdAt: new Date(),
      } as Payment;
      dispatch({ type: 'ADD_PAYMENT', payload: newPayment });
      return newPayment;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to add payment' });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [dispatch]);

  return {
    invoices: state.invoices,
    loading: state.loading,
    error: state.error,
    fetchInvoices,
    addInvoice,
    updateInvoice,
    deleteInvoice,
    addPayment,
  };
}