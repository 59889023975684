import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { useReports } from '../../hooks/useReports';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import { useInvoices } from '../../hooks/useInvoices';
import { useVariations } from '../../hooks/useVariations';
import Overview from '../Overview';
import ProjectStatus from '../ProjectStatus';
import ProgrammeSection from '../ProgrammeSection';
import ComplianceSection from '../ComplianceSection';
import FinancialOverview from '../FinancialOverview';
import CommentsProgress from '../CommentsProgress';
import ImageGallery from '../ImageGallery';
import PDFExport from '../PDFExport';
import JobProgress from '../JobProgress';
import InvoiceTable from './InvoiceTable';
import VariationTable from './VariationTable';
import { generateShareableLink } from '../../utils/helpers';

interface ReportViewProps {
  shared?: boolean;
}

export default function ReportView({ shared = false }: ReportViewProps) {
  const { companyId, clientId, jobId, reportId } = useParams();
  const navigate = useNavigate();
  const [showShareTooltip, setShowShareTooltip] = React.useState(false);
  const { reports } = useReports();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { invoices } = useInvoices();
  const { variations } = useVariations();

  const report = reports.find(r => r.id === reportId);
  const company = companies.find(c => c.id === report?.companyId);
  const client = clients.find(c => c.id === report?.clientId);
  const job = jobs.find(j => j.id === report?.jobId);

  // Get job-specific invoices and variations
  const jobInvoices = invoices.filter(i => i.items.some(item => item.jobId === job?.id));
  const jobVariations = variations.filter(v => v.jobId === job?.id);

  const shareableLink = reportId ? generateShareableLink(reportId) : '';

  const handleBack = () => {
    if (companyId && clientId && jobId) {
      // Full path navigation
      navigate(`/app/companies/${companyId}/clients/${clientId}/jobs/${jobId}/reports`);
    } else if (jobId) {
      // Direct job path
      navigate(`/app/jobs/${jobId}/reports`);
    } else {
      // Default reports path
      navigate('/app/reports');
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareableLink);
    setShowShareTooltip(true);
    setTimeout(() => setShowShareTooltip(false), 2000);
  };

  if (!report || !company || !client || !job) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Report Not Found</h2>
          <p className="text-gray-500">The requested report could not be found.</p>
          {!shared && (
            <button
              onClick={() => navigate(-1)}
              className="mt-4 px-4 py-2 text-sm font-medium text-white bg-teal-500 rounded-lg hover:bg-teal-600 transition-colors"
            >
              Go Back
            </button>
          )}
        </div>
      </div>
    );
  }

  const data = {
    jobName: job.name,
    date: report.date.toString(),
    preparedBy: report.preparedBy,
    companyName: company.name,
    clientName: client.name,
    title: report.preparedByTitle,
    phone: company.phone,
    email: company.email,
    logo: company.logo,
    tasksAchieved: report.tasksAchieved,
    tasksPlanned: report.tasksPlanned,
    keyUpdates: report.keyUpdates,
    clientCommunication: report.clientCommunication,
    accidents: report.accidents,
    councilInspections: report.councilInspections,
    progressPercentage: report.progressPercentage,
    contractAmount: report.contractAmount,
    invoicedToDate: report.invoicedToDate,
    approvedVariations: report.approvedVariations,
    pendingVariations: report.pendingVariations,
    comments: report.comments,
    images: report.images,
  };

  return (
    <div className="space-y-8">
      {/* Back Navigation */}
      {!shared && (
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
        >
          <ChevronLeft size={16} />
          <span>Back to Progress Reports</span>
        </button>
      )}

      <Overview {...data} />
      
      <JobProgress progressPercentage={data.progressPercentage} />
      
      <ProjectStatus
        keyUpdates={data.keyUpdates}
        clientCommunication={data.clientCommunication}
      />

      <ProgrammeSection
        tasksAchieved={data.tasksAchieved}
        tasksPlanned={data.tasksPlanned}
      />

      <ComplianceSection
        accidents={data.accidents}
        councilInspections={data.councilInspections}
      />

      <FinancialOverview
        contractAmount={data.contractAmount}
        invoicedToDate={data.invoicedToDate}
        approvedVariations={data.approvedVariations}
        pendingVariations={data.pendingVariations}
        job={job}
        invoices={jobInvoices}
        variations={jobVariations}
      />

      {jobInvoices.length > 0 && (
        <InvoiceTable invoices={jobInvoices} />
      )}
      
      {jobVariations.length > 0 && (
        <VariationTable variations={jobVariations} />
      )}

      {data.comments && (
        <CommentsProgress comments={data.comments} />
      )}

      {data.images && data.images.length > 0 && (
        <ImageGallery images={data.images} />
      )}

      {!shared && <PDFExport data={data} />}
    </div>
  );
}