import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Calendar, Image as ImageIcon, MoreVertical } from 'lucide-react';
import { Report } from '../../types';
import ActionMenu from '../ui/ActionMenu';

interface ReportCardProps {
  report: Report;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function ReportCard({ report, onSelect, onEdit, onDelete }: ReportCardProps) {
  const getProgressColor = (percentage: number) => {
    if (percentage >= 75) return 'bg-emerald-500';
    if (percentage >= 50) return 'bg-blue-500';
    if (percentage >= 25) return 'bg-amber-500';
    return 'bg-gray-500';
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden cursor-pointer group"
    >
      <div className="p-6" onClick={onSelect}>
        {/* Header */}
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-teal-50 rounded-lg group-hover:bg-teal-100 transition-colors">
              <FileText className="text-teal-600" size={20} />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 group-hover:text-teal-600 transition-colors">
                {report.reportId}
              </h3>
              <p className="text-sm text-gray-500">{report.jobName}</p>
            </div>
          </div>
          <div onClick={e => e.stopPropagation()}>
            <ActionMenu
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </div>
        </div>

        {/* Progress Bar */}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-500">Progress</span>
            <span className="text-sm font-medium text-gray-900">
              {report.progressPercentage}%
            </span>
          </div>
          <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
            <motion.div
              className={`h-full ${getProgressColor(report.progressPercentage)}`}
              initial={{ width: 0 }}
              animate={{ width: `${report.progressPercentage}%` }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            />
          </div>
        </div>

        {/* Footer Info */}
        <div className="flex items-center justify-between pt-4 border-t border-gray-100">
          <div className="flex items-center gap-2 text-sm">
            <Calendar size={14} className="text-gray-400" />
            <span className="text-gray-600">
              {new Date(report.date).toLocaleDateString()}
            </span>
          </div>
          <div className="flex items-center gap-2 text-sm">
            <ImageIcon size={14} className="text-gray-400" />
            <span className="text-gray-600">
              {report.images?.length || 0} images
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}