import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  FileText, 
  Upload, 
  X, 
  DollarSign, 
  Calendar, 
  User,
  Link,
  Image as ImageIcon,
  Plus,
  Building2,
  Users,
  Briefcase,
  CheckCircle2,
  Clock,
  AlertTriangle,
  Shield
} from 'lucide-react';
import { Report } from '../../types';
import { useJobs } from '../../hooks/useJobs';
import { useInvoices } from '../../hooks/useInvoices';
import { useVariations } from '../../hooks/useVariations';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import FormModal from './FormModal';
import FormField from '../ui/FormField';
import FormError from '../ui/FormError';
import { useFormValidation } from '../../hooks/useFormValidation';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { twMerge } from 'tailwind-merge';

interface ReportFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Report>) => Promise<Report>;
  initialData?: Partial<Report>;
  companyId: string;
  clientId?: string;
  jobId?: string;
}

export default function ReportFormModal({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  companyId,
  clientId: defaultClientId,
  jobId: defaultJobId,
}: ReportFormModalProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addNotification } = useNotification();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { variations } = useVariations();
  const { invoices } = useInvoices();
  
  const [selectedClient, setSelectedClient] = useState(defaultClientId || '');
  const [images, setImages] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [selectedVariations, setSelectedVariations] = useState<string[]>([]);

  const {
    formData,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
    setFormData,
  } = useFormValidation(initialData || {
    reportId: `PR-${new Date().getFullYear()}-${Math.floor(Math.random() * 1000).toString().padStart(3, '0')}`,
    jobId: defaultJobId || '',
    clientId: defaultClientId || '',
    companyId,
    companyName: companies.find(c => c.id === companyId)?.name,
    clientName: clients.find(c => c.id === defaultClientId)?.name,
    jobName: jobs.find(j => j.id === defaultJobId)?.name,
    logo: companies.find(c => c.id === companyId)?.logo,
    date: new Date().toISOString().split('T')[0],
    preparedBy: user?.name || '',
    preparedByTitle: 'Project Manager',
    tasksAchieved: '',
    tasksPlanned: '',
    keyUpdates: '',
    clientCommunication: '',
    accidents: '',
    councilInspections: '',
    progressPercentage: 0,
    contractAmount: 0,
    invoicedToDate: 0,
    approvedVariations: 0,
    pendingVariations: 0,
    comments: '',
    images: [],
  }, {
    date: [{ required: true, message: 'Report date is required' }],
    preparedBy: [{ required: true, message: 'Prepared by is required' }],
    preparedByTitle: [{ required: true, message: 'Title is required' }],
    tasksAchieved: [{ required: true, message: 'Tasks achieved is required' }],
    tasksPlanned: [{ required: true, message: 'Tasks planned is required' }],
    keyUpdates: [{ required: true, message: 'Key updates are required' }],
    clientCommunication: [{ required: true, message: 'Client communication is required' }],
    progressPercentage: [
      { required: true, message: 'Progress percentage is required' },
      { min: 0, message: 'Progress cannot be less than 0%' },
      { max: 100, message: 'Progress cannot exceed 100%' }
    ]
  });

  // Get the selected job's variations
  const jobVariations = variations.filter(v => v.jobId === formData.jobId);
  const approvedVariations = jobVariations.filter(v => v.status === 'approved');
  const pendingVariations = jobVariations.filter(v => v.status === 'pending_approval');

  // Calculate variation totals
  const approvedVariationsTotal = approvedVariations
    .filter(v => selectedVariations.includes(v.id))
    .reduce((sum, v) => sum + v.quotedAmount, 0);

  const pendingVariationsTotal = pendingVariations
    .filter(v => selectedVariations.includes(v.id))
    .reduce((sum, v) => sum + v.quotedAmount, 0);

  // Get job details and calculate totals
  const selectedJob = jobs.find(j => j.id === formData.jobId);
  const totalContractValue = (selectedJob?.contractAmount || 0) + approvedVariationsTotal;
  const totalInvoiced = selectedJob?.totalInvoiced || 0;
  const remainingValue = totalContractValue - totalInvoiced;

  // Initialize selected variations when job changes
  useEffect(() => {
    if (formData.jobId) {
      const jobVars = variations.filter(v => v.jobId === formData.jobId);
      setSelectedVariations(jobVars.map(v => v.id));
    }
  }, [formData.jobId, variations]);

  // Update form data when job selection changes
  useEffect(() => {
    if (selectedJob) {
      setFormData(prev => ({
        ...prev,
        jobId: selectedJob.id,
        jobName: selectedJob.name,
        contractAmount: selectedJob.contractAmount,
        invoicedToDate: selectedJob.totalInvoiced,
        progressPercentage: Math.round((selectedJob.totalInvoiced / selectedJob.contractAmount) * 100)
      }));
    }
  }, [selectedJob, setFormData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const finalData = {
        ...formData,
        approvedVariations: approvedVariationsTotal,
        pendingVariations: pendingVariationsTotal,
        contractAmount: totalContractValue,
        invoicedToDate: totalInvoiced,
      };

      const savedReport = await onSubmit(finalData);
      
      // Close the modal
      onClose();
      
      // Navigate to the new report view
      if (!initialData && savedReport?.id) {
        navigate(`/reports/${savedReport.id}`);
      }
    } catch (error) {
      addNotification('error', 'Failed to save report');
    }
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files) {
      const imageFiles = Array.from(e.dataTransfer.files).filter(file => 
        file.type.startsWith('image/')
      );
      setImages(prev => [...prev, ...imageFiles]);
    }
  };

  const removeImage = (index: number) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  const toggleVariation = (variationId: string) => {
    setSelectedVariations(prev => 
      prev.includes(variationId)
        ? prev.filter(id => id !== variationId)
        : [...prev, variationId]
    );
  };

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      title={initialData ? 'Edit Report' : 'Create Progress Report'}
      size="xl"
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Header Information */}
        <div className="bg-gradient-to-br from-gray-50 to-white rounded-xl p-6 border border-gray-100">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              {formData.logo ? (
                <img 
                  src={formData.logo} 
                  alt={formData.companyName}
                  className="h-12 w-12 object-contain rounded-lg"
                />
              ) : (
                <div className="h-12 w-12 bg-gray-100 rounded-lg flex items-center justify-center">
                  <Building2 size={24} className="text-gray-400" />
                </div>
              )}
              <div>
                <h3 className="font-semibold text-gray-900">{formData.companyName}</h3>
                <p className="text-sm text-gray-500">Progress Report</p>
              </div>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500">Report Reference</p>
              <p className="font-mono text-lg font-medium text-gray-900">
                {formData.reportId}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Client
                <span className="text-red-500 ml-1">*</span>
              </label>
              <select
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                required
              >
                <option value="">Select Client</option>
                {clients
                  .filter(c => c.companyId === companyId)
                  .map(client => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))
                }
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Job
                <span className="text-red-500 ml-1">*</span>
              </label>
              <select
                value={formData.jobId}
                onChange={(e) => setFormData(prev => ({ ...prev, jobId: e.target.value }))}
                className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                required
              >
                <option value="">Select Job</option>
                {jobs
                  .filter(j => j.clientId === selectedClient)
                  .map(job => (
                    <option key={job.id} value={job.id}>
                      {job.name}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>

        {/* Report Details */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <FormField
              label="Report Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<Calendar size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.date} touched={touched.date} />
          </div>

          <div>
            <FormField
              label="Prepared By"
              name="preparedBy"
              value={formData.preparedBy}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<User size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.preparedBy} touched={touched.preparedBy} />
          </div>

          <div>
            <FormField
              label="Title"
              name="preparedByTitle"
              value={formData.preparedByTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<User size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.preparedByTitle} touched={touched.preparedByTitle} />
          </div>
        </div>

        {/* Progress */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Progress Percentage
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="space-y-4">
            <input
              type="range"
              name="progressPercentage"
              value={formData.progressPercentage}
              onChange={handleChange}
              onBlur={handleBlur}
              min="0"
              max="100"
              step="1"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-teal-500"
            />
            <div className="flex items-center justify-between text-sm text-gray-600">
              <span>0%</span>
              <span className="font-medium text-gray-900">{formData.progressPercentage}%</span>
              <span>100%</span>
            </div>
          </div>
          <FormError errors={errors.progressPercentage} touched={touched.progressPercentage} />
        </div>

        {/* Tasks */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Tasks Achieved"
              name="tasksAchieved"
              value={formData.tasksAchieved}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              multiline
              rows={6}
              placeholder="List completed tasks and achievements..."
            />
            <FormError errors={errors.tasksAchieved} touched={touched.tasksAchieved} />
          </div>

          <div>
            <FormField
              label="Tasks Planned"
              name="tasksPlanned"
              value={formData.tasksPlanned}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              multiline
              rows={6}
              placeholder="List upcoming tasks and plans..."
            />
            <FormError errors={errors.tasksPlanned} touched={touched.tasksPlanned} />
          </div>
        </div>

        {/* Updates and Communication */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Key Updates"
              name="keyUpdates"
              value={formData.keyUpdates}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              multiline
              rows={4}
              placeholder="Provide key project updates and milestones..."
            />
            <FormError errors={errors.keyUpdates} touched={touched.keyUpdates} />
          </div>

          <div>
            <FormField
              label="Client Communication"
              name="clientCommunication"
              value={formData.clientCommunication}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              multiline
              rows={4}
              placeholder="Document client interactions and decisions..."
            />
            <FormError errors={errors.clientCommunication} touched={touched.clientCommunication} />
          </div>
        </div>

        {/* Compliance */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Health & Safety"
              name="accidents"
              value={formData.accidents}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              multiline
              rows={4}
              placeholder="Document any accidents, near misses, or safety observations..."
            />
            <FormError errors={errors.accidents} touched={touched.accidents} />
          </div>

          <div>
            <FormField
              label="Council Inspections"
              name="councilInspections"
              value={formData.councilInspections}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              multiline
              rows={4}
              placeholder="Document any council inspections or compliance matters..."
            />
            <FormError errors={errors.councilInspections} touched={touched.councilInspections} />
          </div>
        </div>

        {/* Financial Overview */}
        {selectedJob && (
          <div className="bg-gradient-to-br from-emerald-50 to-teal-50 rounded-xl p-6 border border-emerald-100">
            <h3 className="text-lg font-semibold text-gray-900 mb-6">Financial Overview</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
              <div>
                <p className="text-sm text-gray-600">Contract Value</p>
                <p className="text-lg font-semibold text-gray-900">
                  ${selectedJob.contractAmount.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Invoiced to Date</p>
                <p className="text-lg font-semibold text-gray-900">
                  ${totalInvoiced.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Approved Variations</p>
                <p className="text-lg font-semibold text-emerald-600">
                  ${approvedVariationsTotal.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Pending Variations</p>
                <p className="text-lg font-semibold text-amber-600">
                  ${pendingVariationsTotal.toLocaleString()}
                </p>
              </div>
            </div>

            {/* Variations Selection */}
            {jobVariations.length > 0 && (
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-3">Include Variations</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {jobVariations.map(variation => (
                    <label
                      key={variation.id}
                      className={twMerge(
                        "flex items-center gap-3 p-3 rounded-lg border transition-colors cursor-pointer",
                        selectedVariations.includes(variation.id)
                          ? "bg-white border-emerald-200"
                          : "bg-gray-50 border-gray-200 hover:bg-gray-100"
                      )}
                    >
                      <input
                        type="checkbox"
                        checked={selectedVariations.includes(variation.id)}
                        onChange={() => toggleVariation(variation.id)}
                        className="w-4 h-4 text-emerald-500 rounded border-gray-300 focus:ring-emerald-500"
                      />
                      <div className="flex-1 min-w-0">
                        <p className="font-medium text-gray-900 truncate">
                          {variation.name}
                        </p>
                        <p className="text-sm text-gray-500">
                          ${variation.quotedAmount.toLocaleString()}
                        </p>
                      </div>
                      <span className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium ${
                        variation.status === 'approved'
                          ? 'bg-emerald-50 text-emerald-600'
                          : 'bg-amber-50 text-amber-600'
                      }`}>
                        {variation.status === 'approved' ? (
                          <CheckCircle2 size={12} />
                        ) : (
                          <Clock size={12} />
                        )}
                        {variation.status === 'approved' ? 'Approved' : 'Pending'}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Images */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Progress Images
          </label>
          <div
            className={`border-2 border-dashed rounded-lg p-6 text-center ${
              dragActive ? 'border-teal-500 bg-teal-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {images.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {images.map((file, index) => (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${index + 1}`}
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="mt-4 flex text-sm text-gray-600 justify-center">
                  <label className="relative cursor-pointer rounded-md font-medium text-teal-600 hover:text-teal-500">
                    <span>Upload files</span>
                    <input
                      type="file"
                      className="sr-only"
                      multiple
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files) {
                          setImages(prev => [...prev, ...Array.from(e.target.files || [])]);
                        }
                      }}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB each</p>
              </>
            )}
          </div>
        </div>

        {/* Additional Comments */}
        <div>
          <FormField
            label="Additional Comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={4}
            placeholder="Add any additional comments or notes..."
          />
        </div>

        {/* Form Actions */}
        <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 rounded-lg transition-colors"
          >
            <Plus size={16} />
            {initialData ? 'Update Report' : 'Create Report'}
          </button>
        </div>
      </form>
    </FormModal>
  );
}