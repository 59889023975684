import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Users, Plus, LayoutGrid, Table2 } from 'lucide-react';
import { useClients } from '../../hooks/useClients';
import { useCompanies } from '../../hooks/useCompanies';
import { Client } from '../../types';
import ClientFormModal from '../modals/ClientFormModal';
import ClientCard from './ClientCard';
import ClientTable from './ClientTable';
import LoadingState from '../ui/LoadingState';
import ErrorState from '../ui/ErrorState';
import EmptyState from '../ui/EmptyState';
import ConfirmDialog from '../ui/ConfirmDialog';
import { useNotification } from '../../context/NotificationContext';
import { useSearch } from '../../hooks/useSearch';
import SearchAndFilter from '../ui/SearchAndFilter';
import { useViewMode } from '../../hooks/useViewMode';
import { useAppNavigation } from '../../routes/useNavigation';
import { useAuth } from '../../context/AuthContext';

export default function ClientList() {
  const { company } = useAuth();
  const navigation = useAppNavigation();
  const [viewMode, setViewMode] = useViewMode('clientListViewMode', 'card');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<Client | null>(null);
  const [clientToEdit, setClientToEdit] = useState<Client | null>(null);
  const { clients, loading, error, fetchClients, addClient, updateClient, deleteClient } = useClients();
  const { companies } = useCompanies();
  const { addNotification } = useNotification();

  const {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  } = useSearch({
    data: clients,
    searchFields: ['name', 'email', 'phone', 'address', 'contactName', 'billingEmail', 'website', 'clientId'],
    filterConfig: {
      status: (client, values) => values.includes(client.status || 'active'),
    },
  });

  const filters = [
    {
      id: 'status',
      label: 'Status',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
      ],
    },
  ];

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleDelete = (client: Client) => {
    setClientToDelete(client);
  };

  const handleEdit = (client: Client) => {
    setClientToEdit(client);
  };

  const handleConfirmDelete = async () => {
    if (clientToDelete) {
      try {
        await deleteClient(clientToDelete.id);
        addNotification('success', 'Client deleted successfully');
      } catch (error) {
        addNotification('error', 'Failed to delete client');
      }
      setClientToDelete(null);
    }
  };

  const handleSubmit = async (data: Partial<Client>) => {
    try {
      if (clientToEdit) {
        await updateClient({ ...data, id: clientToEdit.id } as Client);
        addNotification('success', 'Client updated successfully');
        setClientToEdit(null);
      } else {
        await addClient(data);
        addNotification('success', 'Client created successfully');
        setIsAddModalOpen(false);
      }
    } catch (error) {
      addNotification('error', `Failed to ${clientToEdit ? 'update' : 'create'} client`);
    }
  };

  const handleClientClick = (client: Client) => {
    navigation.goToClient(client.id);
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} onRetry={fetchClients} />;
  if (!clients.length) {
    return (
      <EmptyState
        title="No Clients Found"
        message="Get started by adding your first client."
        icon={<Users size={24} className="text-gray-400" />}
        actionLabel="Add Client"
        onAction={() => setIsAddModalOpen(true)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-violet-50 rounded-lg">
            <Users className="text-violet-600" size={24} />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Clients</h1>
            <p className="text-sm text-gray-500">
              {company?.name ? `Manage clients for ${company.name}` : 'Manage your clients'}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* View Toggle */}
          <div className="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewMode('card')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'card'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <LayoutGrid size={20} />
            </button>
            <button
              onClick={() => setViewMode('table')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'table'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Table2 size={20} />
            </button>
          </div>

          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-violet-500 rounded-lg hover:bg-violet-600 transition-colors"
          >
            <div className="flex items-center gap-2">
              <Plus size={16} />
              <span>Add Client</span>
            </div>
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <SearchAndFilter
        searchPlaceholder="Search clients..."
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={filters}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
        showFilters={showFilters}
        onToggleFilters={toggleFilters}
      />

      {/* Clients List */}
      {viewMode === 'card' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((client) => (
            <ClientCard
              key={client.id}
              client={client}
              onSelect={() => handleClientClick(client)}
              onEdit={() => handleEdit(client)}
              onDelete={() => handleDelete(client)}
            />
          ))}
        </div>
      ) : (
        <ClientTable
          clients={filteredData}
          onSelect={handleClientClick}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {/* Add/Edit Client Modal */}
      {(isAddModalOpen || clientToEdit) && (
        <ClientFormModal
          isOpen={true}
          onClose={() => {
            setIsAddModalOpen(false);
            setClientToEdit(null);
          }}
          onSubmit={handleSubmit}
          initialData={clientToEdit || undefined}
          companyId={company?.id || ''}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={!!clientToDelete}
        onClose={() => setClientToDelete(null)}
        onConfirm={handleConfirmDelete}
        title="Delete Client"
        message={`Are you sure you want to delete ${clientToDelete?.name}? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </div>
  );
}