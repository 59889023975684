import React from 'react';
import { motion } from 'framer-motion';
import { 
  Briefcase, FileText, DollarSign,
  Bell, ArrowUpRight, Clock, CheckCircle2,
  Eye, Calendar, Target, AlertTriangle,
  TrendingUp, Users, Building2
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useJobs } from '../../../hooks/useJobs';
import { useReports } from '../../../hooks/useReports';
import { useInvoices } from '../../../hooks/useInvoices';
import { useVariations } from '../../../hooks/useVariations';
import MetricCard from '../metrics/MetricCard';

export default function ClientDashboard() {
  const navigate = useNavigate();
  const { user, company } = useAuth();
  const { jobs } = useJobs();
  const { reports } = useReports();
  const { invoices } = useInvoices();
  const { variations } = useVariations();

  // Filter data for this client
  const clientJobs = jobs.filter(j => j.clientId === user?.clientId);
  const clientReports = reports.filter(r => r.clientId === user?.clientId);
  const clientInvoices = invoices.filter(i => i.clientId === user?.clientId);
  const clientVariations = variations.filter(v => v.clientId === user?.clientId);
  
  // Calculate metrics
  const activeJobs = clientJobs.filter(job => job.status === 'in-progress');
  const totalContractValue = clientJobs.reduce((sum, job) => sum + job.contractAmount, 0);
  const totalInvoiced = clientJobs.reduce((sum, job) => sum + job.totalInvoiced, 0);
  const pendingVariations = clientVariations.filter(v => v.status === 'pending_approval');

  const metrics = [
    {
      label: 'Active Projects',
      value: activeJobs.length,
      icon: Briefcase,
      trend: `${activeJobs.length} in progress`,
      color: 'blue'
    },
    {
      label: 'Contract Value',
      value: totalContractValue,
      icon: DollarSign,
      trend: `${Math.round((totalInvoiced / totalContractValue) * 100)}% invoiced`,
      color: 'emerald'
    },
    {
      label: 'Progress Reports',
      value: clientReports.length,
      icon: FileText,
      trend: 'Up to date',
      color: 'violet'
    },
    {
      label: 'Recent Views',
      value: 12,
      icon: Eye,
      trend: 'Last 7 days',
      color: 'amber'
    }
  ];

  // Get recent reports
  const recentReports = clientReports
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 5);

  // Get upcoming milestones
  const upcomingMilestones = clientJobs
    .filter(job => job.status === 'in-progress')
    .flatMap(job => [
      {
        date: new Date(job.estimatedEndDate),
        title: 'Project Completion',
        job: job.name,
        type: 'completion' as const
      },
      // Add more milestone types as needed
    ])
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .slice(0, 5);

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Project Overview</h1>
          <p className="text-gray-500">Welcome back to your project dashboard.</p>
        </div>
        <button className="relative p-2 text-gray-400 hover:text-gray-500 rounded-lg hover:bg-gray-100">
          <Bell size={20} />
          <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
        </button>
      </div>

      {/* Company Info Card */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
      >
        <div className="flex items-center gap-4">
          {company?.logo ? (
            <img 
              src={company.logo} 
              alt={company.name}
              className="w-16 h-16 rounded-xl object-cover"
            />
          ) : (
            <div className="w-16 h-16 bg-gray-100 rounded-xl flex items-center justify-center">
              <Building2 className="text-gray-400" size={32} />
            </div>
          )}
          <div>
            <h2 className="text-xl font-semibold text-gray-900">{company?.name}</h2>
            <div className="flex items-center gap-4 mt-1">
              <span className="text-sm text-gray-500">
                <Users className="inline-block w-4 h-4 mr-1" />
                Your Construction Partner
              </span>
              <button className="text-sm text-blue-600 hover:text-blue-700">
                Contact Details
              </button>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Project Progress */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <TrendingUp className="text-emerald-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Project Progress</h2>
            </div>
          </div>

          <div className="space-y-6">
            {activeJobs.map((job) => (
              <motion.div
                key={job.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="space-y-2"
              >
                <div className="flex items-center justify-between">
                  <h3 className="font-medium text-gray-900">{job.name}</h3>
                  <span className="text-sm text-gray-500">
                    ${job.totalInvoiced.toLocaleString()} of ${job.contractAmount.toLocaleString()}
                  </span>
                </div>
                <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <motion.div
                    className="h-full bg-emerald-500"
                    initial={{ width: 0 }}
                    animate={{ width: `${(job.totalInvoiced / job.contractAmount) * 100}%` }}
                    transition={{ duration: 1, ease: "easeOut" }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Recent Reports */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-violet-50 rounded-lg">
                <FileText className="text-violet-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Recent Reports</h2>
            </div>
            <button 
              onClick={() => navigate('/reports')}
              className="text-sm text-violet-600 hover:text-violet-700"
            >
              View All
            </button>
          </div>

          <div className="space-y-4">
            {recentReports.map((report) => (
              <motion.div
                key={report.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 cursor-pointer group"
                onClick={() => navigate(`/reports/${report.id}`)}
                whileHover={{ x: 4 }}
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-white rounded-lg">
                    <FileText size={16} className="text-violet-500" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{report.reportId}</p>
                    <p className="text-sm text-gray-500">{report.jobName}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-500">
                    {new Date(report.date).toLocaleDateString()}
                  </span>
                  <ArrowUpRight 
                    size={16} 
                    className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Alerts & Milestones */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Important Updates */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <AlertTriangle className="text-amber-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Important Updates</h2>
            </div>
          </div>

          <div className="space-y-4">
            {pendingVariations.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-4 bg-amber-50 rounded-xl border border-amber-100"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <DollarSign size={20} className="text-amber-600" />
                    <div>
                      <p className="font-medium text-gray-900">Pending Variations</p>
                      <p className="text-sm text-amber-600">
                        {pendingVariations.length} variation{pendingVariations.length !== 1 ? 's' : ''} need your review
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => navigate('/variations')}
                    className="text-amber-600 hover:text-amber-700"
                  >
                    <ArrowUpRight size={20} />
                  </button>
                </div>
              </motion.div>
            )}
          </div>
        </div>

        {/* Upcoming Milestones */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Target className="text-blue-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Project Milestones</h2>
            </div>
          </div>

          <div className="space-y-4">
            {upcomingMilestones.map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 cursor-pointer group"
                whileHover={{ y: -2 }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-blue-100 rounded-lg">
                      <CheckCircle2 size={16} className="text-blue-600" />
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">{milestone.title}</p>
                      <p className="text-sm text-gray-500">{milestone.job}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-500">
                      {milestone.date.toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}