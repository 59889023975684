import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link2, CheckCircle2, AlertCircle, Loader2 } from 'lucide-react';
import { usePermissions } from '../../hooks/usePermissions';
import { useNotification } from '../../context/NotificationContext';

interface Integration {
  id: string;
  name: string;
  description: string;
  icon: string;
  connected: boolean;
  status?: 'connected' | 'error' | 'pending';
  lastSync?: Date;
}

export default function IntegrationSettings() {
  const { canManageIntegrations } = usePermissions();
  const { addNotification } = useNotification();
  const [connecting, setConnecting] = useState<string | null>(null);

  const integrations: Integration[] = [
    {
      id: 'xero',
      name: 'Xero',
      description: 'Connect your Xero account to sync invoices and payments',
      icon: 'https://www.xero.com/favicon.ico',
      connected: false
    },
    {
      id: 'stripe',
      name: 'Stripe',
      description: 'Process payments and manage subscriptions',
      icon: 'https://stripe.com/favicon.ico',
      connected: true,
      status: 'connected',
      lastSync: new Date()
    },
    {
      id: 'microsoft',
      name: 'Microsoft 365',
      description: 'Sync calendar events and documents',
      icon: 'https://www.microsoft.com/favicon.ico',
      connected: false
    }
  ];

  const handleConnect = async (integrationId: string) => {
    if (!canManageIntegrations) {
      addNotification('error', 'You do not have permission to manage integrations');
      return;
    }

    setConnecting(integrationId);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      addNotification('success', 'Integration connected successfully');
    } catch (error) {
      addNotification('error', 'Failed to connect integration');
    } finally {
      setConnecting(null);
    }
  };

  const handleDisconnect = async (integrationId: string) => {
    if (!canManageIntegrations) {
      addNotification('error', 'You do not have permission to manage integrations');
      return;
    }

    setConnecting(integrationId);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      addNotification('success', 'Integration disconnected successfully');
    } catch (error) {
      addNotification('error', 'Failed to disconnect integration');
    } finally {
      setConnecting(null);
    }
  };

  if (!canManageIntegrations) {
    return (
      <div className="text-center py-12">
        <Link2 className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Access</h3>
        <p className="mt-1 text-sm text-gray-500">
          You don't have permission to manage integrations.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Link2 className="text-blue-600" size={20} />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Integrations</h2>
          <p className="text-sm text-gray-500">Connect your tools and services</p>
        </div>
      </div>

      <div className="space-y-4">
        {integrations.map((integration) => (
          <motion.div
            key={integration.id}
            className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img
                  src={integration.icon}
                  alt={`${integration.name} logo`}
                  className="w-12 h-12 rounded-lg object-contain bg-gray-50 p-2"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="font-semibold text-gray-900">
                      {integration.name}
                    </h3>
                    {integration.connected && (
                      <span className="flex items-center gap-1 text-xs font-medium text-emerald-600 bg-emerald-50 px-2 py-1 rounded-full">
                        <CheckCircle2 size={12} />
                        Connected
                      </span>
                    )}
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    {integration.description}
                  </p>
                  {integration.lastSync && (
                    <p className="text-xs text-gray-400 mt-1">
                      Last synced: {integration.lastSync.toLocaleString()}
                    </p>
                  )}
                </div>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => integration.connected 
                  ? handleDisconnect(integration.id)
                  : handleConnect(integration.id)
                }
                disabled={connecting === integration.id}
                className={`
                  flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors
                  ${integration.connected
                    ? 'text-gray-700 hover:bg-gray-100'
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                  }
                  disabled:opacity-50 disabled:cursor-not-allowed
                `}
              >
                {connecting === integration.id ? (
                  <>
                    <Loader2 size={16} className="animate-spin" />
                    <span>
                      {integration.connected ? 'Disconnecting...' : 'Connecting...'}
                    </span>
                  </>
                ) : (
                  <>
                    {integration.connected ? (
                      <>
                        <AlertCircle size={16} />
                        <span>Disconnect</span>
                      </>
                    ) : (
                      <>
                        <Link2 size={16} />
                        <span>Connect</span>
                      </>
                    )}
                  </>
                )}
              </motion.button>
            </div>

            {integration.status === 'error' && (
              <div className="mt-4 p-4 bg-red-50 rounded-lg">
                <div className="flex items-center gap-2 text-red-600">
                  <AlertCircle size={16} />
                  <p className="text-sm font-medium">Connection Error</p>
                </div>
                <p className="text-sm text-red-500 mt-1">
                  There was a problem with the connection. Please try reconnecting.
                </p>
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}