import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  DollarSign, 
  Calendar, 
  Building2, 
  Briefcase,
  Plus,
  Trash2,
  Calculator,
  FileText,
  Link
} from 'lucide-react';
import { useFormValidation } from '../../hooks/useFormValidation';
import { Invoice, InvoiceItem } from '../../types/invoice';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import FormModal from './FormModal';
import FormField from '../ui/FormField';
import FormError from '../ui/FormError';

interface InvoiceFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Invoice>) => void;
  initialData?: Partial<Invoice>;
  companyId: string;
}

export default function InvoiceFormModal({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  companyId,
}: InvoiceFormModalProps) {
  const { clients } = useClients();
  const { jobs } = useJobs();
  const [items, setItems] = useState<InvoiceItem[]>(initialData?.items || []);
  const [selectedClient, setSelectedClient] = useState<string>(initialData?.clientId || '');
  const [selectedJob, setSelectedJob] = useState<string>(initialData?.items?.[0]?.jobId || '');

  const {
    formData,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
    setFormData,
  } = useFormValidation(initialData || {
    companyId,
    clientId: '',
    invoiceNumber: '',
    reference: '',
    date: new Date().toISOString().split('T')[0],
    dueDate: '',
    status: 'draft',
    items: [],
    subtotal: 0,
    taxTotal: 0,
    total: 0,
    amountPaid: 0,
    balance: 0,
    paymentTerms: 'Net 30',
    notes: '',
  }, {
    clientId: [{ required: true, message: 'Client is required' }],
    invoiceNumber: [{ required: true, message: 'Invoice number is required' }],
    reference: [{ required: true, message: 'Reference is required' }],
    date: [{ required: true, message: 'Date is required' }],
    dueDate: [{ required: true, message: 'Due date is required' }],
  });

  // Filter clients by company
  const companyClients = clients.filter(client => client.companyId === companyId);
  
  // Filter jobs by selected client
  const clientJobs = jobs.filter(job => job.clientId === selectedClient);

  useEffect(() => {
    if (selectedClient) {
      setFormData(prev => ({ ...prev, clientId: selectedClient }));
    }
  }, [selectedClient, setFormData]);

  const calculateTotals = () => {
    const subtotal = items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
    const taxTotal = items.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.taxRate), 0);
    const total = subtotal + taxTotal;

    return { subtotal, taxTotal, total };
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    const totals = calculateTotals();
    onSubmit({
      ...formData,
      items,
      ...totals,
      balance: totals.total,
    });
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        id: Math.random().toString(36).substr(2, 9),
        description: '',
        quantity: 1,
        unitPrice: 0,
        taxRate: 0.15,
        jobId: selectedJob,
      },
    ]);
  };

  const updateItem = (index: number, updates: Partial<InvoiceItem>) => {
    setItems(items.map((item, i) => 
      i === index ? { ...item, ...updates } : item
    ));
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const { subtotal, taxTotal, total } = calculateTotals();

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      title={initialData ? 'Edit Invoice' : 'Create Invoice'}
      size="xl"
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Header Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gray-50 p-6 rounded-xl">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Client
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Select Client</option>
              {companyClients.map(client => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            <FormError errors={errors.clientId} touched={touched.clientId} />
          </div>

          <div>
            <FormField
              label="Invoice Number"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<FileText size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.invoiceNumber} touched={touched.invoiceNumber} />
          </div>

          <div>
            <FormField
              label="Reference"
              name="reference"
              value={formData.reference}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<FileText size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.reference} touched={touched.reference} />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Link to Job
            </label>
            <select
              value={selectedJob}
              onChange={(e) => setSelectedJob(e.target.value)}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Job (Optional)</option>
              {clientJobs.map(job => (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Dates and Terms */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <FormField
              label="Invoice Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<Calendar size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.date} touched={touched.date} />
          </div>

          <div>
            <FormField
              label="Due Date"
              name="dueDate"
              type="date"
              value={formData.dueDate}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              icon={<Calendar size={16} className="text-gray-400" />}
            />
            <FormError errors={errors.dueDate} touched={touched.dueDate} />
          </div>

          <div>
            <FormField
              label="Payment Terms"
              name="paymentTerms"
              value={formData.paymentTerms}
              onChange={handleChange}
              onBlur={handleBlur}
              icon={<Calendar size={16} className="text-gray-400" />}
            />
          </div>
        </div>

        {/* Line Items */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900">Line Items</h3>
            <button
              type="button"
              onClick={addItem}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
            >
              <Plus size={16} />
              Add Item
            </button>
          </div>

          <div className="space-y-4">
            {items.map((item, index) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="grid grid-cols-12 gap-4 p-4 bg-gray-50 rounded-lg"
              >
                <div className="col-span-4">
                  <FormField
                    label="Description"
                    name={`items.${index}.description`}
                    value={item.description}
                    onChange={(e) => updateItem(index, { description: e.target.value })}
                    required
                  />
                </div>
                <div className="col-span-2">
                  <FormField
                    label="Quantity"
                    name={`items.${index}.quantity`}
                    type="number"
                    value={item.quantity.toString()}
                    onChange={(e) => updateItem(index, { quantity: parseFloat(e.target.value) || 0 })}
                    required
                    min="0"
                    step="0.01"
                  />
                </div>
                <div className="col-span-2">
                  <FormField
                    label="Unit Price"
                    name={`items.${index}.unitPrice`}
                    type="number"
                    value={item.unitPrice.toString()}
                    onChange={(e) => updateItem(index, { unitPrice: parseFloat(e.target.value) || 0 })}
                    required
                    prefix="$"
                    min="0"
                    step="0.01"
                  />
                </div>
                <div className="col-span-2">
                  <FormField
                    label="Tax Rate"
                    name={`items.${index}.taxRate`}
                    type="number"
                    value={(item.taxRate * 100).toString()}
                    onChange={(e) => updateItem(index, { taxRate: parseFloat(e.target.value) / 100 || 0 })}
                    required
                    suffix="%"
                    min="0"
                    max="100"
                    step="0.1"
                  />
                </div>
                <div className="col-span-2 flex items-end">
                  <button
                    type="button"
                    onClick={() => removeItem(index)}
                    className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <Trash2 size={16} />
                    Remove
                  </button>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Totals */}
          {items.length > 0 && (
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="w-full max-w-sm ml-auto space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Subtotal:</span>
                  <span className="font-medium">${subtotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Tax Total:</span>
                  <span className="font-medium">${taxTotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-base font-medium pt-2 border-t border-gray-200">
                  <span>Total:</span>
                  <span>${total.toFixed(2)}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Notes */}
        <div>
          <FormField
            label="Notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={4}
            placeholder="Add any additional notes or payment instructions..."
          />
        </div>

        {/* Form Actions */}
        <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors"
          >
            <DollarSign size={16} />
            {initialData ? 'Update Invoice' : 'Create Invoice'}
          </button>
        </div>
      </form>
    </FormModal>
  );
}