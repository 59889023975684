import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  GitBranch, 
  Upload, 
  X, 
  DollarSign, 
  Calendar, 
  User,
  Link,
  Image as ImageIcon,
  FileText,
  Plus
} from 'lucide-react';
import { Variation, VariationFormData } from '../../types/variation';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import FormModal from './FormModal';
import FormField from '../ui/FormField';
import FormError from '../ui/FormError';
import { useFormValidation } from '../../hooks/useFormValidation';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';

interface VariationFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Variation>) => void;
  initialData?: Partial<Variation>;
  companyId: string;
  clientId?: string;
  jobId?: string;
}

export default function VariationFormModal({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  companyId,
  clientId: defaultClientId,
  jobId: defaultJobId,
}: VariationFormModalProps) {
  const { user } = useAuth();
  const { addNotification } = useNotification();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const [selectedClient, setSelectedClient] = useState(defaultClientId || '');
  const [selectedJob, setSelectedJob] = useState(defaultJobId || '');
  const [images, setImages] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);

  const {
    formData,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
    setFormData,
  } = useFormValidation<VariationFormData>(initialData || {
    variationId: `VAR-${new Date().getFullYear()}-${Math.floor(Math.random() * 1000).toString().padStart(3, '0')}`,
    name: '',
    description: '',
    status: 'pending_approval',
    progressStatus: 'pending',
    dateAdded: new Date().toISOString().split('T')[0],
    invoiceStatus: 'pending',
    quotedAmount: 0,
    cost: 0,
    projectManagerId: '',
    clientId: defaultClientId || '',
    jobId: defaultJobId || '',
    images: [],
    files: [],
    createdById: user?.id || '',
    showInJobReport: true,
    companyId,
  }, {
    name: [{ required: true, message: 'Name is required' }],
    description: [{ required: true, message: 'Description is required' }],
    quotedAmount: [
      { required: true, message: 'Quoted amount is required' },
      { min: 0, message: 'Amount must be positive' },
    ],
    cost: [
      { required: true, message: 'Cost is required' },
      { min: 0, message: 'Cost must be positive' },
    ],
  });

  // Filter clients by company
  const companyClients = clients.filter(client => client.companyId === companyId);
  
  // Filter jobs by selected client
  const clientJobs = jobs.filter(job => job.clientId === selectedClient);

  useEffect(() => {
    if (selectedClient) {
      setFormData(prev => ({ ...prev, clientId: selectedClient }));
    }
  }, [selectedClient, setFormData]);

  useEffect(() => {
    if (selectedJob) {
      setFormData(prev => ({ ...prev, jobId: selectedJob }));
    }
  }, [selectedJob, setFormData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    // In a real app, handle file uploads here
    onSubmit(formData);
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files) {
      const imageFiles = Array.from(e.dataTransfer.files).filter(file => 
        file.type.startsWith('image/')
      );
      const documentFiles = Array.from(e.dataTransfer.files).filter(file => 
        !file.type.startsWith('image/')
      );
      
      setImages(prev => [...prev, ...imageFiles]);
      setFiles(prev => [...prev, ...documentFiles]);
    }
  };

  const removeImage = (index: number) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  const removeFile = (index: number) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      title={initialData ? 'Edit Variation' : 'Create Variation'}
      size="xl"
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Variation Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <FormError errors={errors.name} touched={touched.name} />
          </div>

          <div>
            <FormField
              label="Variation ID"
              name="variationId"
              value={formData.variationId}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </div>
        </div>

        {/* Description */}
        <div>
          <FormField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            multiline
            rows={4}
          />
          <FormError errors={errors.description} touched={touched.description} />
        </div>

        {/* Client and Job Selection */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Client
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
              required
            >
              <option value="">Select Client</option>
              {companyClients.map(client => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Link to Job
            </label>
            <select
              value={selectedJob}
              onChange={(e) => setSelectedJob(e.target.value)}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              <option value="">Select Job (Optional)</option>
              {clientJobs.map(job => (
                <option key={job.id} value={job.id}>
                  {job.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Financial Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <FormField
              label="Quoted Amount"
              name="quotedAmount"
              type="number"
              value={formData.quotedAmount?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              prefix="$"
              min="0"
              step="0.01"
            />
            <FormError errors={errors.quotedAmount} touched={touched.quotedAmount} />
          </div>

          <div>
            <FormField
              label="Cost"
              name="cost"
              type="number"
              value={formData.cost?.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              prefix="$"
              min="0"
              step="0.01"
            />
            <FormError errors={errors.cost} touched={touched.cost} />
          </div>
        </div>

        {/* Status and Progress */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Status
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              <option value="pending_approval">Pending Approval</option>
              <option value="approved">Approved</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Progress Status
            </label>
            <select
              name="progressStatus"
              value={formData.progressStatus}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              <option value="pending">Pending</option>
              <option value="in_progress">In Progress</option>
              <option value="completed">Completed</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Invoice Status
            </label>
            <select
              name="invoiceStatus"
              value={formData.invoiceStatus}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              <option value="pending">Pending</option>
              <option value="invoiced">Invoiced</option>
            </select>
          </div>
        </div>

        {/* Images Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Images
          </label>
          <div
            className={`border-2 border-dashed rounded-lg p-6 text-center ${
              dragActive ? 'border-violet-500 bg-violet-50' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="mt-4 flex text-sm text-gray-600 justify-center">
              <label className="relative cursor-pointer rounded-md font-medium text-violet-600 hover:text-violet-500">
                <span>Upload files</span>
                <input
                  type="file"
                  className="sr-only"
                  multiple
                  accept="image/*,.pdf,.doc,.docx"
                  onChange={(e) => {
                    if (e.target.files) {
                      const imageFiles = Array.from(e.target.files).filter(file => 
                        file.type.startsWith('image/')
                      );
                      const documentFiles = Array.from(e.target.files).filter(file => 
                        !file.type.startsWith('image/')
                      );
                      
                      setImages(prev => [...prev, ...imageFiles]);
                      setFiles(prev => [...prev, ...documentFiles]);
                    }
                  }}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">
              Images, PDFs, and documents up to 10MB each
            </p>
          </div>

          {/* Preview Images */}
          {images.length > 0 && (
            <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
              {images.map((file, index) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <X size={12} />
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Preview Files */}
          {files.length > 0 && (
            <div className="mt-4 space-y-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center gap-3">
                    <FileText size={20} className="text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">{file.name}</p>
                      <p className="text-xs text-gray-500">
                        {(file.size / 1024 / 1024).toFixed(2)} MB
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                  >
                    <X size={16} className="text-gray-500" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Show in Job Report */}
        <div>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              name="showInJobReport"
              checked={formData.showInJobReport}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                showInJobReport: e.target.checked 
              }))}
              className="w-4 h-4 text-violet-600 border-gray-300 rounded focus:ring-violet-500"
            />
            <span className="text-sm text-gray-700">Show in Job Report</span>
          </label>
        </div>

        {/* Form Actions */}
        <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-violet-500 hover:bg-violet-600 rounded-lg transition-colors"
          >
            <Plus size={16} />
            {initialData ? 'Update Variation' : 'Create Variation'}
          </button>
        </div>
      </form>
    </FormModal>
  );
}