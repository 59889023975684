import React from 'react';
import { motion } from 'framer-motion';
import { 
  Users, Briefcase, FileText, DollarSign,
  Bell, Plus, ArrowUpRight, TrendingUp,
  Clock, CheckCircle2, AlertTriangle,
  ClipboardList, LayoutGrid, Calendar,
  Target, Building2
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useClients } from '../../../hooks/useClients';
import { useJobs } from '../../../hooks/useJobs';
import { useReports } from '../../../hooks/useReports';
import { useInvoices } from '../../../hooks/useInvoices';
import { useVariations } from '../../../hooks/useVariations';
import { useTasks } from '../../../hooks/useTasks';
import MetricCard from '../metrics/MetricCard';
import { ROUTE_PATHS } from '../../../routes/config';

export default function CompanyDashboard() {
  const navigate = useNavigate();
  const { user, company } = useAuth();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { reports } = useReports();
  const { invoices } = useInvoices();
  const { variations } = useVariations();
  const { tasks } = useTasks();

  // Filter data by company
  const companyClients = clients.filter(c => c.companyId === company?.id);
  const companyJobs = jobs.filter(j => j.companyId === company?.id);
  const companyReports = reports.filter(r => r.companyId === company?.id);
  const companyInvoices = invoices.filter(i => i.companyId === company?.id);
  const companyVariations = variations.filter(v => v.companyId === company?.id);
  const companyTasks = tasks.filter(t => t.companyId === company?.id);
  
  // Calculate key metrics
  const activeJobs = companyJobs.filter(job => job.status === 'in-progress');
  const totalRevenue = companyInvoices.reduce((sum, inv) => sum + inv.total, 0);
  const pendingInvoices = companyInvoices.filter(inv => inv.status === 'pending');
  const pendingVariations = companyVariations.filter(v => v.status === 'pending_approval');
  const overdueTasks = companyTasks.filter(t => 
    new Date(t.dueDate) < new Date() && t.status !== 'completed'
  );

  const metrics = [
    {
      label: 'Active Clients',
      value: companyClients.length,
      icon: Users,
      trend: '+12%',
      color: 'blue'
    },
    {
      label: 'Active Jobs',
      value: activeJobs.length,
      icon: Briefcase,
      trend: '+8%',
      color: 'violet'
    },
    {
      label: 'Total Reports',
      value: companyReports.length,
      icon: FileText,
      trend: '+15%',
      color: 'emerald'
    },
    {
      label: 'Revenue',
      value: totalRevenue,
      icon: DollarSign,
      trend: '+24%',
      color: 'amber'
    }
  ];

  // Task Quick Links
  const taskViews = [
    {
      title: 'Task Board',
      description: 'Manage tasks in a Kanban-style board',
      icon: LayoutGrid,
      path: ROUTE_PATHS.TASK_BOARD,
      color: 'violet'
    },
    {
      title: 'Task Calendar',
      description: 'View tasks in a calendar layout',
      icon: Calendar,
      path: ROUTE_PATHS.TASK_CALENDAR,
      color: 'blue'
    },
    {
      title: 'All Tasks',
      description: 'View and manage all tasks',
      icon: ClipboardList,
      path: ROUTE_PATHS.TASKS,
      color: 'emerald'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
          <p className="text-gray-500">Welcome back! Here's what's happening at {company?.name}.</p>
        </div>
        <div className="flex items-center gap-4">
          <button className="relative p-2 text-gray-400 hover:text-gray-500 rounded-lg hover:bg-gray-100">
            <Bell size={20} />
            <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
          </button>
          <button
            onClick={() => navigate('/reports/new')}
            className="flex items-center gap-2 px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
          >
            <Plus size={20} />
            <span>New Report</span>
          </button>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>

      {/* Task Management Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-violet-50 rounded-lg">
              <ClipboardList className="text-violet-600" size={20} />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Task Management</h2>
              <p className="text-sm text-gray-500">Quick access to task views</p>
            </div>
          </div>
          <button
            onClick={() => navigate(ROUTE_PATHS.TASKS)}
            className="text-sm text-violet-600 hover:text-violet-700"
          >
            View All Tasks
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {taskViews.map((view, index) => (
            <motion.div
              key={view.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`p-6 bg-${view.color}-50 rounded-xl cursor-pointer group`}
              onClick={() => navigate(view.path)}
              whileHover={{ y: -4 }}
            >
              <div className="flex items-center gap-3 mb-4">
                <div className={`p-2 bg-${view.color}-100 rounded-lg`}>
                  <view.icon className={`text-${view.color}-600`} size={20} />
                </div>
                <h3 className="font-semibold text-gray-900">{view.title}</h3>
              </div>
              <p className="text-sm text-gray-600 mb-4">{view.description}</p>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-500">
                  {view.title === 'Task Board' && `${companyTasks.length} tasks`}
                  {view.title === 'Task Calendar' && `${overdueTasks.length} overdue`}
                  {view.title === 'All Tasks' && 'View all tasks'}
                </span>
                <ArrowUpRight 
                  size={16} 
                  className={`text-${view.color}-500 opacity-0 group-hover:opacity-100 transition-opacity`} 
                />
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Recent Activity */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-violet-50 rounded-lg">
                <Clock className="text-violet-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Recent Activity</h2>
            </div>
          </div>

          <div className="space-y-4">
            {companyTasks.slice(0, 5).map((task, index) => (
              <motion.div
                key={task.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/tasks/${task.id}`)}
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-white rounded-lg">
                    <ClipboardList size={16} className="text-violet-500" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{task.title}</p>
                    <p className="text-sm text-gray-500">Due {new Date(task.dueDate).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${
                    task.status === 'completed'
                      ? 'bg-emerald-50 text-emerald-600'
                      : 'bg-amber-50 text-amber-600'
                  }`}>
                    {task.status === 'completed' ? (
                      <CheckCircle2 size={12} />
                    ) : (
                      <Clock size={12} />
                    )}
                    {task.status.split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </span>
                  <ArrowUpRight size={16} className="text-gray-400" />
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Alerts & Notifications */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <AlertTriangle className="text-amber-600" size={20} />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Attention Required</h2>
            </div>
          </div>

          <div className="space-y-4">
            {overdueTasks.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-4 bg-amber-50 rounded-xl border border-amber-100"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <Clock size={20} className="text-amber-600" />
                    <div>
                      <p className="font-medium text-gray-900">Overdue Tasks</p>
                      <p className="text-sm text-amber-600">
                        {overdueTasks.length} task{overdueTasks.length !== 1 ? 's' : ''} past due date
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => navigate(ROUTE_PATHS.TASKS)}
                    className="text-amber-600 hover:text-amber-700"
                  >
                    <ArrowUpRight size={20} />
                  </button>
                </div>
              </motion.div>
            )}

            {pendingVariations.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="p-4 bg-violet-50 rounded-xl border border-violet-100"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <TrendingUp size={20} className="text-violet-600" />
                    <div>
                      <p className="font-medium text-gray-900">Pending Variations</p>
                      <p className="text-sm text-violet-600">
                        {pendingVariations.length} variation{pendingVariations.length !== 1 ? 's' : ''} need approval
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => navigate('/variations')}
                    className="text-violet-600 hover:text-violet-700"
                  >
                    <ArrowUpRight size={20} />
                  </button>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}