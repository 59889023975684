import { mockData } from './mockData';
import { db } from '../lib/db';

// Service to handle mock data operations
export const mockDataService = {
  // Generic get function
  async get<T>(collection: keyof typeof mockData, id?: string): Promise<T | T[]> {
    const data = mockData[collection];
    if (id) {
      return data.find(item => item.id === id) as T;
    }
    return data as T[];
  },

  // Generic create function
  async create<T extends { id: string }>(
    collection: keyof typeof mockData,
    data: Omit<T, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<T> {
    const id = Math.random().toString(36).substr(2, 9);
    const now = new Date();
    const newItem = {
      ...data,
      id,
      createdAt: now,
      updatedAt: now,
    } as T;
    
    mockData[collection].push(newItem);
    return newItem;
  },

  // Generic update function
  async update<T extends { id: string }>(
    collection: keyof typeof mockData,
    id: string,
    updates: Partial<T>
  ): Promise<T> {
    const index = mockData[collection].findIndex(item => item.id === id);
    if (index === -1) throw new Error('Item not found');

    const updatedItem = {
      ...mockData[collection][index],
      ...updates,
      updatedAt: new Date(),
    } as T;

    mockData[collection][index] = updatedItem;
    return updatedItem;
  },

  // Generic delete function
  async delete(collection: keyof typeof mockData, id: string): Promise<void> {
    const index = mockData[collection].findIndex(item => item.id === id);
    if (index === -1) throw new Error('Item not found');
    mockData[collection].splice(index, 1);
  },

  // Filtered queries
  async query<T>(
    collection: keyof typeof mockData,
    filter: (item: T) => boolean
  ): Promise<T[]> {
    return mockData[collection].filter(filter) as T[];
  }
};

// Export mock data for direct access if needed
export { mockData };