import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FileText, Plus, LayoutGrid, Table2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useReports } from '../../hooks/useReports';
import { useCompanies } from '../../hooks/useCompanies';
import { useClients } from '../../hooks/useClients';
import { useJobs } from '../../hooks/useJobs';
import { Report } from '../../types';
import ReportFormModal from '../modals/ReportFormModal';
import ReportCard from './ReportCard';
import ReportTable from './ReportTable';
import LoadingState from '../ui/LoadingState';
import ErrorState from '../ui/ErrorState';
import EmptyState from '../ui/EmptyState';
import ConfirmDialog from '../ui/ConfirmDialog';
import { useNotification } from '../../context/NotificationContext';
import { useSearch } from '../../hooks/useSearch';
import SearchAndFilter from '../ui/SearchAndFilter';
import { useViewMode } from '../../hooks/useViewMode';
import { useAppNavigation } from '../../routes/useNavigation';

export default function ReportList() {
  const navigation = useAppNavigation();
  const [viewMode, setViewMode] = useViewMode('reportListViewMode', 'card');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<Report | null>(null);
  const [reportToEdit, setReportToEdit] = useState<Report | null>(null);
  const { reports, loading, error, fetchReports, addReport, updateReport, deleteReport } = useReports();
  const { companies } = useCompanies();
  const { clients } = useClients();
  const { jobs } = useJobs();
  const { addNotification } = useNotification();

  const {
    searchTerm,
    showFilters,
    activeFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    toggleFilters,
  } = useSearch({
    data: reports,
    searchFields: ['reportId', 'jobName', 'clientName'],
    filterConfig: {
      client: (report, values) => values.length === 0 || values.includes(report.clientId),
      job: (report, values) => values.length === 0 || values.includes(report.jobId),
      progress: (report, values) => {
        if (values.length === 0) return true;
        const [min, max] = values[0].split('-').map(Number);
        return report.progressPercentage >= min && report.progressPercentage <= max;
      },
      date: (report, values) => {
        if (values.length === 0) return true;
        const reportDate = new Date(report.date).getTime();
        const today = new Date().getTime();
        const thirtyDaysAgo = today - (30 * 24 * 60 * 60 * 1000);
        const ninetyDaysAgo = today - (90 * 24 * 60 * 60 * 1000);
        
        switch (values[0]) {
          case 'last-30':
            return reportDate >= thirtyDaysAgo;
          case 'last-90':
            return reportDate >= ninetyDaysAgo;
          case 'older':
            return reportDate < ninetyDaysAgo;
          default:
            return true;
        }
      }
    },
  });

  const filters = [
    {
      id: 'client',
      label: 'Client',
      options: clients.map(client => ({
        value: client.id,
        label: client.name
      }))
    },
    {
      id: 'job',
      label: 'Job',
      options: jobs.map(job => ({
        value: job.id,
        label: job.name
      }))
    },
    {
      id: 'progress',
      label: 'Progress',
      options: [
        { value: '0-25', label: '0-25%' },
        { value: '26-50', label: '26-50%' },
        { value: '51-75', label: '51-75%' },
        { value: '76-100', label: '76-100%' }
      ]
    },
    {
      id: 'date',
      label: 'Date',
      options: [
        { value: 'last-30', label: 'Last 30 Days' },
        { value: 'last-90', label: 'Last 90 Days' },
        { value: 'older', label: 'Older' }
      ]
    }
  ];

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const handleDelete = (report: Report) => {
    setReportToDelete(report);
  };

  const handleEdit = (report: Report) => {
    setReportToEdit(report);
  };

  const handleConfirmDelete = async () => {
    if (reportToDelete) {
      try {
        await deleteReport(reportToDelete.id);
        addNotification('success', 'Report deleted successfully');
      } catch (error) {
        addNotification('error', 'Failed to delete report');
      }
      setReportToDelete(null);
    }
  };

  const handleSubmit = async (data: Partial<Report>) => {
    try {
      let savedReport;
      if (reportToEdit) {
        savedReport = await updateReport({ ...data, id: reportToEdit.id } as Report);
        addNotification('success', 'Report updated successfully');
        setReportToEdit(null);
      } else {
        savedReport = await addReport(data);
        addNotification('success', 'Report created successfully');
        setIsAddModalOpen(false);
      }
      
      navigation.goToReport(savedReport.id);
    } catch (error) {
      addNotification('error', `Failed to ${reportToEdit ? 'update' : 'create'} report`);
    }
  };

  const handleReportClick = (report: Report) => {
    navigation.goToReport(report.id);
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} onRetry={fetchReports} />;
  if (!reports.length) {
    return (
      <EmptyState
        title="No Reports Found"
        message="Get started by creating your first progress report."
        icon={<FileText size={24} className="text-gray-400" />}
        actionLabel="Create Report"
        onAction={() => setIsAddModalOpen(true)}
      />
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-teal-50 rounded-lg">
            <FileText className="text-teal-600" size={24} />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Progress Reports</h1>
            <p className="text-sm text-gray-500">Track and manage construction progress</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* View Toggle */}
          <div className="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewMode('card')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'card'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <LayoutGrid size={20} />
            </button>
            <button
              onClick={() => setViewMode('table')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'table'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Table2 size={20} />
            </button>
          </div>

          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-teal-500 rounded-lg hover:bg-teal-600 transition-colors"
          >
            <div className="flex items-center gap-2">
              <Plus size={16} />
              <span>Create Report</span>
            </div>
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <SearchAndFilter
        searchPlaceholder="Search reports by ID, job, or client..."
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={filters}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
        showFilters={showFilters}
        onToggleFilters={toggleFilters}
      />

      {/* Reports List */}
      {viewMode === 'card' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((report) => (
            <ReportCard
              key={report.id}
              report={report}
              onSelect={() => handleReportClick(report)}
              onEdit={() => handleEdit(report)}
              onDelete={() => handleDelete(report)}
            />
          ))}
        </div>
      ) : (
        <ReportTable
          reports={filteredData}
          onSelect={handleReportClick}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {/* Add/Edit Report Modal */}
      {(isAddModalOpen || reportToEdit) && (
        <ReportFormModal
          isOpen={true}
          onClose={() => {
            setIsAddModalOpen(false);
            setReportToEdit(null);
          }}
          onSubmit={handleSubmit}
          initialData={reportToEdit || undefined}
          companyId={companies[0]?.id || ''}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={!!reportToDelete}
        onClose={() => setReportToDelete(null)}
        onConfirm={handleConfirmDelete}
        title="Delete Report"
        message={`Are you sure you want to delete this report? This action cannot be undone.`}
        confirmLabel="Delete"
        type="danger"
      />
    </div>
  );
}