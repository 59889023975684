import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { usePermissions } from '../../hooks/usePermissions';
import { ROUTES } from '../../routes/paths';
import LoadingState from '../ui/LoadingState';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredPermission?: {
    id: string;
    action: 'view' | 'create' | 'edit' | 'delete' | 'manage' | 'assign';
  };
  allowedRoles?: string[];
}

export default function ProtectedRoute({ 
  children, 
  requiredPermission,
  allowedRoles 
}: ProtectedRouteProps) {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { hasPermission } = usePermissions();
  const location = useLocation();

  if (isLoading) {
    return <LoadingState />;
  }

  if (!isAuthenticated) {
    // Save the attempted URL for redirecting after login
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  // Check role-based access
  if (allowedRoles && user && !allowedRoles.includes(user.role)) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  // Check permission-based access
  if (requiredPermission && !hasPermission(requiredPermission.id, requiredPermission.action)) {
    // If user doesn't have required permission, redirect to dashboard
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return <>{children}</>;
}