import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle2, Clock, AlertCircle, Ban } from 'lucide-react';
import { Invoice } from '../../types/invoice';

interface InvoiceStatusProps {
  status: Invoice['status'];
  onStatusChange: (status: Invoice['status']) => void;
}

export default function InvoiceStatus({ status, onStatusChange }: InvoiceStatusProps) {
  const statusConfig = {
    draft: {
      label: 'Draft',
      icon: Clock,
      color: 'bg-gray-50 text-gray-600',
      options: ['pending', 'void'],
    },
    pending: {
      label: 'Pending',
      icon: Clock,
      color: 'bg-yellow-50 text-yellow-600',
      options: ['approved', 'void'],
    },
    approved: {
      label: 'Approved',
      icon: CheckCircle2,
      color: 'bg-blue-50 text-blue-600',
      options: ['paid', 'overdue', 'void'],
    },
    paid: {
      label: 'Paid',
      icon: CheckCircle2,
      color: 'bg-green-50 text-green-600',
      options: ['void'],
    },
    overdue: {
      label: 'Overdue',
      icon: AlertCircle,
      color: 'bg-red-50 text-red-600',
      options: ['paid', 'void'],
    },
    void: {
      label: 'Void',
      icon: Ban,
      color: 'bg-gray-50 text-gray-600',
      options: [],
    },
  };

  const config = statusConfig[status];
  const Icon = config.icon;

  return (
    <div className="relative group">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`flex items-center gap-2 px-4 py-2 rounded-lg ${config.color}`}
      >
        <Icon size={16} />
        <span className="font-medium">{config.label}</span>
      </motion.button>

      {/* Status Change Menu */}
      {config.options.length > 0 && (
        <div className="absolute right-0 top-full mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all z-10">
          {config.options.map((option) => {
            const optionConfig = statusConfig[option as Invoice['status']];
            const OptionIcon = optionConfig.icon;

            return (
              <button
                key={option}
                onClick={() => onStatusChange(option as Invoice['status'])}
                className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors"
              >
                <OptionIcon size={16} />
                <span>Mark as {optionConfig.label}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}