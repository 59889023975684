import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../hooks/usePermissions';
import { useNotification } from './NotificationContext';
import { ROUTES } from '../routes/paths';

interface User {
  id: string;
  email: string;
  name: string;
  role: UserRole;
  companyId?: string;
  clientId?: string;
  profileImage?: string;
  createdAt: Date;
  permissions?: string[];
}

interface Company {
  id: string;
  name: string;
  logo?: string;
  brandColors?: {
    primary: string;
    secondary: string;
  };
  subdomain: string;
  plan: 'trial' | 'basic' | 'pro' | 'enterprise';
  trialEndsAt?: Date;
}

interface AuthContextType {
  user: User | null;
  company: Company | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (email: string, password: string, name: string) => Promise<void>;
  updateCompany: (updates: Partial<Company>) => Promise<void>;
  updateProfile: (updates: Partial<User>) => Promise<void>;
  acceptInvite: (data: { token: string; name: string; password: string }) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

const TOKEN_KEY = 'auth_token';
const USER_KEY = 'user_data';
const COMPANY_KEY = 'company_data';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  // Load stored auth state on mount
  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    const storedUser = localStorage.getItem(USER_KEY);
    const storedCompany = localStorage.getItem(COMPANY_KEY);

    if (token && storedUser) {
      setUser(JSON.parse(storedUser));
      if (storedCompany) {
        setCompany(JSON.parse(storedCompany));
      }
    }

    setIsLoading(false);
  }, []);

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      // In production, make API call here
      const mockUser: User = {
        id: '1',
        email,
        name: email === 'super@admin.com' 
          ? 'Super Admin' 
          : email === 'admin@company.com'
          ? 'John Smith'
          : 'Client User',
        role: email === 'super@admin.com' 
          ? 'super_admin' 
          : email === 'admin@company.com'
          ? 'org_admin'
          : email === 'staff@company.com'
          ? 'admin'
          : email === 'user@company.com'
          ? 'user'
          : 'client',
        companyId: ['admin@company.com', 'staff@company.com', 'user@company.com'].includes(email) ? '1' : undefined,
        clientId: email === 'client@example.com' ? '1' : undefined,
        createdAt: new Date()
      };
      
      const mockCompany: Company | null = email === 'super@admin.com' 
        ? null 
        : {
            id: '1',
            name: 'A-Grade Construction',
            logo: '/logo.png',
            brandColors: {
              primary: '#10B981',
              secondary: '#6366F1'
            },
            subdomain: 'agrade',
            plan: 'trial',
            trialEndsAt: new Date('2024-03-15')
          };
      
      localStorage.setItem(TOKEN_KEY, 'dummy-token');
      localStorage.setItem(USER_KEY, JSON.stringify(mockUser));
      if (mockCompany) {
        localStorage.setItem(COMPANY_KEY, JSON.stringify(mockCompany));
      }

      setUser(mockUser);
      setCompany(mockCompany);

      addNotification('success', 'Successfully logged in');
      
      // Redirect based on role
      if (mockUser.role === 'client') {
        navigate(ROUTES.REPORTS);
      } else {
        navigate(ROUTES.DASHBOARD);
      }
    } catch (error) {
      addNotification('error', 'Invalid email or password');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(COMPANY_KEY);
      setUser(null);
      setCompany(null);
      navigate(ROUTES.LOGIN);
      addNotification('success', 'Successfully logged out');
    } catch (error) {
      addNotification('error', 'Failed to log out');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signup = async (email: string, password: string, name: string) => {
    setIsLoading(true);
    try {
      // In production, make API call here
      await new Promise(resolve => setTimeout(resolve, 1000));
      await login(email, password);
      addNotification('success', 'Account created successfully');
    } catch (error) {
      addNotification('error', 'Failed to create account');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateCompany = async (updates: Partial<Company>) => {
    setIsLoading(true);
    try {
      // In production, make API call here
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedCompany = { ...company, ...updates } as Company;
      localStorage.setItem(COMPANY_KEY, JSON.stringify(updatedCompany));
      setCompany(updatedCompany);
      addNotification('success', 'Company settings updated');
    } catch (error) {
      addNotification('error', 'Failed to update company settings');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = async (updates: Partial<User>) => {
    setIsLoading(true);
    try {
      // In production, make API call here
      await new Promise(resolve => setTimeout(resolve, 1000));
      const updatedUser = { ...user, ...updates } as User;
      localStorage.setItem(USER_KEY, JSON.stringify(updatedUser));
      setUser(updatedUser);
      addNotification('success', 'Profile updated successfully');
    } catch (error) {
      addNotification('error', 'Failed to update profile');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const acceptInvite = async (data: { token: string; name: string; password: string }) => {
    setIsLoading(true);
    try {
      // In production, make API call here
      await new Promise(resolve => setTimeout(resolve, 1000));
      addNotification('success', 'Invitation accepted successfully');
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      addNotification('error', 'Failed to accept invitation');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      company,
      isAuthenticated: !!user,
      isLoading,
      login,
      logout,
      signup,
      updateCompany,
      updateProfile,
      acceptInvite
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}